import React, {useContext} from "react";
import { Navigate, useLocation } from "react-router-dom";
import { TokenContext } from "./component/token_context";


const ProtectedRoutes = ({ children, allowedRoles}) => {
  const token = JSON.parse(sessionStorage.getItem("rubbish"));
  const values = useContext(TokenContext);
  const {adminRole} = values;
  const adminRoleM = adminRole;
  let exp = false;
  if(!token) {
    exp = true;
  };
  //console.log(adminRole?.find(role => allowedRoles?.includes(role)))
  // const admin_role = JSON.parse(sessionStorage.getItem("admin_role"));
  const location = useLocation();
  //console.log(adminRole);

  return (
    exp
      ? <Navigate to="/Session_Expired" state={{ from: location }} replace />
      : adminRole?.find(role => allowedRoles?.includes(role))
          ? children
          : <Navigate to='/App/Unauthorized' state={{from: location}} replace />
      // : children
      // : admin_role?.find(role => allowedRoles?.includes(role))
      //     ? <Navigate to="/App/Users" state={{form: location}} replace />
      //     : <Navigate to='/App/Unauthorized' state={{from: location}} replace />
      
  );
}
export default ProtectedRoutes;