import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../css/popup.css";
import { TokenContext } from "../token_context";
import ClipLoader from "react-spinners/ClipLoader";

export default function Assign_Agent() {
  const navigate = useNavigate();
  const location = useLocation();
  const delivery_agent_details = location.state.delivery_agent_details;
  const delivery_details = location.state.delivery_details;
  //console.log(delivery_details);
  const [data, getData] = useState([]);
  const values = useContext(TokenContext);
  const admin_id = JSON.parse(sessionStorage.getItem("admin_id"));
  const token = JSON.parse(sessionStorage.getItem("rubbish"));
  const [disable, setDisable] = useState(false);
  //console.log(values);
  // const admin_id = JSON.parse(values.admin_id);
  //console.log(token, delivery_agent_details._id, delivery_details.delivery_id);

  const handleClick = () => {
    if (delivery_details._id !== undefined) {
      //console.log('first check')
      fetchData2();
    } else {
      if (
        delivery_details.delivery_status_is_cancelled_by === "delivery agent"
      ) {
        fetchData2();
        //console.log('second check')
      } else {
        fetchData();
        //console.log('third check')
      }
    }
  };

  const fetchData2 = async () => {
    setDisable(true);
    try {
      // console.log(`-----> ${{
      //   token: token,
      //   delivery_id: delivery_details.delivery_id || delivery_details._id,
      //   delivery_agent_id: delivery_agent_details._id,
      //   delivery_agent_img_id: delivery_agent_details.delivery_agent_img_id,
      // }}`);
      // send in the proper fields
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/delivery_agent_delivery/assign_cancelled_delivery",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            delivery_id: delivery_details.delivery_id || delivery_details._id,
            delivery_agent_id: delivery_agent_details._id,
            // delivery_agent_img_id: delivery_agent_details.delivery_agent_img_id,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const result = await response.json();
      //console.log(result, 'got here');
      getData(result.delivery_agent);
      //console.log(result);
      //console.log('got here');
      //console.log(result);
      //console.log(data.vehicle_details, data.bank_details);

      if (response.status === 200) {
        setDisable(false);
        //console.log('delivery agent accepted successfully');
        window.alert("Delivery assigned successfully");
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } else {
        setDisable(false);
        window.alert("Delivery not assigned");
        //console.log('some error occurred');
      }
    } catch (error) {
      setDisable(false);
      window.alert("Delivery not assigned");
      //console.log(error);
    }
  };

  const fetchData = async () => {
    setDisable(true);
    try {
      // send in the proper fields
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_delivery/assign_delivery",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            delivery_agent_id: delivery_agent_details._id,
            delivery_id: delivery_details.delivery_id || delivery_details._id,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const result = await response.json();
      //console.log(result, 'got here');
      getData(result.delivery_agent);
      //console.log(result);
      //console.log('got here');
      //console.log(result);
      //console.log(data.vehicle_details, data.bank_details);

      if (response.status === 200) {
        setDisable(false);
        //console.log('delivery agent accepted successfully');
        window.alert("Delivery assigned successfully");
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } else {
        setDisable(false);
        window.alert("Delivery not assigned");
        //console.log('some error occurred');
      }
    } catch (error) {
      setDisable(false);
      window.alert("Delivery not assigned");
      //console.log(error);
    }
  };

  return (
    <div className="popup">
      <div className="popup-container">
        <div className="popup-background">
          <div className="popup-confirmation">
            <div className="popup-confirmation-info">
              Assign Delivery Confirmation
            </div>
            <div className="popup-rematched">
              Are you sure you want to assign this Delivery <br /> to Delivery
              Agent with ID <br></br>{" "}
              {delivery_agent_details.delivery_agent_code}?
            </div>
          </div>
          <div className="chizzys-popup-container">
            <button
              className="chizzys-popup-buttons"
              onClick={() => navigate(-1)}
            >
              No
            </button>
            <button
              className="chizzys-popup-buttons1"
              onClick={handleClick}
              type="submit"
              disabled={disable}
            >
              {disable ? (
                <ClipLoader color={"black"} loading={disable} size={15} />
              ) : (
                "Yes"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

// <button className='popup-dialog1' onClick={() =>  fetchData()} type='submit' disabled={disable}>
// {disable ? <ClipLoader color={"black"} loading={disable}  size={15} /> : "Yes" }
// </button>
