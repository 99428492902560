import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import  ProfilePix from './ProfilePix';
import "../../component/css/support.css";
import { ProfilePix } from "../support/ProfilePix";
import report from "../../component/images/reportflag.png";
import sendicon from "../../component/images/sendicon.png";
import attachfileicon from "../../component/images/attachfileicon.png";
import profileimage from "../../component/images/profileimage.png";
import aang from "../../component/images/aang.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";
import dayjs from "dayjs";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDoc,
  doc,
  getDocs,
  QuerySnapshot,
  setDoc,
  Timestamp,
  orderBy,
} from "firebase/firestore";
import { db, storage, serverTimestamp } from "../../utils/firebase";
import { TokenContext } from "../../component/token_context";

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import audioFile2 from "../../audio/beep2.wav";
// flies for audio when sending a message
import audioFile from "../../audio/new_noti.wav";
const audio = new Audio(audioFile);
const audio2 = new Audio(audioFile2);

export default function Message_User() {
  const value = useContext(TokenContext);
  const { setNewMsg } = value;
  const admin_id = JSON.parse(sessionStorage.getItem("admin_id"));
  const admin_name = JSON.parse(sessionStorage.getItem("admin_name"));
  let admin_img = sessionStorage.getItem("admin_img");
  if (admin_img == undefined) {
    admin_img = "a";
  }
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState("");
  const [conv_id, setConvId] = useState("");
  const [result2, setResult2] = useState("");
  const [data2, setData2] = useState([]);
  const token = JSON.parse(sessionStorage.getItem("rubbish"));
  const [firstFire, setFirstFire] = useState(true);
  const [count, setCount] = useState(0);
  // const [profile_pic, setProfilePic] = useState('');
  // const [sender_name, setSenderName] = useState('');
  const [content, setContent] = useState("");

  const [img, setImg] = useState("");
  const [display, setDisplay] = useState("");

  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  //console.log(location);
  //console.log(location.state.user_details._id)
  const receiver_id =
    location.state.user_details.user_id || location.state.user_details._id;
  const sender_name =
    location.state.user_details.fullname ||
    location.state.user_details.user_name;
  const profile_pic =
    location.state.user_details.img || location.state.user_details.user_img_url;
  const email =
    location.state.user_details.email || location.state.user_details.user_email;
  //console.log(receiver_id, sender_name, profile_pic, email);
  const [new_conv, setNewConv] = useState(false);

  const timestamp = Timestamp.fromDate(new Date());
  //console.log(timestamp.seconds);
  //console.log(timestamp.seconds * 1000);

  //console.log(conv_id);

  const Messager = (item, i) => {
    const admin_id = JSON.parse(sessionStorage.getItem("admin_id"));
    const DateConverter = (props) => {
      const timer = item?.timestamp
        ? dayjs(props.value.seconds * 1000).format("MMM DD, YYYY")
        : "...";
      return timer;
    };

    const TimeConverter = (props) => {
      //console.log(item);
      const timer = item?.timestamp
        ? dayjs(props.value.seconds * 1000).format("hh:mm a")
        : "...";
      return timer;
    };
    let cname = "";
    if (item.sender_id !== receiver_id) {
      cname = "outgoing-msgs";
    } else {
      cname = "incoming-msgs";
    }
    return (
      <div
        className={
          cname === "outgoing-msgs"
            ? "shadow-outgoing-msg-container"
            : "shadow-incoming-msg-container"
        }
        ref={messagesEndRef}
        key={i}
      >
        <div
          className={
            cname === "outgoing-msgs"
              ? "shadow-outgoing-msg-wrapper"
              : "shadow-incoming-msg-wrapper"
          }
        >
          {/* <p className="date-of-msg"> */}
          {/* <div className={cname}>
            <p>{item?.content}</p>
          </div> */}
          <div className={cname}>
            {/* {item.media ? <img src={item.media} width="100px" height=" 100px" style={{marginBottom: "5px"}} /> : null}  */}
            {item.message_type === "image" ? (
              <img
                src={item.content}
                width="100px"
                height=" 100px"
                style={{ marginBottom: "5px" }}
              />
            ) : (
              <p>{item?.content}</p>
            )}
          </div>

          <p
            className={
              cname === "outgoing-msgs" ? "date-of-msg" : "shadow-date-of-msg"
            }
          >
            <TimeConverter value={item?.timestamp} /> <br />
            <DateConverter value={item?.timestamp} />
          </p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    CheckConvers();
  }, [isLoaded === false]);

  // useEffect(() => {
  //     GetMessages();
  // }, [conv_id]);

  useEffect(() => {
    const q = query(
      collection(db, "hf_collection", conv_id, conv_id),
      orderBy("timestamp", "asc")
    );
    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      const list = [];
      QuerySnapshot.forEach((doc) => {
        list.push(doc?.data());
        //console.log(doc.data());
        setData2(list);
        //console.log('exciting times');
        // setMessageList(messageList);
        //console.log(list.length);
      });
    });
    if (isLoaded === false) {
      unsubscribe();
    }
    let countM = {};
    const check = onSnapshot(
      doc(db, "admin_notifiers", "hf_messages"),
      async (doc) => {
        countM = doc.data();
        if (firstFire == true) {
          setCount(countM.messages_count);
          setFirstFire(false);
        } else {
          if (countM.messages_count !== count) {
            setCount(countM.messages_count);
            audio2.play();
            setNewMsg(true);
          }
        }
      }
    );
  }, [isLoaded === false, conv_id]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    // setNewMsg(false);
  }, [data2]);

  const CheckConvers = async () => {
    // setNewMsg(false);
    //console.log(receiver_id);
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/help_feedback_admin/check_convers",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            receiver_id: receiver_id,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const resultM = await response.json();
      //console.log(resultM);
      if (resultM.msg === "Success") {
        //console.log(resultM.conversation_id);
        setConvId(resultM.conversation_id);
        setIsLoaded(true);
        setNewConv(false);
      } else if (resultM.msg === "No conversation found") {
        // setConvId("a");
        setNewConv(true);
        setIsLoaded(true);
      }

      if (response.status === 200) {
        //console.log("data gotten succesfully");
      } else {
        //console.log("some error occurred");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  // const GetMessages = async () => {
  //   try {
  //       //console.log(conv_id);
  //       const response = await fetch(
  //           "https://serverpickload.wl.r.appspot.com/delivery_agent_chat/get_messages",
  //           {
  //             method: "POST",

  //             body: JSON.stringify({
  //               token: token,
  //               conv_id: conv_id,
  //               pagec: 1
  //             }),
  //             headers: {
  //               "Content-Type": "application/json",
  //               Accept: "application/json, text/plain, */*"
  //             },
  //           }
  //       )
  //       // getData(await response.json());
  //       const resultM = await response.json();
  //       //console.log(resultM)
  //       setData2(resultM.messages);
  //       setResult2(resultM)
  //       //console.log(resultM.messages);
  //       //console.log(data2);
  //       // setSenderName(resultM.latest_message.sender_name);

  //       if(response.status === 200) {
  //           //console.log('data gotten succesfully')
  //           //console.log(data2);
  //       } else {
  //           //console.log('some error occurred')
  //       }
  //   } catch(error) {
  //       //console.log(error);
  //   }
  // }

  const SendMessage = async (event) => {
    event.preventDefault();

    if (new_conv === true) {
      const contentToDB = content;
      setContent("");
      let url;
      if (img) {
        const imageRef = ref(
          storage,
          `messageAttachment/${new Date().getTime()}-${img.name}`
        );
        const snap = await uploadBytes(imageRef, img);
        const durl = await getDownloadURL(ref(storage, snap.ref.fullPath));
        url = durl;
      }

      try {
        const res = await fetch(
          "https://serverpickload.wl.r.appspot.com/help_feedback/send_message",
          {
            method: "POST",
            body: JSON.stringify({
              token: token,
              sender_name: admin_name,
              new_conv: true,
              sender_img: admin_img || "a",
              content: contentToDB ? contentToDB : img.name,
              message_type: img ? "image" : "text",
              who_sent: "admin",
              which_user: "user",
              user_id: receiver_id,
            }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json, text/plain, */*",
            },
          }
        );
        const resultM = await res.json();
        //console.log(resultM);
        // if (resultM.msg === "Message sent") {
        const timestamp = Date.now();

        if (res.status === 200) {
          //console.log("result here", resultM);
          //console.log(resultM.message.conversation_id);
          setConvId(resultM.message.conversation_id);
          await setDoc(
            doc(
              db,
              "hf_collection",
              resultM.message.conversation_id,
              resultM.message.conversation_id,
              `${timestamp}`
            ),
            {
              content: contentToDB ? contentToDB : url,
              conv_id: resultM.message.conversation_id,
              receiver_id: receiver_id,
              sender_id: admin_id,
              sender_img: admin_img || attachfileicon || attachfileicon,
              sender_name: admin_name,
              timestamp: serverTimestamp(),
              file_name: contentToDB ? "a" : "IMG_" + timestamp,
              who_sent: "admin",
              which_user: "user",
              user_id: receiver_id,
              message_type: img ? "image" : "text",
            }
          );
          audio2.play();

          // update the messages count field
          // await setDoc(
          //   doc(
          //     db,
          //     "hf_collection",
          //     "statistics"
          //   ),
          //   {messages_count: count + 1}
          // );

          // setNewConvId()
          //
          setNewConv(false);
        }
      } catch (error) {
        //console.log(error);
        // const err = error
      }
      setContent("");
      setImg("");
      setDisplay("");
    } else {
      if (event.keyCode === 13 && event.shiftKey === false) {
        const contentToDB = content;
        setContent("");
        event.preventDefault();
        let url;
        if (img) {
          const imageRef = ref(
            storage,
            `messageAttachment/${new Date().getTime()}-${img.name}`
          );
          const snap = await uploadBytes(imageRef, img);
          const durl = await getDownloadURL(ref(storage, snap.ref.fullPath));
          url = durl;
        }

        const timestamp = Date.now();
        // setContent("");
        await setDoc(
          doc(db, "hf_collection", conv_id, conv_id, `${timestamp}`),
          {
            content: contentToDB ? contentToDB : url,
            conv_id: conv_id,
            sender_id: admin_id,
            sender_img: admin_img || attachfileicon,
            receiver_id: receiver_id,
            sender_name: admin_name,
            timestamp: serverTimestamp(),
            file_name: contentToDB ? "a" : "IMG_" + timestamp,
            who_sent: "admin",
            which_user: "user",
            user_id: receiver_id,
            message_type: img ? "image" : "text",
          }
        );
        audio2.play();

        // update the messages count field
        // await setDoc(
        //   doc(
        //     db,
        //     "hf_collection",
        //     "statistics"
        //   ),
        //   {messages_count: count + 1}
        // );

        await fetch(
          "https://serverpickload.wl.r.appspot.com/help_feedback/send_message",
          {
            method: "POST",
            body: JSON.stringify({
              token: token,
              // receiver_id: receiver_id,
              sender_name: admin_name,
              new_conv: false,
              sender_img: admin_img || "a",
              conv_id: conv_id,
              content: contentToDB ? contentToDB : img.name,
              who_sent: "admin",
              which_user: "user",
              user_id: receiver_id,
              message_type: img ? "image" : "text",
            }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json, text/plain, */*",
            },
          }
        );
        //console.log(receiver_id);
        //console.log("hi", admin_img);
        //console.log(admin_name);
        //console.log(conv_id);
        setContent("");
        setImg("");
        setDisplay("");
      } else if (!event.code) {
        const contentToDB = content;
        setContent("");
        setLoading(true);
        let url;
        if (img) {
          const imageRef = ref(
            storage,
            `messageAttachment/${new Date().getTime()}-${img.name}`
          );
          const snap = await uploadBytes(imageRef, img);
          const durl = await getDownloadURL(ref(storage, snap.ref.fullPath));
          url = durl;
        }

        const timestamp = Date.now();
        await setDoc(
          doc(db, "hf_collection", conv_id, conv_id, `${timestamp}`),
          {
            content: contentToDB ? contentToDB : url,
            conv_id: conv_id,
            sender_id: admin_id,
            sender_img: admin_img || attachfileicon,
            receiver_id: receiver_id,
            sender_name: admin_name,
            timestamp: serverTimestamp(),
            file_name: contentToDB ? "a" : "IMG_" + timestamp,
            who_sent: "admin",
            which_user: "user",
            user_id: receiver_id,
            message_type: img ? "image" : "text",
          }
        );
        audio2.play();

        // update the messages count field
        // await setDoc(
        //   doc(
        //     db,
        //     "hf_collection",
        //     "statistics"
        //   ),
        //   {messages_count: count + 1}
        // );

        await fetch(
          "https://serverpickload.wl.r.appspot.com/help_feedback/send_message",
          {
            method: "POST",
            body: JSON.stringify({
              token: token,
              // receiver_id: receiver_id,
              sender_name: admin_name,
              new_conv: false,
              sender_img: admin_img || "a",
              conv_id: conv_id,
              content: contentToDB ? contentToDB : img.name,
              who_sent: "admin",
              which_user: "user",
              user_id: receiver_id,
              message_type: img ? "image" : "text",
            }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json, text/plain, */*",
            },
          }
        );
        setImg("");
        setDisplay("");
        setLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    setImg(e.target.files[0]);
    setDisplay(URL.createObjectURL(e.target.files[0]));
  };

  if (!isLoaded) {
    //console.log("got here");
    return (
      <h1 className="loading-pages1">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (conv_id?.length > 0) {
    //console.log(.length);
    return (
      <div className="support-chat">
        <div className="chat-wrapper">
          <div className="back2">
            <span>
              <FontAwesomeIcon
                icon={faArrowLeftLong}
                onClick={() => navigate(-1)}
                className="back"
              ></FontAwesomeIcon>{" "}
            </span>{" "}
            <br />
          </div>
          <div className="chat-right-side">
            {display && (
              <span className="photo_display">
                {display && <img src={display} width="100%" height="100%" />}{" "}
              </span>
            )}
            <div className="shadow-chat-right-side-wrapper">
              <div className="main-chat-discussion">
                <div className="profile-picture1">
                  <img
                    src={profile_pic ? profile_pic : aang}
                    alt="icon"
                    className="support-profile-pic1"
                  />
                </div>
                <div className="your-profile">
                  <h3>{sender_name}</h3>
                  <h6>{email ? email : "User"}</h6>
                </div>
              </div>
              {/* <div className="message-header">
                <h6>Conversations</h6>
              </div> */}

              <div className="messages-wrapper">
                {data2?.map((item, i) => Messager(item, i))}
                <div ref={messagesEndRef} />
                <div className="shadow-chat-section">
                  <form
                    onSubmit={SendMessage}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    <div className="typing-bar">
                      <textarea
                        disabled={img}
                        role="textbox"
                        placeholder="Type your message..."
                        className="shadow-text-area"
                        rows="1"
                        cols="20"
                        typeof="submit"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        onSubmit={SendMessage}
                        onKeyUp={SendMessage}
                      ></textarea>
                    </div>
                    <div className="chat-icons">
                      <label
                        htmlFor="img"
                        className="shadow-label-text"
                        id={content ? "disabled" : ""}
                      >
                        <img src={attachfileicon} width="30px" height="30px" />
                      </label>
                      <input
                        type="file"
                        name="img"
                        id="img"
                        accept="image/*"
                        onChange={handleChange}
                        style={{ display: "none" }}
                      />

                      <div className="send-message">
                        <button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            width: "45px",
                          }}
                          disabled={loading}
                          id={!content && !img ? "disabled" : ""}
                        >
                          <img src={sendicon} onClick={SendMessage} />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (conv_id === "") {
    //   //console.log('render second');
    return (
      <div className="support-chat">
        <div className="chat-wrapper">
          <div className="back2">
            <span>
              <FontAwesomeIcon
                icon={faArrowLeftLong}
                onClick={() => navigate(-1)}
                className="back"
              ></FontAwesomeIcon>{" "}
            </span>{" "}
            <br />
          </div>
          <div className="chat-right-side">
            <div className="shadow-chat-right-side-wrapper">
              <div className="main-chat-discussion">
                <div className="profile-picture1">
                  <img
                    src={profile_pic ? profile_pic : aang}
                    alt="icon"
                    className="support-profile-pic1"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="your-profile">
                  <h3>{sender_name}</h3>
                  <h6>{email ? email : "User"}</h6>
                </div>
              </div>
              <div className="message-header">
                <h6>Conversations</h6>
              </div>

              <div className="messages-wrapper">
                <div ref={messagesEndRef} />
                <div className="shadow-chat-section">
                  <div className="typing-bar">
                    <textarea
                      role="textbox"
                      placeholder="Type your message..."
                      className="shadow-text-area"
                      rows="1"
                      cols="20"
                      typeof="submit"
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                      // onKeyUp={SendMessage}
                    ></textarea>
                  </div>
                  <div className="chat-icons">
                    <div className="send-message">
                      <img src={sendicon} alt="" onClick={SendMessage} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
