export const updateRefundDays = async (
  token,
  refund_days,
  fetchData,
  setDisable
) => {
  try {
    setDisable(true);
    const response = await fetch(
      "https://serverpickload.wl.r.appspot.com/admin_settings/set_refund_days",
      {
        method: "POST",

        body: JSON.stringify({
          token: token,
          refund_days: refund_days,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
      }
    );

    if (response.status === 200) {
      //console.log('data gotten succesfully');
      fetchData();
      setDisable(false);
    } else {
      //console.log('somen error occurred');
      setDisable(false);
    }
  } catch (error) {
    //console.log(error);
    setDisable(false);
  }
};

export const updatePickupRadius = async (
  token,
  pickup_radius,
  fetchData,
  setDisable4
) => {
  setDisable4(true);
  try {
    const response = await fetch(
      "https://serverpickload.wl.r.appspot.com/admin_settings/set_pickup_radius",
      {
        method: "POST",

        body: JSON.stringify({
          token: token,
          pickup_radius: pickup_radius,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
      }
    );
    if (response.status === 200) {
      //console.log('data gotten succesfully');
      fetchData();
      setDisable4(false);
    } else {
      //console.log('somen error occurred');
      setDisable4(false);
    }
  } catch (error) {
    //console.log(error);
    setDisable4(false);
  }
};

export const updateRefRate = async (
  token,
  entity,
  refund_percent,
  fetchData,
  setDisable
) => {
  try {
    setDisable(true);
    //console.log(entity, refund_percent);
    const response = await fetch(
      "https://serverpickload.wl.r.appspot.com/admin_settings/set_refund_percent",
      {
        method: "POST",

        body: JSON.stringify({
          token: token,
          refund_percent: refund_percent,
          entity: entity,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
      }
    );
    if (response.status === 200) {
      //console.log('data gotten succesfully');
      fetchData();
      setDisable(false);
    } else {
      //console.log('somen error occurred');
      setDisable(false);
    }
    const result = await response.json();
    //console.log(result);
  } catch (error) {
    //console.log(error);
    setDisable(false);
  }
};

export const updateBaseFare = async (
  token,
  delivery_medium,
  base_fare,
  fetchData,
  setDisable
) => {
  try {
    setDisable(true);
    const response = await fetch(
      "https://serverpickload.wl.r.appspot.com/admin_settings/set_base_fare",
      {
        method: "POST",

        body: JSON.stringify({
          token: token,
          delivery_medium: delivery_medium,
          base_fare: base_fare,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
      }
    );
    if (response.status === 200) {
      //console.log('data gotten succesfully');
      fetchData();
      setDisable(false);
    } else {
      //console.log('somen error occurred');
      setDisable(false);
    }
    const result = await response.json();
    //console.log(result);
  } catch (error) {
    //console.log(error);
    setDisable(false);
  }
};

export const updateKmRate = async (
  token,
  delivery_medium,
  km_rate,
  fetchData,
  setDisable
) => {
  try {
    setDisable(true);
    const response = await fetch(
      "https://serverpickload.wl.r.appspot.com/admin_settings/set_km_rate",
      {
        method: "POST",

        body: JSON.stringify({
          token: token,
          delivery_medium: delivery_medium,
          km_rate: km_rate,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
      }
    );

    if (response.status === 200) {
      //console.log('data gotten succesfully');
      fetchData();
      setDisable(false);
    } else {
      //console.log('somen error occurred');
      setDisable(false);
    }
    const result = await response.json();
    //console.log(result);
  } catch (error) {
    //console.log(error);
    setDisable(false);
  }
};

export const updateTimeRate = async (
  token,
  delivery_medium,
  time_rate,
  fetchData,
  setDisable
) => {
  try {
    setDisable(true);
    const response = await fetch(
      "https://serverpickload.wl.r.appspot.com/admin_settings/set_time_rate",
      {
        method: "POST",

        body: JSON.stringify({
          token: token,
          delivery_medium: delivery_medium,
          time_rate: time_rate,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
      }
    );
    const result = await response.json();
    //console.log(result);
    if (response.status === 200) {
      //console.log('data gotten succesfully');
      fetchData();
      setDisable(false);
    } else {
      //console.log('somen error occurred');
      setDisable(false);
    }
  } catch (error) {
    //console.log(error);
    setDisable(false);
  }
};

export const updateMinPrice = async (
  token,
  delivery_medium,
  min_price,
  fetchData,
  setDisable
) => {
  try {
    setDisable(true);
    const response = await fetch(
      "https://serverpickload.wl.r.appspot.com/admin_settings/set_min_price",
      {
        method: "POST",

        body: JSON.stringify({
          token: token,
          delivery_medium: delivery_medium,
          min_price: min_price,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
      }
    );
    const result = await response.json();
    //console.log(result);
    if (response.status === 200) {
      //console.log('data gotten succesfully');
      setDisable(false);
      fetchData();
    } else {
      //console.log('somen error occurred');
      setDisable(false);
    }
  } catch (error) {
    //console.log(error);
    setDisable(false);
  }
};

export const updatePickloadPercent = async (
  token,
  pickload_percent,
  fetchData,
  setDisable
) => {
  try {
    setDisable(true);
    //console.log(pickload_percent);
    const response = await fetch(
      "https://serverpickload.wl.r.appspot.com/admin_settings/set_pickload_percent",
      {
        method: "POST",

        body: JSON.stringify({
          token: token,
          pickload_percent: pickload_percent,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
      }
    );
    if (response.status === 200) {
      //console.log('data gotten succesfully');
      setDisable(false);
      fetchData();
    } else {
      //console.log('somen error occurred');
      setDisable(false);
    }
    const result = await response.json();
    //console.log(result);
  } catch (error) {
    //console.log(error);
    setDisable(false);
  }
};

export const updateDeliveryWaitTime = async (
  token,
  delivery_wait_time,
  fetchData,
  setDisable
) => {
  try {
    setDisable(true);
    //console.log(delivery_wait_time);
    const response = await fetch(
      "https://serverpickload.wl.r.appspot.com/admin_settings/set_request_timeout_duration",
      {
        method: "POST",

        body: JSON.stringify({
          token: token,
          duration: delivery_wait_time,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
      }
    );
    if (response.status === 200) {
      //console.log('data gotten succesfully');
      fetchData();
      setDisable(false);
    } else {
      //console.log('somen error occurred');
      setDisable(false);
    }
    const result = await response.json();
    //console.log(result);
  } catch (error) {
    //console.log(error);
    setDisable(false);
  }
};

export const updatePaymentTimeoutDuration = async (
  token,
  payment_timeout_duration,
  fetchData,
  setDisable
) => {
  try {
    setDisable(true);
    //console.log(payment_timeout_duration);
    const response = await fetch(
      "https://serverpickload.wl.r.appspot.com/admin_settings/set_payment_timeout_duration",
      {
        method: "POST",

        body: JSON.stringify({
          token: token,
          duration: payment_timeout_duration,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
      }
    );
    if (response.status === 200) {
      //console.log('data gotten succesfully');
      fetchData();
      setDisable(false);
    } else {
      //console.log('somen error occurred');
      setDisable(false);
    }
    const result = await response.json();
    //console.log(result);
  } catch (error) {
    //console.log(error);
    setDisable(false);
  }
};
