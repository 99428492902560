import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../component/css/delivery_details.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import checkoutdelivery from "../../component/images/checkoutdelivery.png";
import aang from "../../component/images/aang.jpg";
import emptybox from "../../component/images/emptybox.png";
import vehicleimgavatar from "../../component/images/vehicleimgavatar.png";
import No_instant_Deliveries from "../../component/notfound/No_instant_Deliveries";
import No_All_Deliveries from "../../component/notfound/No_All_Deliveries";
import { ClipLoader } from "react-spinners";

export default function Instant_Delivery() {
  const [data, getData] = useState([]);
  const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const token = JSON.parse(sessionStorage.getItem("rubbish"));
  const [result, setResult] = useState();
  const delivery_id = location.state.id;

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [isLoaded === false]);
  let DATE = {};

  const TimeConverter = (props) => {
    //console.log(props)
    const date = new Date(props.value);
    DATE = {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString(),
      combined: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`,
    };
    return DATE.time;
  };
  
  const DateConverter = (props) => {
    const date = new Date(props.value);

    const formattedDate = {
      day: date.getDate().toString().padStart(2, "0"), // Get the day and pad with leading zero if necessary
      month: (date.getMonth() + 1).toString().padStart(2, "0"), // Get the month (adding 1 because it's zero-based) and pad with leading zero if necessary
      year: date.getFullYear().toString().slice(-2), // Get the last two digits of the year
    };

    return `${formattedDate.day}/${formattedDate.month}/${formattedDate.year}`;
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_delivery/delivery",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            delivery_id: delivery_id,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const resultM = await response.json();
      setResult(resultM);
      setIsLoaded(true);
      getData(resultM.delivery);
      //console.log(data);
      //console.log(resultM);

      if (response.status === 200) {
        //console.log('data gotten succesfully');
      } else {
        //console.log('somen error occurred');
      }
    } catch (error) {
      //console.log(error);
    }
  };
  if (!isLoaded) {
    //console.log('got here')
    return (
      <h1 className="loading-pages">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (result.msg === "Success") {
    //console.log('data gotten succesfully');
    return (
      <div className="deliverys-details">
        <div className="delivery-details-props">
          <div className="back1">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              onClick={() => navigate(-1)}
              className="back"
            ></FontAwesomeIcon>
          </div>
          <h1 className="instant-delivery-summary-title">Delivery Summary</h1>
          <h3 className="instant-delivery-title">Instant Delivery</h3>
          <p style={{ fontSize: "18px", color: "black" }}>
            Delivery cost: ₦{data?.delivery_cost_user}
          </p>
          <div className="delivery-acception">
            Delivery request accepted by:
          </div>
          <div className="instant-delivery-details">
            <div className="instant-delivery-agent-image">
              <img
                style={{ objectFit: "cover" }}
                src={data?.delivery_agent_img ? data?.delivery_agent_img : aang}
                alt=""
                className="deliveryimage"
              />
            </div>
            <div className="instant-delivery-agent-details">
              <tr>
                <th> Delivery Agent Name :</th>{" "}
                <td id="numbers-details">{data?.delivery_agent_name}</td> <br />
              </tr>
              <tr>
                <th> Vehicle Type :</th>{" "}
                <td id="numbers-details">
                  {data?.delivery_agent_vehicle_type}
                </td>{" "}
                <br />
              </tr>
              <tr>
                <th> Vehicle Color : </th>
                <td id="numbers-details">
                  {data?.delivery_agent_vehicle_color}
                </td>{" "}
                <br />
              </tr>
              <tr>
                <th> Agent ID : </th>
                <td id="numbers-details">{data?.delivery_agent_code}</td> <br />
              </tr>
              <tr>
                <th> Plate Number : </th>
                <td id="numbers-details">
                  {data?.delivery_agent_plate_no}
                </td>{" "}
                <br />
              </tr>
              <tr>
                <th>Phone Number : </th>
                <td id="numbers-details">
                  {data?.delivery_agent_phone_no}
                </td>{" "}
                <br />
              </tr>
            </div>
            <div className="delivery-vehicle-type-medium">
              <div className="delivery-vehicle-type-medium-name">
                Vehicle Images
              </div>
              <div className="delivery-vehicle-type-medium-name-vehicle">
                <img
                  style={{ objectFit: "cover" }}
                  src={
                    data?.delivery_agent_vehicle_imgs_urls[2]
                      ? data?.delivery_agent_vehicle_imgs_urls[2]
                      : vehicleimgavatar
                  }
                  className="delivery-vehicle-type-medium-name-vehicle"
                  alt=""
                />
                <img
                  style={{ objectFit: "cover" }}
                  src={
                    data?.delivery_agent_vehicle_imgs_urls[3]
                      ? data?.delivery_agent_vehicle_imgs_urls[3]
                      : vehicleimgavatar
                  }
                  className="delivery-vehicle-type-medium-name-vehicle"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="button-holder">
            <button
              className="chizzy-delivery-button"
              onClick={() =>
                navigate("/App/Individual_Delivery_Agent", {
                  state: { id: data?.delivery_agent_id },
                })
              }
            >
              View delivery agent
            </button>
          </div>
          <div className="delivery-line-breaker">
            <hr className="delivery-line-break" />
          </div>
          <div className="instant-delivery-details1">
            <div className="instant-delivery-client-details1">
              <tr>
                <th> Delivery ID :</th>{" "}
                <td id="numbers-details1">{data?.parcel_code}</td> <br />
              </tr>
              <tr>
                <th>Clients name :</th>{" "}
                <td id="numbers-details1">{data?.sender_fullname}</td> <br />
              </tr>
              <tr>
                <th> Clients Phone Number :</th>{" "}
                <td id="numbers-details1">{"+234" + data?.sender_phone_no}</td>{" "}
                <br />
              </tr>
              <tr>
                <th> Receivers Name :</th>{" "}
                <td id="numbers-details1">{data?.reciever_name}</td> <br />
              </tr>
              <tr>
                <th>Receivers Phone Number : </th>
                <td id="numbers-details1">
                  {"+234" + data?.reciever_phone_no}
                </td>{" "}
                <br />
              </tr>
              <tr>
                <th>Parcel Name :</th>{" "}
                <td id="numbers-details1">{data?.parcel_name}</td> <br />
              </tr>
              <tr>
                <th> Parcel Type : </th>
                <td id="numbers-details1">{data?.parcel_type}</td> <br />
              </tr>
              <tr>
                <th>Item Quantity : </th>
                <td id="numbers-details1">{data?.parcel_description}</td> <br />
              </tr>
              <tr>
                <th> Delivery Instructions : </th>
                <td id="numbers-details1">
                  {data?.delivery_instructions}
                </td>{" "}
                <br />
              </tr>
              <tr>
                <th>Time of client request : </th>
                <td id="numbers-details1">
                  <DateConverter value={data?.timestamp} /> {" at "}
                  <TimeConverter value={data?.timestamp} />
                </td>{" "}
                <br />
              </tr>
              <tr>
                <th>Time of parcel delivery :</th>{" "}
                <td id="numbers-details1">
                  <DateConverter value={data?.delivery_status?.is_completed_at} /> {" at "}
                  <TimeConverter
                    value={data?.delivery_status?.is_completed_at}
                  />
                </td>{" "}
                <br />
              </tr>
              {data?.delivery_status?.is_cancelled ? (
                <>
                  <tr>
                    <th>Cancelled by : </th>
                    <td id="numbers-details1">
                      {data?.delivery_status?.is_cancelled_by}
                    </td>{" "}
                    <br />
                  </tr>
                  <tr>
                    <th>Reason for cancelling : </th>{" "}
                    <td id="numbers-details1">
                      {data?.cancel_reason ||
                        data?.delivery_agent_cancel_reason}
                    </td>{" "}
                    <br />
                  </tr>
                  <tr>
                    <th>Time of Cancel :</th>{" "}
                    <td id="numbers-details1">
                      <DateConverter
                        value={data?.delivery_status?.is_cancelled_at}
                      />{" "}
                      {" at "}
                      <TimeConverter
                        value={data?.delivery_status?.is_cancelled_at}
                      />
                    </td>{" "}
                    <br />
                  </tr>
                </>
              ) : null}
            </div>
          </div>
          <div className="delivery-image">
            <h4 className="delivery-images-title">Images</h4>
            <div className="delivery-images">
              <img
                style={{ objectFit: "cover" }}
                src={data?.imgs[0] ? data?.imgs[0] : emptybox}
                className="delivery-images-details"
                alt=""
              />
              <img
                style={{ objectFit: "cover" }}
                src={data?.imgs[1] ? data?.imgs[1] : emptybox}
                className="delivery-images-details"
                alt=""
              />
              <img
                style={{ objectFit: "cover" }}
                src={data?.imgs[2] ? data?.imgs[2] : emptybox}
                className="delivery-images-details"
                alt=""
              />
            </div>
          </div>{" "}
          <br />
          <br />
          <div className="delivery-image">
            <h4 className="delivery-images-title">Confirmation proof</h4>
            <div className="delivery-images">
              <img
                style={{ objectFit: "cover" }}
                src={
                  data?.delivery_confirmation_proof_urls[0]
                    ? data?.delivery_confirmation_proof_urls[0]
                    : emptybox
                }
                className="delivery-images-details"
                alt=""
              />
              <img
                style={{ objectFit: "cover" }}
                src={
                  data?.delivery_confirmation_proof_urls[1]
                    ? data?.delivery_confirmation_proof_urls[1]
                    : emptybox
                }
                className="delivery-images-details"
                alt=""
              />
              <img
                style={{ objectFit: "cover" }}
                src={
                  data?.delivery_confirmation_proof_urls[2]
                    ? data?.delivery_confirmation_proof_urls[2]
                    : emptybox
                }
                className="delivery-images-details"
                alt=""
              />
            </div>
          </div>
          <div className="delivery-line-breaker1">
            <hr className="delivery-line-break" />
          </div>
          <div>
            <div className="delivery-details-location">
              <div className="delivery-deatails-location-pickup">
                <div className="location-img">
                  <img src={checkoutdelivery} alt="" />
                </div>
                <div>
                  <div className="pickup-location">
                    <h3>Pickup Location </h3>
                    <p>{data?.pickup_address}</p>
                  </div>
                  <div className="delivery-location">
                    <h3>Delivery loaction </h3>
                    <p>{data?.drop_off_address}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (result.msg === "No instant deliveries available yet") {
    return <No_All_Deliveries />;
  }
}
