import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";
import "../component/css/popup.css";

const Confirm_Pin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  //console.log(location);
  const [disable2, setDisable2] = useState(false);
  const [pin, setPin] = useState("");
  const [noti_data_reverse, setNotiDataReverse] = useState("");
  const {
    id,
    week,
    steps,
    month,
    year,
    transaction_type,
    vehicle_type,
    not_paids,
    name,
    code,
    noti_data,
    paid_reversals,
  } = location.state;
  //console.log(not_paids);
  // const not_paidsm = not_paids;
  //console.log(not_paidsm)
  // not_paidsm.push({agent_id: id, status: 'not-paid'});
  const token = JSON.parse(sessionStorage.getItem("rubbish"));

  const CheckPin = async () => {
    try {
      setDisable2(true);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_payment_record/verify_activity_pin",
        {
          method: "POST",

          body: JSON.stringify({
            token,
            activity_pin: pin,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );

      const result = await response.json();

      if (result.msg === "activity pin correct") {
        //console.log("data gotten succesfully");
        ReversePayment();
      } else if (result.msg === "activity pin incorrect") {
        window.alert("Activity pin incorrect");
        setDisable2(false);
      } else {
        //console.log("some error occurred");
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const ReversePayment = async () => {
    const range = noti_data.slice(27);
    const noti_data_reverseM = `Payment has been reveresed from ${range}. Reason: ${noti_data_reverse}`;
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_payment_record/update_not_paids",
        {
          method: "POST",

          body: JSON.stringify({
            token,
            week,
            month,
            year,
            transaction_type,
            vehicle_type,
            not_paids,
            noti_data,
            noti_data_reverse: noti_data_reverseM,
            paid_reversals,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );

      const result = await response.json();

      if (result.msg === "Update Success") {
        //console.log("data gotten succesfully");
        setDisable2(false);
        window.alert("Reverse successful");
        setTimeout(() => {
          navigate(steps);
        }, 3000);
      } else {
        setDisable2(false);
        //console.log("some error occurred");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    CheckPin();
  };

  const handleChange = (e) => {
    e.preventDefault();
    setPin(e.target.value);
  };

  const handleChange1 = (e) => {
    e.preventDefault();
    setNotiDataReverse(e.target.value);
  };

  return (
    <div className="chizzys-parent-container">
      <FontAwesomeIcon
        icon={faArrowLeftLong}
        className="chizzys-arrow"
        onClick={() => navigate(-1)}
      ></FontAwesomeIcon>
      <div className="logout-container">
        <div className="logout-background">
          <div className="logout-confirmation1">
            <div className="chizzy-confirmation-info2">
              <br></br>
              <h4>Confirm activity pin</h4>
              <br></br>
              <br></br>
              {/* <img src={amico} width='110px' height='110px' alt='icon' /><br></br> */}
              <div className="logout-rematched">
                <p>
                  Please put in your activity pin and the reason for reversal to
                  cotinue this operation
                </p>
              </div>
            </div>
            <div className="logout-dialog-row">
              <form className="chizzys-form" onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="reversal reason"
                  value={noti_data_reverse}
                  onChange={handleChange1}
                />
                <input
                  type="password"
                  placeholder="activity pin"
                  value={pin}
                  onChange={handleChange}
                />
                <button type="submit">
                  {disable2 ? (
                    <ClipLoader color={"black"} loading={disable2} size={15} />
                  ) : (
                    "Ok"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirm_Pin;
