import React, { useState, useEffect, useContext, createContext } from "react";
import "../../component/css/agent_applications.css";
import { useNavigate } from "react-router-dom";
import No_Agent_Application from "../../component/notfound/No_Agent_Application";
import { ClipLoader } from "react-spinners";
import PageCounter from "../../component/page_counter";
import { TokenContext } from "../../component/token_context";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDoc,
  doc,
  getDocs,
  QuerySnapshot,
  setDoc,
} from "firebase/firestore";
import { db, storage } from "../../utils/firebase";
import audioFile from "../../audio/new_noti.wav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";

export default function Agent_Applications() {
  const value = useContext(TokenContext);
  const { setNewRegApp, listen } = value;
  const [data, getData] = useState([]);
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState();
  const [pageCount, setPageCount] = React.useState(1);
  const token = JSON.parse(sessionStorage.getItem("rubbish"));
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const [resultPerPage, setResultPerPage] = useState(20);
  const [firstFire, setFirstFire] = useState(true);
  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const audio = new Audio(audioFile);
  // const [show, setShow] = useNavigate({});
  let show = "";
  // const{ token }= useContext(tokenContext);
  //console.log(token);
  //console.log('got here');

  useEffect(() => {
    fetchData();
  }, [isLoaded === false]);

  useEffect(() => {
    setNewRegApp(false);
  }, [data]);

  useEffect(() => {
    let countM = {};
    const check = onSnapshot(
      doc(db, "admin_notifiers", "agent_applications"),
      async (doc) => {
        countM = doc.data();
        if (firstFire === true) {
          setCount(countM.applications_count);
          setFirstFire(false);
        } else {
          if (countM.applications_count !== count) {
            setCount(countM.applications_count);
            //console.log("played");
            audio.play();
            setNewRegApp(true);
            fetchData();
          }
        }
      }
    );

    // let counter = {};
    // const firer = onSnapshot(doc(db, "statistics", "statistics"),
    //   async (doc) => {
    //     counter = doc.data();
    //     if(firstFire ===true) {
    //       setCount2(counter.total_registrations);
    //       set
    //     }
    //   }
    // )
  }, [isLoaded === false]);

  const minusPagec = () => {
    if (pageCount <= 1) {
      return;
    } else {
      setDisable(true);
      const pageCountM = pageCount - 1;
      setPageCount((prev) => prev - 1);
      fetchData(pageCountM);
    }
  };
  const addPagec = () => {
    if (data?.length <= resultPerPage - 1) return data;
    setDisable1(true);
    const pageCountM = pageCount + 1;
    setPageCount((prev) => prev + 1);
    fetchData(pageCountM);
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    setIsLoaded(false);
    setPageCount(1);
  };

  const fetchData = async (pageCountM) => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_reg_request/reg_requests",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: pageCountM || pageCount,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const resultM = await response.json();
      //console.log(resultM);
      setResult(resultM);
      setIsLoaded(true);
      getData(resultM.delivery_agents);
      //console.log(result);
      //console.log(data);
      if (response.status === 200) {
        setDisable(false);
        setDisable1(false);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  if (!isLoaded) {
    //console.log("got here");
    return (
      <h1 className="loading-pages">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (result.msg === "Success") {
    //console.log("data gotten succesfully");
    return (
      <div className="agent-applications">
        <div className="application-properties">
          <div className="application-header">
            <h1 className="application-title">Delivery Agent Applications</h1>
          </div>
          <form>
            Result Per Page
            <input
              className="chizys-input"
              type="number"
              value={resultPerPage}
              onChange={(e) => setResultPerPage(e.target.value)}
            />
            <button className="chizys-button" onClick={handleButtonClick}>
              Done
            </button>
          </form>
          <div>
            <table className="application-table">
              <th>
                <div className="table-left">Name</div>
              </th>
              <th>Phone Number</th>
              <th>Application type</th>
              <tbody>
                {data.map((item, i) => (
                  <tr
                    key={i}
                    onClick={() =>
                      navigate("/App/Agent_Form", {
                        state: { id: item._id, type: item.delivery_agent_type },
                      })
                    }
                  >
                    <td>
                      <div className="table-left">{item?.fullname}</div>
                    </td>
                    <td>{item?.phone_no}</td>
                    <td>{item?.delivery_agent_type}</td>
                    <td
                      onClick={() =>
                        navigate("/App/Agent_Form", {
                          state: {
                            id: item._id,
                            type: item.delivery_agent_type,
                          },
                        })
                      }
                    >
                      view details
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            style={{
              height: "20px",
              display: "flex",
              alignContent: "center",
              justifyContent: "flex-end",
              paddingRight: "20px",
              marginTop: "10px",
            }}
          >
            <div>
              {disable ? (
                <ClipLoader color={"black"} loading={disable} size={15} />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className={pageCount <= 1 ? "icon-space-less" : "icon-space"}
                  onClick={minusPagec}
                />
              )}
            </div>
            <h6>{PageCounter(result.count, pageCount, resultPerPage)}</h6>
            <div>
              {disable1 ? (
                <ClipLoader color={"black"} loading={disable1} size={15} />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className={
                    data?.length <= resultPerPage - 1
                      ? "icon-space-less"
                      : "icon-space"
                  }
                  onClick={addPagec}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (result.msg === "No registration requests availabe presently") {
    //console.log("and here");
    return <No_Agent_Application />;
  }
}
