import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import "../../component/css/fleet_managers.css";
import { IoMdMore } from "react-icons/io";
import { useNavigate, Link } from "react-router-dom";
import aang from "../../component/images/aang.jpg";
import No_Fleet from "../../component/notfound/No_Fleet";
import { FaSearch } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";
import ClickAwayListener from "react-click-away-listener";
import PageCounter from "../../component/page_counter";
import {
  ClassNameDeterminer,
  NameTrimer,
} from "../../component/content_trimer";

export const ZeroChecker = (cash) => {
  if (cash === 0) {
    return "₦0.00";
  } else {
    return "₦" + cash.toLocaleString();
  }
};

export default function Fleet_Managers() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [searchString, setSearchString] = useState("");
  const token = JSON.parse(sessionStorage.getItem("rubbish"));
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const [search, setSearch] = useState(false);
  const [resultPerPage, setResultPerPage] = useState(20);

  useEffect(() => {
    fetchData();
  }, [isLoaded === false]);

  const minusPagec = () => {
    if (pageCount <= 1) {
      return;
    } else {
      setDisable(true);
      const pageCountM = pageCount - 1;
      setPageCount((prev) => prev - 1);
      if (search) {
        fetchSearchData(pageCountM);
      } else {
        fetchData(pageCountM);
      }
    }
  };
  const addPagec = () => {
    if (data?.length <= resultPerPage - 1) return data;
    setDisable1(true);
    const pageCountM = pageCount + 1;
    setPageCount((prev) => prev + 1);
    if (search) {
      fetchSearchData(pageCountM);
    } else {
      fetchData(pageCountM);
    }
  };
  const DataChecker = (data) => {
    if (data) {
      return data;
    } else {
      return "No Data Yet";
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    setIsLoaded(false);
    setPageCount(1);
  };

  const fetchData = async (pageCountM) => {
    try {
      setSearch(false);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/view_ops/view_fleet_managers",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: pageCountM || pageCount,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // setData(await response.json());
      const resultM = await response.json();
      setResult(resultM);
      setIsLoaded(true);
      setData(resultM.fleet_managers);
      //console.log(data);
      //console.log(resultM);

      if (resultM.msg === "Success") {
        setDisable(false);
        setDisable1(false);
        //console.log("data gotten succesfully");
      } else {
        setData("");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const fetchSearchData = async (pageCountM) => {
    try {
      //console.log(searchString);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_search/fleet_managers",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: pageCountM || pageCount,
            search: searchString,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );

      //console.log("got here");
      // setData(await response.json());
      const result = await response.json();
      setResult(result);
      setData(result.fleet_managers);
      //console.log(result);
      //console.log(data);

      if (result.msg === "Success") {
        //console.log("data gotten succesfully");
        setDisable(false);
        setDisable1(false);
      } else {
        setData("");
        //console.log("somen error occurred");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  // function Fleet(props) {
  //   const [popUpMenu, setPopUpMenu] = React.useState(false);
  //   const details = props.name;
  //   return (
  //     <div className="fleet-App">
  //       <button
  //         onClick={() => setPopUpMenu(!popUpMenu)}
  //         className="more-details"
  //       >
  //         <IoMdMore />
  //       </button>
  //       {popUpMenu && (
  //         <ClickAwayListener onClickAway={() => setPopUpMenu(false)}>
  //           {PopUpMenu(details)}
  //         </ClickAwayListener>
  //       )}
  //     </div>
  //   );
  // }

  const Menu = (fleet_manager_details) => (
    <div className="admin-App">
      <Popup
        trigger={
          <button className="more-details">
            {" "}
            <IoMdMore />{" "}
          </button>
        }
        position="right top"
        on="click"
        closeOnDocumentClick
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        // contentStyle={{ padding: '0px', border: 'none' }}
        // arrow={false}
      >
        <ul className="drop-down4">
          <li
            onClick={() =>
            // console.log(JSON.stringify(fleet_manager_details))
              navigate("/App/Individual_Fleet", {
                state: { id: fleet_manager_details.id },
              })
            }
          >
            View Fleet Manager profile
          </li>
          <li
            onClick={() =>
              navigate("/App/Message_Fleet_Manager", {
                state: { details: fleet_manager_details },
              })
            }
          >
            Message Fleet Manager
          </li>
          <li
            onClick={() =>
              navigate("/App/Block_Fleet_Manager", {
                state: { details: fleet_manager_details },
              })
            }
          >
            Block Fleet Manager account
          </li>
          <li
            onClick={() =>
              navigate("/App/Delete_Fleet_Manager", {
                state: { details: fleet_manager_details },
              })
            }
          >
            Delete Fleet Manager account
          </li>
        </ul>
      </Popup>
    </div>
  );

  // function PopUpMenu(props) {
  //   const fleet_manager_details = props;
  //   //console.log(fleet_manager_details);
  //   return (
  //     <ul className="drop-down">
  //       <li
  //         onClick={() =>
  //           navigate("/App/Individual_Fleet", {
  //             state: { id: fleet_manager_details._id },
  //           })
  //         }
  //       >
  //         View Fleet Manager profile
  //       </li>
  //       <li
  //         onClick={() =>
  //           navigate("/App/Message_Fleet_Manager", {
  //             state: { details: fleet_manager_details },
  //           })
  //         }
  //       >
  //         Message Fleet Manager
  //       </li>
  //       <li
  //         onClick={() =>
  //           navigate("/App/Block_Fleet_Manager", {
  //             state: { details: fleet_manager_details },
  //           })
  //         }
  //       >
  //         Block Fleet Manager account
  //       </li>
  //       <li
  //         onClick={() =>
  //           navigate("/App/Delete_Fleet_Manager", {
  //             state: { details: fleet_manager_details },
  //           })
  //         }
  //       >
  //         Delete Fleet Manager account
  //       </li>
  //     </ul>
  //   );
  // }

  if (!isLoaded) {
    //console.log("got here");
    return (
      <h1 className="loading-pages">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (data?.length > 0) {
    //console.log("data gotten succesfully");
    return (
      <div className="fleet-managers">
        <div className="fleet-properties">
          <h1 className="fleet-title">Fleet Managers</h1>
          <div className="fleet-table-header-prop">
            <div className="users-search-box-container">
              <div className="main-search-box-container">
                <input
                  type="text"
                  placeholder="Search Fleet Managers"
                  className="search-box"
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                />
                <button className="delivery-searching">
                  <FaSearch
                    onClick={() => {
                      fetchSearchData();
                      setSearch(true);
                      setPageCount(1);
                    }}
                  />
                </button>
              </div>c
            </div>

            <div className="result-per-page">
              <form>
                Results per page
                <input
                  className="chizys-input"
                  type="number"
                  value={resultPerPage}
                  onChange={(e) => setResultPerPage(e.target.value)}
                />
                <button className="chizys-button" onClick={handleButtonClick}>
                  Done
                </button>
              </form>
            </div>
            <table className="all-fleet-table">
              <th>
                <div className="agent-table-left">Picture</div>
              </th>
              <th>Fleet ID</th>
              <th>Fleet name</th>
              <th>Email address</th>
              <th>Phone number</th>
              <th>Total Earnings</th>
              <tbody>
                {data.map((item, i) => (
                  <tr key={i}>
                    <td>
                      <div className="users-table-pic">
                        <img
                          src={item?.img_url ? item?.img_url : aang}
                          alt=""
                          className="users-table-pic"
                          style={{ objectFit: "cover" }}
                        />
                      </div>{" "}
                    </td>
                    {/* <td>{item._id}</td> */}
                    <td>{item.fleet_manager_code}</td>
                    <td className={ClassNameDeterminer(item?.fleet_name)}>
                      {NameTrimer(DataChecker(item?.fleet_name))}
                      <p style={{ display: "none" }}>
                        {DataChecker(item?.fleet_name)}
                      </p>
                    </td>
                    <td>{item.email}</td>
                    <td>{item.phone_no}</td>
                    <td>{ZeroChecker(item.total_earnings)}</td>
                    <td>
                      <div className="popup-click">
                        {Menu({
                          id: item._id,
                          email: item?.email,
                          name: item.fleet_name,
                          code: item.fleet_manager_code,
                          fullname: item?.fullname,
                          img: item?.img_url,
                        })}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            style={{
              height: "20px",
              display: "flex",
              alignContent: "center",
              justifyContent: "flex-end",
              paddingRight: "20px",
              marginTop: "10px",
            }}
          >
            <div>
              {disable ? (
                <ClipLoader color={"black"} loading={disable} size={15} />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className={pageCount <= 1 ? "icon-space-less" : "icon-space"}
                  onClick={minusPagec}
                />
              )}
            </div>
            <h6>{PageCounter(result.count, pageCount, resultPerPage)}</h6>
            <div>
              {disable1 ? (
                <ClipLoader color={"black"} loading={disable1} size={15} />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className={
                    data?.length <= resultPerPage - 1
                      ? "icon-space-less"
                      : "icon-space"
                  }
                  onClick={addPagec}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (data?.length === 0) {
    //console.log("and here");
    return <No_Fleet />;
  }
}
