import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import "../../component/css/revenue.css";
import dayjs from "dayjs";
import { ClipLoader } from "react-spinners";

const Converter = (amount) => {
  //console.log(amount)
  return "₦" + amount.toLocaleString();
};

export const ZeroChecker = (cash) => {
  if (cash === 0) {
    return "₦0.00";
  } else {
    return "₦" + cash.toLocaleString();
  }
};

export default function Revenue() {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState({});
  const [year, setYear] = useState(2022);
  const [week, setWeek] = useState(4);
  const [month, setMonth] = useState(2);
  const [isLoaded, setIsLoaded] = useState(false);
  const token = JSON.parse(sessionStorage.getItem("rubbish"));
  const [result, setResult] = useState("");
  const [pickload_percent, setPickloadPercent] = useState(54);

  const handleDate = (e) => {
    const newDate = dayjs(e.target.value).format("YYYY-MM-DD");
    //console.log(newDate);
  };

  useEffect(() => {
    fetchData();
  }, [isLoaded === false]);

  const fetchData = async () => {
    //console.log('got here')
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_revenue/revenue",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: 1,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // setData(await response.json());
      const resultM = await response.json();
      //console.log(resultM);
      setResult(resultM);
      setPickloadPercent(resultM.percentages.pickload_percent);
      setIsLoaded(true);
      setData(resultM.revenue);
      //console.log(result);
      //console.log(data);
      //console.log(data.total_individual_agent_earnings);
      //console.log(result);
      // data.forEach(element => {
      //   time = new Date(element.timestamp);
      //   timestamp = time.toLocaleDateString()
      //   //console.log(timestamp);
      // });

      if (response.status === 200) {
        //console.log('data gotten succesfully');
      } else {
        //console.log('somen error occurred');
      }

      const response2 = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_revenue/monthly_revenue",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: 1,
            week: week,
            month: month,
            year: year,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // setData(await response.json());
      const result2 = await response2.json();
      setData2(result2.revenue);
      //console.log(result2);
      //console.log(data2);
      //console.log(data2.revenue);
      // data.forEach(element => {
      //   time = new Date(element.timestamp);
      //   timestamp = time.toLocaleDateString()
      //   //console.log(timestamp);
      // });

      if (response2.status === 200) {
        //console.log('data gotten succesfully');
      } else {
        //console.log('somen error occurred');
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const [stackedData] = useState({
    labels: ["Week1", "Week2", "Week3", "Week4", "Week5"],
    datasets: [
      {
        type: "bar",
        label: "Individual Agents ",
        backgroundColor: "#F5EC16B2",
        data: [
          //   data.individual_agents_earnings,
          25, 12, 48,
        ],
      },
      {
        type: "bar",
        label: "Fleet Managers",
        backgroundColor: "#5BC0EB",
        data: [
          //   data.fleet_managers_earnings,
          84, 24, 75, 43, 56,
        ],
      },
      {
        type: "bar",
        label: "Pickload Earnings",
        backgroundColor: "#F20A0ACC",
        data: [
          //   data.pickload_earnings,
          52, 24, 74, 34, 34,
        ],
      },
    ],
  });

  const getLightTheme = () => {
    let stackedOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        tooltips: {
          mode: "index",
          intersect: false,
        },
        legend: {
          labels: {
            color: "#495057",
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          stacked: true,
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    };

    return {
      stackedOptions,
    };
  };

  const { stackedOptions } = getLightTheme();

  const [chartData] = useState({
    datasets: [
      {
        data: [pickload_percent, 50, 100],
        backgroundColor: ["#F20A0ACC", "#5BC0EB", "#F5EC16B2"],
        hoverBackgroundColor: ["#F20A0ACC", "#5BC0EB", "#F5EC16B2"],
      },
    ],
  });

  const [lightOptions] = useState({
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
  });

  //console.log(pickload_percent);

  if (!isLoaded) {
    //console.log('got here')
    return (
      <h1 className="loading-pages">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (result.msg === "Success") {
    return (
      <div className="revenue">
        <div className="revenue-grid">
          <div className="revenue-grid-1">
            <div className="revenue-item1">
              <div className="revenue-design1"></div>
              <div className="itemTitle">AGENT EARNINGS</div>
              <div className="iterations">
                <span className="noIterations">
                  {ZeroChecker(data?.total_delivery_agent_earnings)}
                </span>
              </div>
            </div>
            <div className="revenue-item2">
              <div className="itemTitle">INDIVIDUAL AGENT EARNINGS</div>
              <div className="revenue-iterations">
                <span className="noIterations">
                  {ZeroChecker(data?.total_individual_agent_earnings)}{" "}
                </span>
              </div>
              <div className="revenue-design2"></div>
            </div>
            <div className="revenue-item3">
              <div className="itemTitle">FLEET MANAGERS EARNINGS</div>
              <div className="revenue-iterations">
                <span className="noIterations">
                  {ZeroChecker(data?.total_fleet_manager_earnings)}
                </span>
              </div>
              <div className="revenue-design3"></div>
            </div>
          </div>
          {/* <div className="revenue-doughnut-chart">
                  <Chart type="doughnut" data={chartData} options={lightOptions} />
              </div> */}
          <div className="revenue-grid-2">
            <div className="revenue-item4">
              <div className="revenue-design4"></div>
              <div className="itemTitle">PICKLOAD EARNINGS </div>
              <div className="iterations">
                <span className="no-Iterations">
                  {ZeroChecker(data?.total_pickload_earnings)}
                </span>
              </div>
            </div>
            <div className="revenue-item5">
              <div className="revenue-design5"></div>
              <div className="itemTitle">TOTAL EARNINGS</div>
              <div className="iterations">
                <span className="no-Iterations">
                  {ZeroChecker(data?.total_revenue)}
                </span>
              </div>
            </div>
            <div className="revenue-item5">
              <div className="revenue-design5"></div>
              <div className="itemTitle">TOTAL REFUNDED AMOUNT</div>
              <div className="iterations">
                <span className="no-Iterations">
                  {ZeroChecker(data?.total_refunded_amount)}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="card">
          <Chart type="bar" data={stackedData} options={stackedOptions} className='revenue-barchart'/>
          <div className='calender-revenue1'><input type="date" className="calender-revenue" min="2000-01-02" /></div>
        </div> */}
      </div>
    );
  }
}
