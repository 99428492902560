const weekRangeGetter = (month, year) => {
  switch (year) {
    case 2022:
      return year_2022["months"][month]["ranges"];
    case 2023:
      return year_2023["months"][month]["ranges"];
    case 2024:
      return year_2024["months"][month]["ranges"];
    case 2025:
      return year_2025["months"][month]["ranges"];
    case 2026:
      return year_2026["months"][month]["ranges"];
    case 2027:
      return year_2027["months"][month]["ranges"];
    case 2028:
      return year_2028["months"][month]["ranges"];
    case 2029:
      return year_2029["months"][month]["ranges"];
    case 2030:
      return year_2030["months"][month]["ranges"];
    case 2031:
      return year_2031["months"][month]["ranges"];
    case 2032:
      return year_2032["months"][month]["ranges"];
    case 2034:
      return year_2034["months"][month]["ranges"];
    case 2035:
      return year_2035["months"][month]["ranges"];
    case 2036:
      return year_2036["months"][month]["ranges"];
    case 2037:
      return year_2037["months"][month]["ranges"];
    case 2038:
      return year_2038["months"][month]["ranges"];
    case 2039:
      return year_2039["months"][month]["ranges"];
    case 2040:
      return year_2040["months"][month]["ranges"];
    case 2041:
      return year_2041["months"][month]["ranges"];
    case 2042:
      return year_2042["months"][month]["ranges"];
  }
}

export function getWeekNumber (year, month, selected_day) {
  let year_list = [];
  const selected_month = month + 1;
  //console.log(`${selected_day}/${selected_month}/${year}`);
  if (year == 2022) {
    year_list = year_2022["months"][month]["ranges"];
  } else if (year == 2023) {
    year_list = year_2023["months"][month]["ranges"];
  } else if (year == 2024) {
    year_list = year_2024["months"][month]["ranges"];
  } else if (year == 2025) {
    year_list = year_2025["months"][month]["ranges"];
  } else if (year == 2026) {
    year_list = year_2026["months"][month]["ranges"];
  } else if (year == 2027) {
    year_list = year_2027["months"][month]["ranges"];
  } else if (year == 2028) {
    year_list = year_2028["months"][month]["ranges"];
  } else if (year == 2029) {
    year_list = year_2029["months"][month]["ranges"];
  } else if (year == 2030) {
    year_list = year_2030["months"][month]["ranges"];
  }
  else if (year == 2031) {
    year_list = year_2031["months"][month]["ranges"];
  } else if (year == 2032) {
    year_list = year_2032["months"][month]["ranges"];
  } else if (year == 2033) {
    year_list = year_2033["months"][month]["ranges"];
  } else if (year == 2034) {
    year_list = year_2034["months"][month]["ranges"];
  } else if (year == 2035) {
    year_list = year_2035["months"][month]["ranges"];
  } else if (year == 2036) {
    year_list = year_2036["months"][month]["ranges"];
  } else if (year == 2037) {
    year_list = year_2037["months"][month]["ranges"];
  } else if (year == 2038) {
    year_list = year_2038["months"][month]["ranges"];
  } else if (year == 2039) {
    year_list = year_2039["months"][month]["ranges"];
  } else if (year == 2040) {
    year_list = year_2040["months"][month]["ranges"];
  } else if (year == 2041) {
    year_list = year_2041["months"][month]["ranges"];
  } else if (year == 2042) {
    year_list = year_2042["months"][month]["ranges"];
  }
  else{
    return ("More loading...");
  }
  // int? week_no;
  let week_no = 0;
  let start_day = '';
  let end_day = '';
  let start_month = '';
  let end_month = '';
  for (let i = 0; i < year_list.length; i++) {
    start_day = Number.parseInt(year_list[i].split("-")[0].split("/")[0]);
    end_day = Number.parseInt(year_list[i].split("-")[1].split("/")[0]);
    start_month = Number.parseInt(year_list[i].split("-")[0].split("/")[1]);
    end_month = Number.parseInt(year_list[i].split("-")[1].split("/")[1]);
    if (start_month != end_month) {
      if(selected_day <= 6){
        week_no=1;
      }else if(selected_day >= 25){
        week_no = year_list.length;
      }
    } else {
      if (selected_day >= start_day && selected_day <= end_day) {
        week_no = year_list.indexOf(year_list[i]) + 1;
        break;
      }
    }
    // print("\n");
  }
  //console.log("week no: $week_no \n");
  return week_no;
}

const year_2022 = {
  "months": [
    {
      "month": 0, //January
      "no_of_weeks": 6,
      "ranges": [
        "26/12/2021- 01/01/2022",
        "02/01/2022 - 08/01/2022",
        "09/01/2022 - 15/01/2022",
        "16/01/2022 - 22/01/2022",
        "23/01/2022 - 29/01/2022",
        "30/01/2022 - 05/02/2022",
      ],
    },
    {
      "month": 1, //February
      "no_of_weeks": 5,
      "ranges": [
        "30/01/2022 - 05/02/2022",
        "06/02/2022 - 12/02/2022",
        "13/02/2022 - 19/02/2022",
        "20/02/2022 - 26/02/2023",
        "27/02/2022 - 05/03/2022",
      ],
    },
    {
      "month": 2, //March
      "no_of_weeks": 5,
      "ranges": [
        "27/02/2022 - 05/03/2022",
        "06/03/2022 - 12/03/2022",
        "13/32/2022 - 19/03/2022",
        "20/03/2022 - 26/03/2022",
        "27/03/2022 - 02/04/2022",
      ],
    },
    {
      "month": 3, //April
      "no_of_weeks": 5,
      "ranges": [
        "27/03/2022 - 02/04/2022",
        "03/04/2022 - 09/04/2022",
        "10/04/2022 - 16/04/2022",
        "17/04/2022 - 23/04/2022",
        "24/04/2022 - 30/04/2022",
      ],
    },
    {
      "month": 4, //May
      "no_of_weeks": 5,
      "ranges": [
        "01/05/2022 - 07/05/2022",
        "08/05/2022 - 14/05/2022",
        "15/05/2022 - 21/05/2022",
        "22/05/2022 - 28/05/2022",
        "29/05/2022 - 04/06/2022",
      ],
    },
    {
      "month": 5, //June
      "no_of_weeks": 5,
      "ranges": [
        "29/05/2022 - 04/06/2022",
        "05/06/2022 - 11/06/2022",
        "12/06/2022 - 18/06/2022",
        "19/06/2022 - 25/06/2022",
        "26/06/2022 - 02/07/2022",
      ],
    },
    {
      "month": 6, //July
      "no_of_weeks": 6,
      "ranges": [
        "26/06/2022 - 02/07/2022",
        "03/07/2022 - 09/07/2022",
        "10/07/2022 - 16/07/2022",
        "17/07/2022 - 23/07/2022",
        "24/07/2022 - 30/07/2022",
        "31/07/2022 - 06/08/2022",
      ],
    },
    {
      "month": 7, //August
      "no_of_weeks": 5,
      "ranges": [
        "31/07/2022 - 06/08/2022",
        "07/08/2022 - 13/08/2022",
        "14/08/2022 - 20/08/2022",
        "21/08/2022 - 27/08/2022",
        "28/08/2022 - 03/09/2022",
      ],
    },
    {
      "month": 8, //September
      "no_of_weeks": 5,
      "ranges": [
        "28/08/2022 - 03/09/2022",
        "04/09/2022 - 10/09/2022",
        "11/09/2022 - 17/09/2022",
        "18/09/2022 - 24/09/2022",
        "25/09/2022 - 01/10/2022",
      ],
    },
    {
      "month": 9, //October
      "no_of_weeks": 6,
      "ranges": [
        "25/09/2022 - 01/10/2022",
        "02/10/2022 - 08/10/2022",
        "09/10/2022 - 15/10/2022",
        "16/10/2022 - 22/10/2022",
        "23/10/2022 - 29/10/2022",
        "30/10/2022 - 05/11/2022",
      ],
    },
    {
      "month": 10, //November
      "no_of_weeks": 5,
      "ranges": [
        "30/10/2022 - 05/11/2022",
        "06/11/2022 - 12/11/2022",
        "13/11/2022 - 19/11/2022",
        "20/11/2022 - 26/11/2022",
        "27/11/2022 - 03/12/2022",
      ],
    },
    {
      "month": 11, //December
      "no_of_weeks": 5,
      "ranges": [
        "27/11/2022 - 03/12/2022",
        "04/12/2022 - 10/12/2022",
        "11/12/2022 - 17/12/2022",
        "18/12/2022 - 24/12/2022",
        "25/12/2022 - 31/12/2022",
      ],
    },
  ],
};

const year_2023 = {
  "months": [
    {
      "month": 0, //January
      "no_of_weeks": 5,
      "ranges": [
        "01/01/2023 - 07/01/2023",
        "08/01/2023 - 14/01/2023",
        "15/01/2023 - 21/01/2023",
        "22/01/2023 - 28/01/2023",
        "29/01/2023 - 04/02/2023",
      ],
    },
    {
      "month": 1, //February
      "no_of_weeks": 5,
      "ranges": [
        "29/01/2023 - 04/02/2023",
        "05/01/2023 - 11/02/2023",
        "12/02/2023 - 18/02/2023",
        "19/02/2023 - 25/02/2023",
        "26/02/2023 - 04/03/2023",
      ],
    },
    {
      "month": 2, //March
      "no_of_weeks": 5,
      "ranges": [
        "26/02/2023 - 04/03/2023",
        "05/03/2023 - 11/03/2023",
        "12/03/2023 - 18/03/2023",
        "19/32/2023 - 25/03/2023",
        "26/03/2023 - 01/04/2023",
      ],
    },
    {
      "month": 3, //April
      "no_of_weeks": 6,
      "ranges": [
        "26/03/2023 - 01/04/2023",
        "02/04/2023 - 08/04/2023",
        "09/04/2023 - 15/04/2023",
        "16/04/2023 - 22/04/2023",
        "23/04/2023 - 29/04/2023",
        "30/04/2023 - 06/05/2023",
      ],
    },
    {
      "month": 4, //May
      "no_of_weeks": 5,
      "ranges": [
        "30/04/2023 - 06/05/2023",
        "07/05/2023 - 13/05/2023",
        "14/05/2023 - 20/05/2023",
        "21/05/2023 - 27/05/2023",
        "28/05/2023 - 03/06/2023",
      ],
    },
    {
      "month": 5, //June
      "no_of_weeks": 5,
      "ranges": [
        "28/05/2023 - 03/06/2023",
        "04/06/2023 - 10/06/2023",
        "11/06/2023 - 17/06/2023",
        "18/06/2023 - 24/06/2023",
        "25/06/2023 - 01/07/2023",
      ],
    },
    {
      "month": 6, //July
      "no_of_weeks": 6,
      "ranges": [
        "25/06/2023 - 01/07/2023",
        "02/07/2023 - 08/07/2023",
        "09/07/2023 - 15/07/2023",
        "16/07/2023 - 22/07/2023",
        "23/07/2023 - 29/07/2023",
        "30/07/2023 - 05/08/2023",
      ],
    },
    {
      "month": 7, //August
      "no_of_weeks": 5,
      "ranges": [
        "30/07/2023 - 05/08/2023",
        "06/08/2023 - 12/08/2023",
        "13/08/2023 - 19/08/2023",
        "20/08/2023 - 26/08/2023",
        "27/08/2023 - 02/09/2023",
      ],
    },
    {
      "month": 8, //September
      "no_of_weeks": 5,
      "ranges": [
        "27/08/2023 - 02/09/2023",
        "03/09/2023 - 09/09/2023",
        "10/09/2023 - 16/09/2023",
        "17/09/2023 - 23/09/2023",
        "24/09/2023 - 30/09/2023",
      ],
    },
    {
      "month": 9, //October
      "no_of_weeks": 5,
      "ranges": [
        "01/10/2023 - 07/10/2023",
        "08/10/2023 - 14/10/2023",
        "15/10/2023 - 21/10/2023",
        "22/10/2023 - 28/10/2023",
        "29/10/2023 - 04/11/2023",
      ],
    },
    {
      "month": 10, //November
      "no_of_weeks": 5,
      "ranges": [
        "29/10/2023 - 04/11/2023",
        "05/11/2023 - 11/11/2023",
        "12/11/2023 - 18/11/2023",
        "19/11/2023 - 25/11/2023",
        "26/11/2023 - 02/12/2023",
      ],
    },
    {
      "month": 11, //December
      "no_of_weeks": 6,
      "ranges": [
        "26/11/2023 - 02/12/2023",
        "03/12/2023 - 09/12/2023",
        "10/12/2023 - 16/12/2023",
        "17/12/2023 - 23/12/2023",
        "24/12/2023 - 30/12/2023",
        "31/12/2023 - 06/01/2024",
      ],
    },
  ],
};

const year_2024 = {
  "months": [
    {
      "month": 0, //January
      "no_of_weeks": 5,
      "ranges": [
        "31/12/2023 - 06/01/2024",
        "07/01/2024 - 13/01/2024",
        "14/01/2024 - 20/01/2024",
        "21/01/2024 - 27/01/2024",
        "28/01/2024 - 03/02/2024",
      ],
    },
    {
      "month": 1, //February
      "no_of_weeks": 5,
      "ranges": [
        "28/01/2024 - 03/02/2024",
        "04/02/2024 - 10/02/2024",
        "11/02/2024 - 17/02/2024",
        "18/02/2024 - 24/02/2024",
        "25/02/2024 - 02/03/2024",
      ],
    },
    {
      "month": 2, //March
      "no_of_weeks": 6,
      "ranges": [
        "25/02/2024 - 02/03/2024",
        "03/03/2024 - 09/03/2024",
        "10/03/2024 - 16/03/2024",
        "17/03/2024 - 23/03/2024",
        "24/03/2024 - 30/03/2024",
        "31/03/2024 - 06/04/2024",
      ],
    },
    {
      "month": 3, //April
      "no_of_weeks": 5,
      "ranges": [
        "31/03/2024 - 06/04/2024",
        "07/04/2024 - 13/04/2024",
        "14/04/2024 - 20/04/2024",
        "21/04/2024 - 27/04/2024",
        "28/04/2024 - 04/05/2024",
      ],
    },
    {
      "month": 4, //May
      "no_of_weeks": 5,
      "ranges": [
        "28/04/2024 - 04/05/2024",
        "05/05/2024 - 11/05/2024",
        "12/05/2024 - 18/05/2024",
        "19/05/2024 - 25/05/2024",
        "26/05/2024 - 01/06/2024",
      ],
    },
    {
      "month": 5, //June
      "no_of_weeks": 6,
      "ranges": [
        "26/05/2024 - 01/06/2024",
        "02/06/2024 - 08/06/2024",
        "09/06/2024 - 15/06/2024",
        "16/06/2024 - 22/06/2024",
        "23/06/2024 - 29/06/2024",
        "30/06/2024 - 06/07/2024",
      ],
    },
    {
      "month": 6, //July
      "no_of_weeks": 5,
      "ranges": [
        "30/06/2024 - 06/07/2024",
        "07/07/2024 - 13/07/2024",
        "14/07/2024 - 20/07/2024",
        "21/07/2024 - 27/07/2024",
        "28/07/2024 - 03/08/2024",
      ],
    },
    {
      "month": 7, //August
      "no_of_weeks": 5,
      "ranges": [
        "28/07/2024 - 03/08/2024",
        "04/08/2024 - 10/08/2024",
        "11/08/2024 - 17/08/2024",
        "18/08/2024 - 24/08/2024",
        "25/08/2024 - 31/08/2024",
      ],
    },
    {
      "month": 8, //September
      "no_of_weeks": 5,
      "ranges": [
        "01/09/2024 - 07/09/2024",
        "08/09/2024 - 14/09/2024",
        "15/09/2024 - 21/09/2024",
        "22/09/2024 - 28/09/2024",
        "29/09/2024 - 05/10/2024",
      ],
    },
    {
      "month": 9, //October
      "no_of_weeks": 5,
      "ranges": [
        "29/09/2024 - 05/10/2024",
        "06/10/2024 - 12/10/2024",
        "13/10/2024 - 19/10/2024",
        "20/10/2024 - 26/10/2024",
        "27/10/2024 - 02/11/2024",
      ],
    },
    {
      "month": 10, //November
      "no_of_weeks": 5,
      "ranges": [
        "27/10/2024 - 02/11/2024",
        "03/11/2024 - 09/11/2024",
        "10/11/2024 - 16/11/2024",
        "17/11/2024 - 23/11/2024",
        "24/11/2024 - 30/11/2024",
      ],
    },
    {
      "month": 11, //December
      "no_of_weeks": 5,
      "ranges": [
        "01/12/2024 - 07/12/2024",
        "08/12/2024 - 14/12/2024",
        "15/12/2024 - 21/12/2024",
        "22/12/2024 - 28/12/2024",
        "29/12/2024 - 04/01/2025",
      ],
    },
  ],
};

const year_2025 = {
  "months": [
    {
      "month": 0, //January
      "no_of_weeks": 5,
      "ranges": [
        "29/12/2024 - 04/01/2025",
        "05/01/2025 - 11/01/2025",
        "12/01/2025 - 18/01/2025",
        "19/01/2025 - 25/01/2025",
        "26/01/2025 - 01/02/2025",
      ],
    },
    {
      "month": 1, //February
      "no_of_weeks": 5,
      "ranges": [
        "26/01/2025 - 01/02/2025",
        "02/02/2025 - 08/02/2025",
        "09/02/2025 - 15/02/2025",
        "16/02/2025 - 22/02/2025",
        "23/02/2025 - 01/03/2025",
      ],
    },
    {
      "month": 2, //March
      "no_of_weeks": 6,
      "ranges": [
        "23/02/2025 - 01/03/2025",
        "02/03/2025 - 08/03/2025",
        "09/03/2025 - 15/03/2025",
        "16/03/2025 - 22/03/2025",
        "23/03/2025 - 29/03/2025",
        "30/03/2025 - 05/04/2025",
      ],
    },
    {
      "month": 3, //April
      "no_of_weeks": 6,
      "ranges": [
        "30/03/2025 - 05/04/2025",
        "06/04/2025 - 12/04/2025",
        "13/04/2025 - 19/04/2025",
        "20/04/2025 - 26/04/2025",
        "27/04/2025 - 03/05/2025",
      ],
    },
    {
      "month": 4, //May
      "no_of_weeks": 5,
      "ranges": [
        "27/04/2025 - 03/05/2025",
        "04/05/2025 - 10/05/2025",
        "11/05/2025 - 17/05/2025",
        "18/05/2025 - 24/05/2025",
        "25/05/2025 - 31/05/2025",
      ],
    },
    {
      "month": 5, //June
      "no_of_weeks": 5,
      "ranges": [
        "01/06/2025 - 07/06/2025",
        "08/06/2025 - 14/06/2025",
        "15/06/2025 - 21/06/2025",
        "22/06/2025 - 28/06/2025",
        "29/06/2025 - 05/07/2025",
      ],
    },
    {
      "month": 6, //July
      "no_of_weeks": 5,
      "ranges": [
        "29/06/2025 - 05/07/2025",
        "06/07/2025 - 12/07/2025",
        "13/07/2025 - 19/07/2025",
        "20/07/2025 - 26/07/2025",
        "27/07/2025 - 02/08/2025",
      ],
    },
    {
      "month": 7, //August
      "no_of_weeks": 6,
      "ranges": [
        "27/07/2025 - 02/08/2025",
        "03/08/2025 - 09/08/2025",
        "10/08/2025 - 16/08/2025",
        "17/08/2025 - 23/08/2025",
        "24/08/2025 - 30/08/2025",
        "31/08/2025 - 06/09/2025",
      ],
    },
    {
      "month": 8, //September
      "no_of_weeks": 5,
      "ranges": [
        "31/08/2025 - 06/09/2025",
        "07/09/2025 - 13/09/2025",
        "14/09/2025 - 20/09/2025",
        "21/09/2025 - 27/09/2025",
        "28/09/2025 - 04/10/2025",
      ],
    },
    {
      "month": 9, //October
      "no_of_weeks": 5,
      "ranges": [
        "28/09/2025 - 04/10/2025",
        "05/10/2025 - 11/10/2025",
        "12/10/2025 - 18/10/2025",
        "19/10/2025 - 25/10/2025",
        "26/10/2025 - 01/11/2025",
      ],
    },
    {
      "month": 10, //November
      "no_of_weeks": 6,
      "ranges": [
        "26/10/2025 - 01/11/2025",
        "02/11/2025 - 08/11/2025",
        "09/11/2025 - 15/11/2025",
        "16/11/2025 - 22/11/2025",
        "23/11/2025 - 29/11/2025",
        "30/11/2025 - 06/12/2025",
      ],
    },
    {
      "month": 11, //December
      "no_of_weeks": 5,
      "ranges": [
        "30/11/2025 - 06/12/2025",
        "07/12/2025 - 13/12/2025",
        "14/12/2025 - 20/12/2025",
        "21/12/2025 - 27/12/2025",
        "28/12/2025 - 03/01/2026",
      ],
    },
  ],
};

const year_2026 = {
  "months": [
    {
      "month": 0, // january
      "no_of_weeks": 5,
      "ranges": [
        "28/12/2025 - 03/01/2026",
        "04/01/2026 - 10/01/2026",
        "11/01/2026 - 17/01/2026",
        "18/01/2026 - 24/01/2026",
        "25/01/2026 - 31/01/2026"
      ]
    },
    {
      "month": 1, //february
      "no_of_weeks": 4,
      "ranges": [
        "01/02/2026 - 07/02/2026",
        "08/02/2026 - 14/02/2026",
        "15/02/2026 - 21/02/2026",
        "22/02/2026 - 28/02/2026"
      ]
    },
    {
      "month": 2, //march
      "no_of_weeks": 5,
      "ranges": [
        "01/03/2026 - 07/03/2026",
        "08/03/2026 - 14/03/2026",
        "15/03/2026 - 21/03/2026",
        "22/03/2026 - 28/03/2026",
        "29/03/2026 - 04/04/2026",
      ]
    },
    {
      "month": 3, //april
      "no_of_weeks": 5,
      "ranges": [
        "29/03/2026 - 04/04/2026",
        "05/04/2026 - 11/04/2026",
        "12/04/2026 - 18/04/2026",
        "19/04/2026 - 25/04/2026",
        "26/04/2026 - 02/05/2026"
      ]
    },
    {
      "month": 4, //may
      "no_of_weeks": 6,
      "ranges": [
        "26/04/2026 - 02/05/2026",
        "03/05/2026 - 09/05/2026",
        "10/05/2026 - 16/05/2026",
        "17/05/2026 - 23/05/2026",
        "24/05/2026 - 30/05/2026",
        "31/05/2026 - 06/06/2026"
      ]
    },
    {
      "month": 5, //june
      "no_of_weeks": 5,
      "ranges": [
        "31/05/2026 - 06/06/2026",
        "07/06/2026 - 13/06/2026",
        "14/06/2026 - 20/06/2026",
        "21/06/2026 - 27/06/2026",
        "28/06/2026 - 04/06/2026"
      ]
    },
    {
      "month": 6, //july
      "no_of_weeks": 5,
      "ranges": [
        "28/06/2026 - 04/06/2026",
        "05/07/2026 - 11/07/2026",
        "12/07/2026 - 18/07/2026",
        "19/07/2026 - 25/07/2026",
        "26/07/2026 - 01/08/2026"
      ]
    },
    {
      "month": 7, //august
      "no_of_weeks": 6,
      "ranges": [
        "26/07/2026 - 01/08/2026",
        "02/08/2026 - 08/08/2026",
        "09/08/2026 - 15/08/2026",
        "16/08/2026 - 22/08/2026",
        "23/08/2026 - 29/08/2026",
        "30/08/2026 - 05/09/2026"
      ]
    },
    {
      "month": 8, //september
      "no_of_weeks": 5,
      "ranges": [
        "30/08/2026 - 05/09/2026",
        "06/09/2026 - 12/09/2026",
        "13/09/2026 - 19/09/2026",
        "20/09/2026 - 26/09/2026",
        "27/09/2026 - 03/09/2026"
      ]
    },
    {
      "month": 9, //october
      "no_of_weeks": 5,
      "ranges": [
        "27/09/2026 - 03/09/2026",
        "04/10/2026 - 10/10/2026",
        "11/10/2026 - 17/10/2026",
        "18/10/2026 - 24/10/2026",
        "25/10/2026 - 31/10/2026"
      ]
    },
    {
      "month": 10, //november
      "no_of_weeks": 5,
      "ranges": [
        "01/11/2026 - 07/11/2026",
        "08/11/2026 - 14/11/2026",
        "15/11/2026 - 21/11/2026",
        "22/11/2026 - 28/11/2026",
        "29/11/2026 - 05/12/2026"
      ]
    },
    {
      "month": 11, //december
      "no_of_weeks": 5,
      "ranges": [
        "29/11/2026 - 05/12/2026",
        "06/12/2026 - 12/12/2026",
        "13/12/2026 - 19/12/2026",
        "20/12/2026 - 26/12/2026",
        "27/12/2026 - 02/01/2027"
      ]
    },
  ],
};

const year_2027 = {
  "months": [
    {
      "month": 0, // january
      "no_of_weeks": 6,
      "ranges": [
        "27/12/2026 - 02/01/2027",
        "03/1/2026 - 09/01/2027",
        "10/01/2027 - 16/01/2027",
        "17/01/2027 - 23/01/2027",
        "24/01/2027 - 30/01/2027",
        "31/01/2027 - 6/02/2027"
      ]
    },
    {
      "month": 1, //february
      "no_of_weeks": 5,
      "ranges": [
        "31/01/2027 - 6/02/2027",
        "07/02/2027 - 13/02/2027",
        "14/02/2027 - 20/02/2027",
        "21/02/2027 - 27/02/2027",
        "28/02/2027 - 06/03/2027"
      ]
    },
    {
      "month": 2, //march
      "no_of_weeks": 5,
      "ranges": [
        "28/02/2027 - 06/03/2027",
        "07/03/2027 - 13/03/2027",
        "14/03/2027 - 20/03/2027",
        "21/03/2027 - 27/03/2027",
        "28/03/2027 - 03/04/2027"
      ]
    },
    {
      "month": 3, //april
      "no_of_weeks": 5,
      "ranges": [
        "28/03/2027 - 03/04/2027",
        "04/04/2027 - 10/04/2027",
        "11/04/2027 - 17/04/2027",
        "18/04/2027 - 24/04/2027",
        "25/04/2027 - 01/05/2027"
      ]
    },
    {
      "month": 4, //may
      "no_of_weeks": 6,
      "ranges": [
        "25/04/2027 - 01/05/2027",
        "02/05/2027 - 08/05/2027",
        "09/05/2027 - 15/05/2027",
        "16/05/2027 - 22/05/2027",
        "23/05/2027 - 29/05/2027",
        "30/05/2027 - 05/06/2027"
      ]
    },
    {
      "month": 5, //june
      "no_of_weeks": 5,
      "ranges": [
        "30/05/2027 - 05/06/2027",
        "06/06/2027 - 12/06/2027",
        "13/06/2027 - 19/06/2027",
        "20/06/2027 - 26/06/2027",
        "27/06/2027 - 03/06/2027"
      ]
    },
    {
      "month": 6, //july
      "no_of_weeks": 5,
      "ranges": [
        "27/06/2027 - 03/06/2027",
        "04/07/2027 - 10/07/2027",
        "11/07/2027 - 17/07/2027",
        "18/07/2027 - 24/07/2027",
        "25/07/2027 - 31/08/2027"
      ]
    },
    {
      "month": 7, //august
      "no_of_weeks": 5,
      "ranges": [
        "01/08/2027 - 07/08/2027",
        "08/08/2027 - 14/08/2027",
        "15/08/2027 - 21/08/2027",
        "22/08/2027 - 28/08/2027",
        "29/08/2027 - 04/09/2027"
      ]
    },
    {
      "month": 8, //september
      "no_of_weeks": 5,
      "ranges": [
        "29/08/2027 - 04/09/2027",
        "05/09/2027 - 11/09/2027",
        "12/09/2027 - 18/09/2027",
        "19/09/2027 - 25/09/2027",
        "26/09/2027 - 02/09/2027"
      ]
    },
    {
      "month": 9, //october
      "no_of_weeks": 6,
      "ranges": [
        "26/09/2027 - 02/09/2027",
        "03/10/2027 - 09/10/2027",
        "10/10/2027 - 16/10/2027",
        "17/10/2027 - 23/10/2027",
        "24/10/2027 - 30/10/2027",
        "31/10/2027 - 06/11/2027"
      ]
    },
    {
      "month": 10, //november
      "no_of_weeks": 5,
      "ranges": [
        "31/10/2027 - 06/11/2027",
        "07/11/2027 - 13/11/2027",
        "14/11/2027 - 20/11/2027",
        "21/11/2027 - 27/11/2027",
        "28/11/2027 - 04/12/2027",
      ]
    },
    {
      "month": 11, //december
      "no_of_weeks": 5,
      "ranges": [
        "28/11/2027 - 04/12/2027",
        "05/12/2027 - 11/12/2027",
        "12/12/2027 - 18/12/2027",
        "19/12/2027 - 25/12/2027",
        "26/12/2027 - 01/01/2028"
      ]
    },
  ],
};

const year_2028 = {
  "months": [
    {
      "month": 0, // january
      "no_of_weeks": 6,
      "ranges": [
        "26/12/2027 - 01/01/2028",
        "02/01/2028 - 08/01/2028",
        "09/01/2028 - 15/01/2028",
        "16/01/2028 - 22/01/2028",
        "23/01/2028 - 29/01/2028",
        "30/01/2027 - 05/02/2028"
      ]
    },
    {
      "month": 1, // febuary
      "no_of_weeks": 5,
      "ranges": [
        "30/01/2027 - 05/02/2028",
        "06/02/2028 - 12/02/2028",
        "13/02/2028 - 19/02/2028",
        "20/02/2028 - 26/02/2028",
        "27/02/2028 - 04/03/2028",
      ]
    },
    {
      "month": 2, // march
      "no_of_weeks": 5,
      "ranges": [
        "27/02/2028 - 04/03/2028",
        "05/03/2028 - 11/03/2028",
        "12/03/2028 - 18/03/2028",
        "29/03/2028 - 25/03/2028",
        "26/03/2028 - 01/04/2028",
      ]
    },
    {
      "month": 3, // april
      "no_of_weeks": 6,
      "ranges": [
        "26/03/2028 - 01/04/2028",
        "02/04/2028 - 08/04/2028",
        "09/04/2028 - 15/04/2028",
        "16/04/2028 - 22/04/2028",
        "23/04/2028 - 29/04/2028",
        "30/04/2028 - 06/05/2028"
      ]
    },
    {
      "month": 4, // may
      "no_of_weeks": 5,
      "ranges": [
        "30/04/2028 - 06/05/2028",
        "07/05/2028 - 13/05/2028",
        "14/05/2028 - 20/05/2028",
        "21/05/2028 - 27/05/2028",
        "28/05/2028 - 03/06/2028"
      ]
    },
    {
      "month": 5, // june
      "no_of_weeks": 5,
      "ranges": [
        "28/05/2028 - 03/06/2028",
        "04/06/2028 - 10/06/2028",
        "11/06/2028 - 17/06/2028",
        "18/06/2028 - 24/06/2028",
        "25/06/2028 - 01/07/2028"
      ]
    },
    {
      "month": 6, // july
      "no_of_weeks": 6,
      "ranges": [
        "25/06/2028 - 01/07/2028",
        "02/07/2028 - 08/07/2028",
        "09/07/2028 - 15/07/2028",
        "16/07/2028 - 22/07/2028",
        "23/07/2028 - 29/07/2028",
        "30/07/2028 - 05/08/2028"
      ]
    },
    {
      "month": 7, // august
      "no_of_weeks": 5,
      "ranges": [
        "30/07/2028 - 05/08/2028",
        "06/08/2028 - 12/08/2028",
        "13/08/2028 - 19/08/2028",
        "20/08/2028 - 26/08/2028",
        "27/08/2028 - 02/09/2028"
      ]
    },
    {
      "month": 8, // september
      "no_of_weeks": 5,
      "ranges": [
        "27/08/2028 - 02/09/2028",
        "03/09/2028 - 09/09/2028",
        "10/09/2028 - 16/09/2028",
        "17/09/2028 - 23/09/2028",
        "24/09/2028 - 30/09/2028"
      ]
    },
    {
      "month": 9, // october
      "no_of_weeks": 5,
      "ranges": [
        "01/10/2028 - 07/10/2028",
        "08/10/2028 - 14/10/2028",
        "15/10/2028 - 21/10/2028",
        "22/10/2028 - 28/10/2028",
        "29/10/2028 - 04/11/2028"
      ]
    },
    {
      "month": 10, // november
      "no_of_weeks": 5,
      "ranges": [
        "29/10/2028 - 04/11/2028",
        "05/11/2028 - 11/11/2028",
        "12/11/2028 - 18/11/2028",
        "19/11/2028 - 25/11/2028",
        "26/11/2028 - 02/12/2028"
      ]
    },
    {
      "month": 11, // december
      "no_of_weeks": 6,
      "ranges": [
        "26/11/2028 - 02/12/2028",
        "03/12/2028 - 09/12/2028",
        "10/12/2028 - 16/12/2028",
        "17/12/2028 - 23/12/2028",
        "24/12/2028 - 30/12/2028",
        "31/12/2028 - 06/01/2029"
      ]
    }
  ]
};

const year_2029 = {
  "months": [
    {
      "month": 0, //January
      "no_of_weeks": 5,
      "ranges": [
        "31/12/2028 - 06/01/2029",
        "07/01/2029 - 13/01/2029",
        "14/01/2029 - 20/01/2029",
        "21/01/2029 - 27/01/2029",
        "28/01/2029 - 03/02/2029",
      ],
    },
    {
      "month": 1, //February
      "no_of_weeks": 5,
      "ranges": [
        "28/01/2029 - 03/02/2029",
        "04/02/2029 - 10/02/2029",
        "11/02/2029 - 17/02/2029",
        "18/02/2029 - 24/02/2029",
        "25/02/2029 - 03/03/2029",
      ],
    },
    {
      "month": 2, //March
      "no_of_weeks": 5,
      "ranges": [
        "25/02/2029 - 03/03/2029",
        "04/03/2029 - 10/03/2029",
        "11/32/2029 - 17/03/2029",
        "18/03/2029 - 24/03/2029",
        "25/03/2029 - 31/04/2029",
      ],
    },
    {
      "month": 3, //April
      "no_of_weeks": 5,
      "ranges": [
        "1/04/2029 - 07/04/2029",
        "08/04/2029 - 14/04/2029",
        "15/04/2029 - 21/04/2029",
        "22/04/2029 - 28/04/2029",
        "29/04/2029 - 05/05/2029",
      ],
    },
    {
      "month": 4, //May
      "no_of_weeks": 5,
      "ranges": [
        "29/04/2029 - 05/05/2029",
        "06/05/2029 - 12/05/2029",
        "13/05/2029 - 19/05/2029",
        "20/05/2029 - 26/05/2029",
        "27/05/2029 - 02/06/2029",
      ],
    },
    {
      "month": 5, //June
      "no_of_weeks": 5,
      "ranges": [
        "27/05/2029 - 02/06/2029",
        "03/06/2029 - 09/06/2029",
        "10/06/2029 - 16/06/2029",
        "17/06/2029 - 23/06/2029",
        "24/06/2029 - 30/06/2029",
      ],
    },
    {
      "month": 6, //July
      "no_of_weeks": 5,
      "ranges": [
        "01/07/2029 - 07/07/2029",
        "08/07/2029 - 14/07/2029",
        "15/07/2029 - 21/07/2029",
        "22/07/2029 - 28/07/2029",
        "29/07/2029 - 04/08/2029",
      ],
    },
    {
      "month": 7, //August
      "no_of_weeks": 5,
      "ranges": [
        "29/07/2029 - 04/08/2029",
        "05/08/2029 - 11/08/2029",
        "12/08/2029 - 18/08/2029",
        "19/08/2029 - 25/08/2029",
        "26/08/2029 - 01/09/2029",
      ],
    },
    {
      "month": 8, //September
      "no_of_weeks": 6,
      "ranges": [
        "26/08/2029 - 01/09/2029",
        "02/09/2029 - 08/09/2029",
        "09/09/2029 - 15/09/2029",
        "16/09/2029 - 22/09/2029",
        "23/09/2029 - 29/10/2029",
        "30/09/2029 - 06/10/2020",
      ],
    },
    {
      "month": 9, //October
      "no_of_weeks": 5,
      "ranges": [
        "30/09/2029 - 06/10/2029",
        "07/10/2029 - 13/10/2029",
        "14/10/2029 - 20/10/2029",
        "21/10/2029 - 27/10/2029",
        "28/10/2029 - 03/11/2029",
      ],
    },
    {
      "month": 10, //November
      "no_of_weeks": 5,
      "ranges": [
        "28/10/2029 - 03/11/2029",
        "04/11/2029 - 10/11/2029",
        "11/11/2029 - 17/11/2029",
        "18/11/2029 - 24/11/2029",
        "25/11/2029 - 01/12/2029",
      ],
    },
    {
      "month": 11, //December
      "no_of_weeks": 6,
      "ranges": [
        "25/11/2029 - 01/12/2029",
        "02/12/2029 - 08/12/2029",
        "09/12/2029 - 15/12/2029",
        "16/12/2029 - 22/12/2029",
        "23/12/2029 - 29/12/2029",
        "30/12/2029 - 05/01/2030",
      ],
    },
  ],
};

const year_2030 = {
  "months": [
    {
      "month": 0, //January
      "no_of_weeks": 5,
      "ranges": [
        "30/12/2029 - 05/01/2030",
        "06/01/2030 - 12/01/2030",
        "13/01/2030 - 19/01/2030",
        "17/01/2030 - 26/01/2030",
        "27/01/2030 - 02/02/2030",
      ],
    },
    {
      "month": 1, //February
      "no_of_weeks": 5,
      "ranges": [
        "27/01/2030 - 02/02/2030",
        "03/02/2030 - 09/02/2030",
        "10/02/2030 - 16/02/2030",
        "17/02/2030 - 23/02/2030",
        "24/02/2030 - 02/03/2030",
      ],
    },
    {
      "month": 2, //March
      "no_of_weeks": 6,
      "ranges": [
        "24/02/2030 - 02/03/2030",
        "03/03/2030 - 09/03/2030",
        "10/03/2030 - 16/03/2030",
        "17/03/2030 - 23/03/2030",
        "24/03/2030 - 30/03/2030",
        "31/03/2030 - 06/04/2030",
      ],
    },
    {
      "month": 3, //April
      "no_of_weeks": 5,
      "ranges": [
        "31/03/2030 - 06/04/2030",
        "07/04/2030 - 13/04/2030",
        "14/04/2030 - 20/04/2030",
        "21/04/2030 - 27/04/2030",
        "28/04/2030 - 04/05/2030",
      ],
    },
    {
      "month": 4, //May
      "no_of_weeks": 5,
      "ranges": [
        "28/04/2030 - 04/05/2030",
        "05/05/2030 - 11/05/2030",
        "12/05/2030 - 18/05/2030",
        "19/05/2030 - 25/05/2030",
        "26/05/2030 - 01/06/2030",
      ],
    },
    {
      "month": 5, //June
      "no_of_weeks": 6,
      "ranges": [
        "26/06/2030 - 01/06/2030",
        "02/06/2030 - 08/06/2030",
        "09/06/2030 - 15/06/2030",
        "16/06/2030 - 22/06/2030",
        "23/06/2030 - 29/06/2030",
        "30/06/2030 - 06/07/2030",
      ],
    },
    {
      "month": 6, //July
      "no_of_weeks": 5,
      "ranges": [
        "30/06/2030 - 06/07/2030",
        "07/07/2030 - 13/07/2030",
        "14/07/2030 - 20/07/2030",
        "21/07/2030 - 27/07/2030",
        "28/07/2030 - 03/08/2030",
      ],
    },
    {
      "month": 7, //August
      "no_of_weeks": 5,
      "ranges": [
        "28/07/2030 - 03/08/2030",
        "04/08/2030 - 10/08/2030",
        "11/08/2030 - 17/08/2030",
        "18/08/2030 - 24/08/2030",
        "25/08/2030 - 31/08/2030",
      ],
    },
    {
      "month": 8, //September
      "no_of_weeks": 5,
      "ranges": [
        "01/09/2030 - 07/09/2030",
        "08/09/2030 - 14/09/2030",
        "15/09/2030 - 21/09/2030",
        "22/09/2030 - 28/09/2030",
        "29/09/2030 - 05/10/2030",
      ],
    },
    {
      "month": 9, //October
      "no_of_weeks": 5,
      "ranges": [
        "29/09/2030 - 05/10/2030",
        "06/10/2030 - 12/10/2030",
        "13/10/2030 - 19/10/2030",
        "20/10/2030 - 26/10/2030",
        "27/10/2030 - 02/11/2030",
      ],
    },
    {
      "month": 10, //November
      "no_of_weeks": 5,
      "ranges": [
        "27/10/2030 - 02/11/2030",
        "03/11/2030 - 09/11/2030",
        "10/11/2030 - 16/11/2030",
        "17/11/2030 - 23/11/2030",
        "24/11/2030 - 30/11/2030",
      ],
    },
    {
      "month": 11, //December
      "no_of_weeks": 5,
      "ranges": [
        "01/12/2030 - 07/12/2030",
        "08/12/2030 - 14/12/2030",
        "15/12/2030 - 21/12/2030",
        "22/12/2030 - 28/12/2030",
        "29/12/2030 - 04/01/2031",
      ],
    },
  ],
};

const year_2031 = {
  "months": [
    {
      "month": 0, //January
      "no_of_weeks": 5,
      "ranges": [
        "29/12/2030 - 04/01/2031",
        "05/01/2031 - 11/01/2031",
        "12/01/2031 - 18/01/2031",
        "19/01/2031 - 25/01/2031",
        "26/01/2031 - 01/02/2031",
      ],
    },
    {
      "month": 1, //February
      "no_of_weeks": 5,
      "ranges": [
        "26/01/2031 - 01/02/2031",
        "02/02/2031 - 08/02/2031",
        "09/02/2031 - 15/02/2031",
        "16/02/2031 - 22/02/2031",
        "23/02/2031 - 01/03/2031",
      ],
    },
    {
      "month": 2, //March
      "no_of_weeks": 6,
      "ranges": [
        "23/02/2031 - 01/03/2031",
        "02/03/2031 - 08/03/2031",
        "09/32/2031 - 15/03/2031",
        "16/03/2031 - 22/03/2031",
        "23/03/2031 - 29/03/2031",
        "30/03/2031 - 05/04/2031",
      ],
    },
    {
      "month": 3, //April
      "no_of_weeks": 5,
      "ranges": [
        "30/03/2031 - 05/04/2031",
        "06/04/2031 - 12/04/2031",
        "13/04/2031 - 19/04/2031",
        "20/04/2031 - 26/04/2031",
        "27/04/2023 - 03/05/2031",
      ],
    },
    {
      "month": 4, //May
      "no_of_weeks": 5,
      "ranges": [
        "27/04/2031 - 03/05/2031",
        "04/05/2031 - 10/05/2031",
        "11/05/2031 - 17/05/2031",
        "18/05/2031 - 24/05/2031",
        "25/05/2023 - 31/05/2031",
      ],
    },
    {
      "month": 5, //June
      "no_of_weeks": 5,
      "ranges": [
        "01/06/2031 - 07/06/2031",
        "08/06/2031 - 14/06/2031",
        "15/06/2031 - 21/06/2031",
        "22/06/2031 - 28/06/2031",
        "29/06/2031 - 05/07/2031",
      ],
    },
    {
      "month": 6, //July
      "no_of_weeks": 5,
      "ranges": [
        "29/06/2031 - 05/07/2031",
        "06/07/2031 - 12/07/2031",
        "13/07/2031 - 19/07/2031",
        "20/07/2031 - 26/07/2031",
        "27/07/2031 - 02/08/2031",
      ],
    },
    {
      "month": 7, //August
      "no_of_weeks": 6,
      "ranges": [
        "27/07/2031 - 02/08/2031",
        "03/08/2031 - 09/08/2031",
        "10/08/2031 - 16/08/2031",
        "17/08/2031 - 23/08/2031",
        "24/08/2031 - 30/08/2031",
        "31/08/2031 - 06/09/2031",
      ],
    },
    {
      "month": 8, //September
      "no_of_weeks": 5,
      "ranges": [
        "31/08/2031 - 06/09/2031",
        "07/09/2031 - 13/09/2031",
        "14/09/2031 - 20/09/2031",
        "21/09/2031 - 27/09/2031",
        "28/09/2031 - 04/10/2031",
      ],
    },
    {
      "month": 9, //October
      "no_of_weeks": 5,
      "ranges": [
        "28/09/2031 - 04/10/2031",
        "05/10/2031 - 11/10/2031",
        "12/10/2031 - 18/10/2031",
        "19/10/2031 - 25/10/2031",
        "26/10/2031 - 01/11/2031",
      ],
    },
    {
      "month": 10, //November
      "no_of_weeks": 6,
      "ranges": [
        "26/10/2031 - 01/11/2031",
        "02/11/2031 - 08/11/2031",
        "09/11/2031 - 15/11/2031",
        "16/11/2031 - 22/11/2031",
        "23/11/2031 - 29/11/2031",
        "30/11/2031 - 06/12/2031",
      ],
    },
    {
      "month": 11, //December
      "no_of_weeks": 5,
      "ranges": [
        "30/11/2031 - 06/12/2031",
        "07/12/2031 - 13/12/2031",
        "14/12/2031 - 20/12/2031",
        "21/12/2031 - 27/12/2031",
        "28/12/2031 - 03/01/2032",
      ],
    },
  ],
};

const year_2032 = {
  "months": [
    {
      "month": 0, //January
      "no_of_weeks": 5,
      "ranges": [
        "28/12/2031 - 03/01/2032",
        "04/01/2032- 10/01/2032",
        "11/01/2032 - 17/01/2032",
        "18/01/2032 - 24/01/2032",
        "25/01/2032 - 31/01/2032"
      ],
    },
    {
      "month": 1, //February
      "no_of_weeks": 5,
      "ranges": [
        "01/02/2032 - 07/02/2032",
        "08/02/2032 - 14/02/2032",
        "15/02/2032 - 21/02/2032",
        "22/02/2032 - 28/02/2032",
        "29/02/2032 - 06/03/2032"
      ],
    },
    {
      "month": 2, //March
      "no_of_weeks": 5,
      "ranges": [
        "29/02/2032 - 06/03/2032",
        "07/03/2032 - 13/03/2032",
        "14/03/2032 - 20/03/2032",
        "21/03/2032 - 27/03/2032",
        "28/03/2032 - 03/04/2032",
      ],
    },
    {
      "month": 3, //April
      "no_of_weeks": 5,
      "ranges": [
        "28/03/2032 - 03/04/2032",
        "04/04/2032 - 10/04/2032",
        "11/04/2032 - 17/04/2032",
        "18/04/2032 - 24/04/2032",
        "25/04/2032 - 01/05/2032"
      ],
    },
    {
      "month": 4, //May
      "no_of_weeks": 6,
      "ranges": [
        "25/04/2032 - 01/05/2032",
        "02/05/2032 - 08/05/2032",
        "09/05/2032 - 15/05/2032",
        "16/05/2032 - 22/05/2032",
        "23/05/2032 - 29/05/2032",
        "30/05/2032 - 05/06/2032"
      ],
    },
    {
      "month": 5, //June
      "no_of_weeks": 5,
      "ranges": [
        "30/05/2032 - 05/06/2032",
        "06/06/2032 - 12/06/2032",
        "13/06/2032 - 19/06/2032",
        "20/06/2032 - 26/06/2032",
        "27/06/2032 - 03/07/2032",
      ],
    },
    {
      "month": 6, //July
      "no_of_weeks": 5,
      "ranges": [
        "27/06/2032 - 03/07/2032",
        "04/07/2032 - 10/07/2032",
        "11/07/2032 - 17/07/2032",
        "18/07/2032 - 24/07/2032",
        "25/07/2032 - 31/07/2032",
      ],
    },
    {
      "month": 7, //August
      "no_of_weeks": 5,
      "ranges": [
        "01/08/2032 - 07/08/2032",
        "08/08/2032 - 14/08/2032",
        "15/08/2032 - 21/08/2032",
        "22/08/2032 - 28/08/2032",
        "29/08/2032 - 04/09/2032"
      ],
    },
    {
      "month": 8, //September
      "no_of_weeks": 5,
      "ranges": [
        "29/08/2032 - 04/09/2032",
        "05/09/2032 - 11/09/2032",
        "12/09/2032 - 18/09/2032",
        "19/09/2032 - 25/09/2032",
        "26/09/2032 - 02/10/2032",
      ],
    },
    {
      "month": 9, //October
      "no_of_weeks": 6,
      "ranges": [
        "26/09/2032 - 02/10/2032",
        "03/10/2032 - 09/10/2032",
        "10/10/2032 - 16/10/2032",
        "17/10/2032 - 23/10/2032",
        "24/10/2032 - 30/10/2032",
        "31/10/2032 - 06/11/2032"
      ],
    },
    {
      "month": 10, //November
      "no_of_weeks": 5,
      "ranges": [
        "31/10/2032 - 06/11/2032",
        "07/11/2032 - 13/11/2032",
        "14/11/2032 - 20/11/2032",
        "21/11/2032 - 27/11/2032",
        "28/11/2032 - 04/12/2032",
      ],
    },
    {
      "month": 11, //December
      "no_of_weeks": 5,
      "ranges": [
        "28/11/2032 - 04/12/2032",
        "05/12/2032 - 11/12/2032",
        "12/12/2032 - 18/12/2032",
        "19/12/2032 - 25/12/2032",
        "26/12/2032 - 01/01/2033"
      ],
    },
  ]
};

const year_2033 = {
  "months": [
    {
      "month": 0, //January
      "no_of_weeks": 6,
      "ranges": [
        "26/12/2032 - 01/01/2033",
        "02/01/2033 - 08/01/2033",
        "09/01/2033 - 15/01/2033",
        "16/01/2033 - 22/01/2033",
        "23/01/2033 - 29/01/2033",
        "30/01/2033 - 05/02/2033",
      ],
    },
    {
      "month": 1, //February
      "no_of_weeks": 5,
      "ranges": [
        "30/01/2033 - 05/02/2033",
        "06/02/2033 - 12/02/2033",
        "13/02/2033 - 19/02/2033",
        "20/02/2033 - 26/02/2033",
        "27/02/2033 - 05/03/2033",
      ],
    },
    {
      "month": 2, //March
      "no_of_weeks": 5,
      "ranges": [
        "27/02/2033 - 05/03/2033",
        "06/03/2033 - 12/03/2033",
        "13/03/2033 - 19/03/2033",
        "20/03/2033 - 26/03/2033",
        "27/03/2033 - 02/04/2033",
      ],
    },
    {
      "month": 3, //April
      "no_of_weeks": 5,
      "ranges": [
        "27/03/2033 - 02/04/2033",
        "03/04/2033 - 09/04/2033",
        "10/04/2033 - 16/04/2033",
        "17/04/2033 - 23/04/2033",
        "24/04/2033 - 30/04/2033",
      ],
    },
    {
      "month": 4, //May
      "no_of_weeks": 5,
      "ranges": [
        "01/05/2033 - 07/05/2033",
        "08/05/2033 - 14/05/2033",
        "15/05/2033 - 21/05/2033",
        "22/05/2033 - 28/05/2033",
        "29/05/2033 - 04/06/2033",
      ],
    },
    {
      "month": 5, //June
      "no_of_weeks": 5,
      "ranges": [
        "29/05/2033 - 04/06/2033",
        "05/06/2033 - 11/06/2033",
        "12/06/2033 - 18/06/2033",
        "19/06/2033 - 25/06/2033",
        "26/06/2033 - 02/07/2033",
      ],
    },
    {
      "month": 6, //July
      "no_of_weeks": 6,
      "ranges": [
        "26/06/2033 - 02/07/2033",
        "03/07/2033 - 09/07/2033",
        "10/07/2033 - 16/07/2033",
        "17/07/2033 - 23/07/2033",
        "24/07/2033 - 30/07/2033",
        "31/07/2033 - 06/08/2033",
      ],
    },
    {
      "month": 7, //August
      "no_of_weeks": 5,
      "ranges": [
        "31/07/2033 - 06/08/2033",
        "07/08/2033 - 13/08/2033",
        "14/08/2033 - 20/08/2033",
        "21/08/2033 - 27/08/2033",
        "28/08/2033 - 03/09/2033",
      ],
    },
    {
      "month": 8, //September
      "no_of_weeks": 5,
      "ranges": [
        "28/08/2033 - 03/09/2033",
        "04/09/2033 - 10/09/2033",
        "11/09/2033 - 17/09/2033",
        "18/09/2033 - 24/09/2033",
        "25/09/2033 - 01/10/2033",
      ],
    },
    {
      "month": 9, //October
      "no_of_weeks": 6,
      "ranges": [
        "25/09/2033 - 01/10/2033",
        "02/10/2033 - 08/10/2033",
        "09/10/2033 - 15/10/2033",
        "16/10/2033 - 22/10/2033",
        "23/10/2033 - 29/10/2033",
        "30/10/2033 - 05/11/2033",
      ],
    },
    {
      "month": 10, //November
      "no_of_weeks": 5,
      "ranges": [
        "30/10/2033 - 05/11/2033",
        "06/11/2033 - 12/11/2033",
        "13/11/2033 - 19/11/2033",
        "20/11/2033 - 26/11/2033",
        "27/11/2033 - 03/12/2033",
      ],
    },
    {
      "month": 11, //December
      "no_of_weeks": 5,
      "ranges": [
        "27/11/2033 - 03/12/2033",
        "04/12/2033 - 10/12/2033",
        "11/12/2033 - 17/12/2033",
        "18/12/2033 - 24/12/2033",
        "25/12/2033 - 31/12/2033",
      ],
    },
  ],
};

const year_2034 = {
  "months": [
    {
      "month": 0, //January
      "no_of_weeks": 5,
      "ranges": [
        "01/01/2034 - 07/01/2034",
        "08/01/2034 - 14/01/2034",
        "15/01/2034 - 21/01/2034",
        "22/01/2034 - 28/01/2034",
        "29/01/2034 - 04/02/2034",
      ]
    },
    {
      "month": 1, // February
      "no_of_weeks": 5,
      "ranges": [
        "29/01/2034 - 04/02/2034",
        "05/02/2034 - 11/02/2034",
        "12/02/2034 - 18/02/2034",
        "19/02/2034 - 25/02/2034",
        "26/02/2034 - 04/03/2034",
      ]
    },
    {
      "month": 2, // March
      "no_of_weeks": 5,
      "ranges": [
        "26/02/2034 - 04/03/2034",
        "05/03/2034 - 11/03/2034",
        "12/03/2034 - 18/03/2034",
        "19/03/2034 - 25/03/2034",
        "26/03/2034 - 01/04/2034",
      ]
    },
    {
      "month": 3, // April
      "no_of_weeks": 6,
      "ranges": [
        "26/03/2034 - 01/04/2034",
        "02/04/2034 - 08/04/2034",
        "09/04/2034 - 15/04/2034",
        "16/04/2034 - 22/04/2034",
        "23/04/2034 - 29/04/2034",
        "30/04/2034 - 06/05/2034",
      ]
    },
    {
      "month": 4, // May
      "no_of_weeks": 5,
      "ranges": [
        "30/04/2034 - 06/05/2034",
        "07/05/2034 - 13/05/2034",
        "14/05/2034 - 20/05/2034",
        "21/05/2034 - 27/05/2034",
        "28/05/2034 - 03/06/2034",
      ]
    },
    {
      "month": 5, // June
      "no_of_weeks": 5,
      "ranges": [
        "28/05/2034 - 03/06/2034",
        "04/06/2034 - 10/06/2034",
        "11/06/2034 - 17/06/2034",
        "18/06/2034 - 24/06/2034",
        "25/06/2034 - 01/07/2034",
      ]
    },
    {
      "month": 6, // July
      "no_of_weeks": 6,
      "ranges": [
        "25/06/2034 - 01/07/2034",
        "02/07/2034 - 08/07/2034",
        "09/07/2034 - 15/07/2034",
        "16/07/2034 - 22/07/2034",
        "23/07/2034 - 29/07/2034",
        "30/07/2034 - 05/08/2034",
      ]
    },
    {
      "month": 7, // August
      "no_of_weeks": 5,
      "ranges": [
        "30/07/2034 - 05/08/2034",
        "06/08/2034 - 12/08/2034",
        "13/08/2034 - 19/08/2034",
        "20/08/2034 - 26/08/2034",
        "27/08/2034 - 02/09/2034",
      ]
    },
    {
      "month": 8, // September
      "no_of_weeks": 5,
      "ranges": [
        "27/08/2034 - 02/09/2034",
        "03/09/2034 - 09/09/2034",
        "10/09/2034 - 16/09/2034",
        "17/09/2034 - 23/09/2034",
        "24/09/2034 - 30/09/2034",
      ]
    },
    {
      "month": 9, // October
      "no_of_weeks": 5,
      "ranges": [
        "01/10/2034 - 07/10/2034",
        "08/10/2034 - 14/10/2034",
        "15/10/2034 - 21/10/2034",
        "22/10/2034 - 28/10/2034",
        "29/10/2034 - 04/11/2034",
      ]
    },
    {
      "month": 10, // November
      "no_of_weeks": 5,
      "ranges": [
        "29/10/2034 - 04/11/2034",
        "05/11/2034 - 11/11/2034",
        "12/11/2034 - 18/11/2034",
        "19/11/2034 - 25/11/2034",
        "26/11/2034 - 02/12/2034",
      ]
    },
    {
      "month": 11, // December
      "no_of_weeks": 6,
      "ranges": [
        "26/11/2034 - 02/12/2034",
        "03/12/2034 - 09/12/2034",
        "10/12/2034 - 16/12/2034",
        "17/12/2034 - 23/12/2034",
        "24/12/2034 - 30/12/2034",
        "31/12/2034 - 06/01/2035",
      ]
    },
  ]
};

const year_2035 = {
  "months": [
    {
      "month": 0, //January
      "no_of_weeks": 5,
      "ranges": [
        "31/12/2034 - 06/01/2035",
        "07/01/2035 - 13/01/2035",
        "14/01/2035 - 20/01/2035",
        "21/01/2035 - 27/01/2035",
        "28/01/2035 - 03/02/2035",
      ],
    },
    {
      "month": 1,
      "no_of_weeks": 5, //February
      "ranges": [
        "28/01/2035 - 03/02/2035",
        "04/02/2035 - 10/02/2035",
        "11/02/2035 - 17/02/2035",
        "18/02/2035 - 24/02/2035",
        "25/02/2035 - 03/03/2035",
      ],
    },
    {
      "month": 2, //March
      "no_of_weeks": 5,
      "ranges": [
        "25/02/2035 - 03/03/2035",
        "04/03/2035 - 10/03/2035",
        "11/03/2035 - 17/03/2035",
        "18/03/2035 - 24/03/2035",
        "25/03/2035 - 31/03/2035",
      ],
    },
    {
      "month": 3, //April
      "no_of_weeks": 5,
      "ranges": [
        "01/04/2035 - 07/04/2035",
        "08/04/2035 - 14/04/2035",
        "15/04/2035 - 21/04/2035",
        "22/04/2035 - 28/04/2035",
        "29/04/2035 - 05/05/2035",
      ],
    },
    {
      "month": 4, //May
      "no_of_weeks": 5,
      "ranges": [
        "29/04/2035 - 05/05/2035",
        "06/05/2035 - 12/05/2035",
        "13/05/2035 - 19/05/2035",
        "20/05/2035 - 26/05/2035",
        "27/05/2035 - 02/06/2035",
      ],
    },
    {
      "month": 5, //June
      "no_of_weeks": 5,
      "ranges": [
        "27/05/2035 - 02/06/2035",
        "03/06/2035 - 09/06/2035",
        "10/06/2035 - 16/06/2035",
        "17/06/2035 - 23/06/2035",
        "24/06/2035 - 30/06/2035",
      ],
    },
    {
      "month": 6, //July
      "no_of_weeks": 5,
      "ranges": [
        "01/07/2035 - 07/07/2035",
        "08/07/2035 - 14/07/2035",
        "15/07/2035 - 21/07/2035",
        "22/07/2035 - 28/07/2035",
        "29/07/2035 - 04/08/2035",
      ],
    },
    {
      "month": 7, //August
      "no_of_weeks": 5,
      "ranges": [
        "29/07/2035 - 04/08/2035",
        "05/08/2035 - 11/08/2035",
        "12/08/2035 - 18/08/2035",
        "19/08/2035 - 25/08/2035",
        "26/08/2035 - 01/09/2035",
      ],
    },
    {
      "month": 8, //September
      "no_of_weeks": 6,
      "ranges": [
        "26/08/2035 - 01/09/2035",
        "02/09/2035 - 08/09/2035",
        "09/09/2035 - 15/09/2035",
        "16/09/2035 - 22/09/2035",
        "23/09/2035 - 29/09/2035",
        "30/09/2035 - 06/10/2035",
      ],
    },
    {
      "month": 9, //October
      "no_of_weeks": 5,
      "ranges": [
        "30/10/2035 - 06/10/2035",
        "07/10/2035 - 13/10/2035",
        "14/10/2035 - 20/10/2035",
        "21/10/2035 - 27/10/2035",
        "28/10/2035 - 03/11/2035",
      ],
    },
    {
      "month": 10, //November
      "no_of_weeks": 5,
      "ranges": [
        "28/10/2035 - 03/11/2035",
        "04/11/2035 - 10/11/2035",
        "11/11/2035 - 17/11/2035",
        "18/11/2035 - 24/11/2035",
        "25/11/2035 - 01/12/2035",
      ],
    },
    {
      "month": 11, //December
      "no_of_weeks": 6,
      "ranges": [
        "25/11/2035 - 01/12/2035",
        "02/12/2035 - 08/12/2035",
        "09/12/2035 - 15/12/2035",
        "16/12/2035 - 22/12/2035",
        "23/12/2035 - 29/12/2035",
        "30/12/2035 - 05/01/2036",
      ],
    },
  ],
};

const year_2036 = {
  "months": [
    {
      "month": 0,
      "no_of_weeks": 5,
      "ranges": [
        "30/12/2035 - 05/01/2036",
        "06/01/2036 - 12/01/2036",
        "13/01/2036 - 19/01/2036",
        "20/01/2036 - 26/01/2036",
        "27/01/2036 - 03/02/2036",
      ],
    },
    {
      "month": 1,
      "no_of_weeks": 5,
      "ranges": [
        "27/01/2036 - 02/02/2036",
        "03/02/2036 - 09/02/2036",
        "10/02/2036 - 16/02/2036",
        "17/02/2036 - 23/02/2036",
        "24/02/2036 - 01/03/2036",
      ],
    },
    {
      "month": 2,
      "no_of_weeks": 6,
      "ranges": [
        "24/02/2036 - 01/03/2036",
        "02/03/2036 - 08/03/2036",
        "09/03/2036 - 15/03/2036",
        "16/03/2036 - 22/03/2036",
        "23/03/2036 - 29/03/2036",
        "30/03/2036 - 05/04/2036",
      ],
    },
    {
      "month": 3,
      "no_of_weeks": 5,
      "ranges": [
        "30/03/2036 - 05/04/2036",
        "06/04/2036 - 12/04/2036",
        "13/04/2036 - 19/04/2036",
        "20/04/2036 - 26/04/2036",
        "27/04/2036 - 03/05/2036",
      ],
    },
    {
      "month": 4,
      "no_of_weeks": 6,
      "ranges": [
        "27/04/2036 - 03/05/2036",
        "04/05/2036 - 10/05/2036",
        "11/05/2036 - 17/05/2036",
        "18/05/2036 - 24/05/2036",
        "25/05/2036 - 31/06/2036",
      ],
    },
    {
      "month": 5,
      "no_of_weeks": 5,
      "ranges": [
        "01/06/2036 - 07/06/2036",
        "08/06/2036 - 14/06/2036",
        "15/06/2036 - 21/06/2036",
        "22/06/2036 - 28/06/2036",
        "29/06/2036 - 05/07/2036",
      ],
    },
    {
      "month": 6,
      "no_of_weeks": 5,
      "ranges": [
        "29/06/2036 - 05/07/2036",
        "06/07/2036 - 12/07/2036",
        "13/07/2036 - 19/07/2036",
        "20/07/2036 - 26/07/2036",
        "27/07/2036 - 02/08/2036",
      ],
    },
    {
      "month": 7,
      "no_of_weeks": 6,
      "ranges": [
        "27/07/2036 - 02/08/2036",
        "03/08/2036 - 09/08/2036",
        "10/08/2036 - 16/08/2036",
        "17/08/2036 - 23/08/2036",
        "24/08/2036 - 30/08/2036",
        "31/08/2036 - 06/09/2036",
      ],
    },
    {
      "month": 8,
      "no_of_weeks": 5,
      "ranges": [
        "31/08/2036 - 06/09/2036",
        "07/09/2036 - 13/09/2036",
        "14/09/2036 - 20/09/2036",
        "21/09/2036 - 27/09/2036",
        "28/09/2036 - 04/10/2036",
      ],
    },
    {
      "month": 9,
      "no_of_weeks": 5,
      "ranges": [
        "28/09/2036 - 04/10/2036",
        "05/10/2036 - 11/10/2036",
        "11/10/2036 - 18/10/2036",
        "19/10/2036 - 25/10/2036",
        "26/10/2036 - 01/11/2036",
      ],
    },
    {
      "month": 10,
      "no_of_weeks": 6,
      "ranges": [
        "26/10/2036 - 01/11/2036",
        "02/11/2036 - 08/11/2036",
        "09/11/2036 - 15/11/2036",
        "16/11/2036 - 22/11/2036",
        "23/11/2036 - 29/11/2036",
        "30/11/2036 - 06/12/2036",
      ],
    },
    {
      "month": 11,
      "no_of_weeks": 5,
      "ranges": [
        "30/11/2036 - 06/12/2036",
        "07/12/2036 - 13/12/2036",
        "14/12/2036 - 20/12/2036",
        "21/12/2036 - 27/12/2036",
        "28/12/2036 - 03/01/2037",
      ],
    },
  ],
};

const year_2037 = {
  "months": [
    {
      "month": 0, //January
      "no_of_weeks": 5,
      "ranges": [
        "28/01/2036 - 03/01/2037",
        "04/01/2037 - 10/01/2037",
        "11/01/2037 - 17/01/2037",
        "18/01/2037 - 24/01/2037",
        "25/01/2037 - 31/01/2037",
      ]
    },
    {
      "month": 1, // February
      "no_of_weeks": 4,
      "ranges": [
        "01/02/2037 - 07/02/2037",
        "08/02/2037 - 14/02/2037",
        "15/02/2037 - 21/02/2037",
        "22/02/2037 - 28/02/2037",
      ]
    },
    {
      "month": 2, // March
      "no_of_weeks": 5,
      "ranges": [
        "01/03/2037 - 07/03/2037",
        "08/03/2037 - 14/03/2037",
        "15/03/2037 - 21/03/2037",
        "22/03/2037 - 28/03/2037",
        "29/03/2037 - 04/04/2037",
      ]
    },
    {
      "month": 3, // April
      "no_of_weeks": 5,
      "ranges": [
        "29/03/2037 - 04/04/2037",
        "05/04/2037 - 11/04/2037",
        "12/04/2037 - 18/04/2037",
        "19/04/2037 - 25/04/2037",
        "26/04/2037 - 02/05/2037",
      ]
    },
    {
      "month": 4, // May
      "no_of_weeks": 6,
      "ranges": [
        "26/04/2037 - 02/05/2037",
        "03/05/2037 - 09/05/2037",
        "10/05/2037 - 16/05/2037",
        "17/05/2037 - 23/05/2037",
        "24/05/2037 - 30/05/2037",
        "31/05/2037 - 06/06/2037",
      ]
    },
    {
      "month": 5, // June
      "no_of_weeks": 5,
      "ranges": [
        "31/05/2037 - 06/06/2037",
        "07/06/2037 - 13/06/2037",
        "14/06/2037 - 20/06/2037",
        "21/06/2037 - 27/06/2037",
        "28/06/2037 - 04/07/2037",
      ]
    },
    {
      "month": 6, // July
      "no_of_weeks": 5,
      "ranges": [
        "28/06/2037 - 04/07/2037",
        "05/07/2037 - 11/07/2037",
        "12/07/2037 - 18/07/2037",
        "19/07/2037 - 25/07/2037",
        "26/07/2037 - 01/08/2037",
      ]
    },
    {
      "month": 7, // August
      "no_of_weeks": 6,
      "ranges": [
        "26/07/2037 - 01/08/2037",
        "02/08/2037 - 08/08/2037",
        "09/08/2037 - 15/08/2037",
        "16/08/2037 - 22/08/2037",
        "23/08/2037 - 29/08/2037",
        "30/08/2037 - 05/09/2037",
      ]
    },
    {
      "month": 8, // September
      "no_of_weeks": 5,
      "ranges": [
        "30/08/2037 - 05/09/2037",
        "06/09/2037 - 12/09/2037",
        "13/09/2037 - 19/09/2037",
        "20/09/2037 - 26/09/2037",
        "27/09/2037 - 03/10/2037",
      ]
    },
    {
      "month": 9, // October
      "no_of_weeks": 5,
      "ranges": [
        "27/09/2037 - 03/10/2037",
        "04/10/2037 - 10/10/2037",
        "11/10/2037 - 17/10/2037",
        "18/10/2037 - 24/10/2037",
        "25/10/2037 - 31/10/2037",
      ]
    },
    {
      "month": 10, // November
      "no_of_weeks": 5,
      "ranges": [
        "01/11/2037 - 07/11/2037",
        "08/11/2037 - 14/11/2037",
        "15/11/2037 - 21/11/2037",
        "22/11/2037 - 28/11/2037",
        "29/11/2037 - 05/12/2037",
      ]
    },
    {
      "month": 11, // December
      "no_of_weeks": 5,
      "ranges": [
        "29/11/2037 - 05/12/2037",
        "06/12/2037 - 12/12/2037",
        "13/12/2037 - 19/12/2037",
        "20/12/2037 - 26/12/2037",
        "27/12/2037 - 02/12/2038",
      ]
    },
  ]
};

const year_2038 = {
  "months": [
    {
      "month": 0, //January
      "no_of_weeks": 5,
      "ranges": [
        "27/12/2037 - 02/01/2038",
        "03/01/2038 - 09/01/2038",
        "10/01/2038 - 16/01/2038",
        "17/01/2038 - 23/01/2038",
        "24/01/2038 - 30/01/2038",
        "31/01/2038 - 06/02/2038",
      ],
    },
    {
      "month": 1, //February
      "no_of_weeks": 5,
      "ranges": [
        "31/01/2038 - 06/02/2038",
        "07/02/2038 - 13/02/2038",
        "14/02/2038 - 20/02/2038",
        "21/02/2038 - 27/02/2038",
        "28/02/2038 - 06/03/2038",
      ],
    },
    {
      "month": 2, //March
      "no_of_weeks": 5,
      "ranges": [
        "28/02/2038 - 06/03/2038",
        "07/03/2038 - 13/03/2038",
        "14/03/2038 - 20/03/2038",
        "21/03/2038 - 27/03/2038",
        "28/03/2038 - 03/04/2038",
      ],
    },
    {
      "month": 3, //April
      "no_of_weeks": 5,
      "ranges": [
        "28/03/2038 - 03/04/2038",
        "04/04/2038 - 10/04/2038",
        "11/04/2038 - 17/04/2038",
        "18/04/2038 - 24/04/2038",
        "25/04/2038 - 01/05/2038",
      ],
    },
    {
      "month": 4, //May
      "no_of_weeks": 5,
      "ranges": [
        "25/04/2038 - 01/05/2038",
        "02/05/2038 - 08/05/2038",
        "09/05/2038 - 15/05/2038",
        "16/05/2038 - 22/05/2038",
        "23/05/2038 - 29/05/2038",
        "30/05/2038 - 05/06/2038",
      ],
    },
    {
      "month": 5, //June
      "no_of_weeks": 5,
      "ranges": [
        "30/05/2038 - 05/06/2038",
        "06/06/2038 - 12/06/2038",
        "13/06/2038 - 19/06/2038",
        "20/06/2038 - 26/06/2038",
        "27/06/2038 - 03/07/2038",
      ],
    },
    {
      "month": 6, //July
      "no_of_weeks": 5,
      "ranges": [
        "27/06/2038 - 03/07/2038",
        "04/07/2038 - 10/07/2038",
        "11/07/2038 - 17/07/2038",
        "18/07/2038 - 24/07/2038",
        "25/07/2038 - 31/07/2038",
      ],
    },
    {
      "month": 7, //August
      "no_of_weeks": 5,
      "ranges": [
        "01/08/2038 - 07/08/2038",
        "08/08/2038 - 14/08/2038",
        "15/08/2038 - 21/08/2038",
        "22/08/2038 - 28/08/2038",
        "29/08/2038 - 04/09/2038",
      ],
    },
    {
      "month": 8, //September
      "no_of_weeks": 5,
      "ranges": [
        "29/08/2038 - 04/09/2038",
        "05/09/2038 - 11/09/2038",
        "12/09/2038 - 18/09/2038",
        "19/09/2038 - 25/09/2038",
        "26/09/2038 - 02/10/2038",
      ],
    },
    {
      "month": 9, //October
      "no_of_weeks": 6,
      "ranges": [
        "26/09/2038 - 02/10/2038",
        "03/10/2038 - 09/10/2038",
        "10/10/2038 - 16/10/2038",
        "17/10/2038 - 23/10/2038",
        "24/10/2038 - 30/10/2038",
        "31/10/2038 - 06/11/2038",
      ],
    },
    {
      "month": 10, //November
      "no_of_weeks": 5,
      "ranges": [
        "31/10/2038 - 06/11/2038",
        "07/11/2038 - 13/11/2038",
        "14/11/2038 - 20/11/2038",
        "21/11/2038 - 27/11/2038",
        "28/11/2038 - 04/12/2038",
      ],
    },
    {
      "month": 11, //December
      "no_of_weeks": 5,
      "ranges": [
        "28/11/2038 - 04/12/2038",
        "05/12/2038 - 11/12/2038",
        "12/12/2038 - 18/12/2038",
        "19/12/2038 - 25/12/2038",
        "26/12/2038 - 01/01/2039",
      ],
    },
  ],
};

const year_2039 = {
  "months": [
    {
      "month": 0,
      "no_of_weeks": 6,
      "ranges": [
        "26/12/2038 - 01/01/2039",
        "02/01/2039 - 08/01/2039",
        "09/01/2039 - 15/01/2039",
        "16/01/2039 - 22/01/2039",
        "23/01/2039 - 29/02/2039",
        "30/01/2039 - 05/02/2039",
      ],
    },
    {
      "month": 1,
      "no_of_weeks": 5,
      "ranges": [
        "30/01/2039 - 05/02/2039",
        "06/02/2039 - 12/02/2039",
        "13/02/2039 - 19/02/2039",
        "20/02/2039 - 26/02/2039",
        "27/02/2039 - 05/03/2039",
      ],
    },
    {
      "month": 2,
      "no_of_weeks": 5,
      "ranges": [
        "27/02/2039 - 05/03/2039",
        "06/03/2039 - 12/03/2039",
        "13/03/2039 - 19/03/2039",
        "20/03/2039 - 26/03/2039",
        "27/03/2039 - 02/04/2039",
      ],
    },
    {
      "month": 3,
      "no_of_weeks": 5,
      "ranges": [
        "27/03/2039 - 02/04/2039",
        "03/04/2039 - 09/04/2039",
        "10/04/2039 - 16/04/2039",
        "17/04/2039 - 23/04/2039",
        "24/04/2039 - 30/04/2039",
      ],
    },
    {
      "month": 4,
      "no_of_weeks": 5,
      "ranges": [
        "01/05/2039 - 07/05/2039",
        "08/05/2039 - 14/05/2039",
        "15/05/2039 - 21/05/2039",
        "22/05/2039 - 28/05/2039",
        "29/05/2039 - 04/06/2039",
      ],
    },
    {
      "month": 5,
      "no_of_weeks": 5,
      "ranges": [
        "29/05/2039 - 04/06/2039",
        "05/06/2039 - 11/06/2039",
        "12/06/2039 - 18/06/2039",
        "19/06/2039 - 25/06/2039",
        "26/06/2039 - 02/07/2039",
      ],
    },
    {
      "month": 6,
      "no_of_weeks": 6,
      "ranges": [
        "26/06/2039 - 02/07/2039",
        "03/07/2039 - 09/07/2039",
        "10/07/2039 - 16/07/2039",
        "17/07/2039 - 23/07/2039",
        "24/07/2039 - 30/07/2039",
        "31/07/2039 - 06/08/2039",
      ],
    },
    {
      "month": 7,
      "no_of_weeks": 5,
      "ranges": [
        "31/07/2039 - 06/08/2039",
        "07/08/2039 - 13/08/2039",
        "14/08/2039 - 20/08/2039",
        "21/08/2039 - 27/08/2039",
        "28/08/2039 - 03/09/2039",
      ],
    },
    {
      "month": 8,
      "no_of_weeks": 5,
      "ranges": [
        "28/08/2039 - 03/09/2039",
        "04/09/2039 - 10/09/2039",
        "11/09/2039 - 17/09/2039",
        "18/09/2039 - 24/09/2039",
        "25/09/2039 - 01/10/2039",
      ],
    },
    {
      "month": 9,
      "no_of_weeks": 6,
      "ranges": [
        "25/09/2039 - 01/10/2039",
        "02/10/2039 - 08/10/2039",
        "09/10/2039 - 15/10/2039",
        "16/10/2039 - 22/10/2039",
        "23/10/2039 - 29/10/2039",
        "30/10/2039 - 05/11/2039",
      ],
    },
    {
      "month": 10,
      "no_of_weeks": 5,
      "ranges": [
        "30/10/2039 - 05/11/2039",
        "06/11/2039 - 12/11/2039",
        "13/11/2039 - 19/11/2039",
        "20/11/2039 - 26/11/2039",
        "27/11/2039 - 03/12/2039",
      ],
    },
    {
      "month": 11,
      "no_of_weeks": 5,
      "ranges": [
        "27/11/2039 - 03/12/2039",
        "04/12/2039 - 10/12/2039",
        "11/12/2039 - 17/12/2039",
        "18/12/2039 - 24/12/2039",
        "25/12/2039 - 31/12/2039",
      ],
    },
  ],
};

const year_2040 = {
  "months": [
    {
      "month": 0, // january
      "no_of_weeks": 5,
      "ranges": [
        "01/01/2040 - 07/01/2040",
        "08/01/2040 - 14/01/2040",
        "15/01/2040 - 21/01/2040",
        "22/01/2040 - 28/01/2040",
        "29/01/2040 - 04/02/2040",
      ]
    },
    {
      "month": 1, //february
      "no_of_weeks": 5,
      "ranges": [
        "29/01/2040 - 04/02/2040",
        "05/02/2040 - 11/02/2040",
        "12/02/2040 - 18/02/2040",
        "19/02/2040 - 25/02/2040",
        "26/02/2040 - 03/03/2040"
      ]
    },
    {
      "month": 2, //march
      "no_of_weeks": 5,
      "ranges": [
        "26/02/2040 - 03/03/2040",
        "04/03/2040 - 10/03/2040",
        "11/03/2040 - 17/03/2040",
        "18/03/2040 - 24/03/2040",
        "25/03/2040 - 31/03/2040",
      ]
    },
    {
      "month": 3, //april
      "no_of_weeks": 5,
      "ranges": [
        "01/04/2040 - 07/04/2040",
        "08/04/2040 - 14/04/2040",
        "15/04/2040 - 21/04/2040",
        "22/04/2040 - 28/04/2040",
        "29/04/2040 - 05/05/2040",
      ]
    },
    {
      "month": 4, //may
      "no_of_weeks": 5,
      "ranges": [
        "29/04/2040 - 05/05/2040",
        "06/05/2040 - 12/05/2040",
        "13/05/2040 - 19/05/2040",
        "20/05/2040 - 26/05/2040",
        "27/05/2040 - 02/06/2040",
      ]
    },
    {
      "month": 5, //june
      "no_of_weeks": 5,
      "ranges": [
        "27/05/2040 - 02/06/2040",
        "03/05/2040 - 09/06/2040",
        "10/06/2040 - 16/06/2040",
        "17/06/2040 - 23/06/2040",
        "24/06/2040 - 30/06/2040",
      ]
    },
    {
      "month": 6, //july
      "no_of_weeks": 5,
      "ranges": [
        "01/07/2040 - 07/07/2040",
        "08/07/2040 - 14/07/2040",
        "15/07/2040 - 21/07/2040",
        "22/07/2040 - 28/07/2040",
        "29/07/2040 - 04/08/2040"
      ]
    },
    {
      "month": 7, //august
      "no_of_weeks": 5,
      "ranges": [
        "29/07/2040 - 04/08/2040",
        "05/08/2040 - 11/08/2040",
        "12/08/2040 - 18/08/2040",
        "19/08/2040 - 25/08/2040",
        "26/08/2040 - 01/09/2040",
      ]
    },
    {
      "month": 8, //september
      "no_of_weeks": 6,
      "ranges": [
        "26/08/2040 - 01/09/2040",
        "02/09/2040 - 08/09/2040",
        "09/09/2040 - 15/09/2040",
        "16/09/2040 - 22/09/2040",
        "23/09/2040 - 29/09/2040",
        "30/09/2040 - 06/10/2040"
      ]
    },
    {
      "month": 9, //october
      "no_of_weeks": 5,
      "ranges": [
        "30/09/2040 - 06/10/2040",
        "07/10/2040 - 13/10/2040",
        "14/10/2040 - 20/10/2040",
        "21/10/2040 - 27/10/2040",
        "28/10/2040 - 03/11/2040",
      ]
    },
    {
      "month": 10, //november
      "no_of_weeks": 5,
      "ranges": [
        "28/10/2040 - 03/11/2040",
        "04/11/2040 - 10/11/2040",
        "11/11/2040 - 17/11/2040",
        "18/11/2040 - 24/11/2040",
        "25/11/2040 - 01/12/2040",
      ]
    },
    {
      "month": 11, //december
      "no_of_weeks": 6,
      "ranges": [
        "25/11/2040 - 01/12/2040",
        "02/12/2040 - 08/12/2040",
        "09/12/2040 - 15/12/2040",
        "16/12/2040 - 22/12/2040",
        "23/12/2040 - 29/01/2040",
        "30/12/2040 - 05/01/2041"
      ]
    },
  ],
};

const year_2041 = {
  "months": [
    {
      "month": 0, // january
      "no_of_weeks": 5,
      "ranges": [
        "30/12/2040 - 05/01/2041",
        "06/01/2041 - 12/01/2041",
        "13/01/2041 - 19/01/2041",
        "20/01/2041 - 26/01/2041",
        "27/01/2041 - 02/02/2041",
      ]
    },
    {
      "month": 1, //february
      "no_of_weeks": 5,
      "ranges": [
        "27/01/2041 - 02/02/2041",
        "03/02/2041 - 09/02/2041",
        "10/02/2041 - 16/02/2041",
        "17/02/2041 - 23/02/2041",
        "24/02/2041 - 02/03/2041"
      ]
    },
    {
      "month": 2, //march
      "no_of_weeks": 6,
      "ranges": [
        "24/02/2041 - 02/03/2041",
        "03/03/2041 - 09/03/2041",
        "10/03/2041 - 16/03/2041",
        "17/03/2041 - 23/03/2041",
        "24/03/2041 - 30/03/2041",
        "31/03/2041 - 06/04/2041"
      ]
    },
    {
      "month": 3, //april
      "no_of_weeks": 5,
      "ranges": [
        "31/03/2041 - 06/04/2041",
        "07/04/2041 - 13/04/2041",
        "14/04/2041 - 20/04/2041",
        "21/04/2041 - 27/04/2041",
        "28/04/2041 - 04/05/2041",
      ]
    },
    {
      "month": 4, //may
      "no_of_weeks": 5,
      "ranges": [
        "28/04/2041 - 04/05/2041",
        "05/05/2041 - 11/05/2041",
        "12/05/2041 - 18/05/2041",
        "19/05/2041 - 25/05/2041",
        "26/05/2041 - 01/06/2041",
      ]
    },
    {
      "month": 5, //june
      "no_of_weeks": 6,
      "ranges": [
        "26/05/2041 - 01/06/2041",
        "02/06/2041 - 08/06/2041",
        "09/06/2041 - 15/06/2041",
        "16/06/2041 - 22/06/2041",
        "23/06/2041 - 29/06/2041",
        "30/06/2041 - 06/07/2041",
      ]
    },
    {
      "month": 6, //july
      "no_of_weeks": 5,
      "ranges": [
        "30/06/2041 - 06/07/2041",
        "07/07/2041 - 13/07/2041",
        "14/07/2041 - 20/07/2041",
        "21/07/2041 - 27/07/2041",
        "28/07/2041 - 03/08/2041"
      ]
    },
    {
      "month": 7, //august
      "no_of_weeks": 5,
      "ranges": [
        "28/07/2041 - 03/08/2041",
        "04/08/2041 - 10/08/2041",
        "11/08/2041 - 17/08/2041",
        "18/08/2041 - 24/08/2041",
        "25/08/2041 - 31/08/2041",
      ]
    },
    {
      "month": 8, //september
      "no_of_weeks": 5,
      "ranges": [
        "01/09/2041 - 07/09/2041",
        "08/09/2041 - 14/09/2041",
        "15/09/2041 - 21/09/2041",
        "22/09/2041 - 28/09/2041",
        "29/09/2041 - 05/10/2041",
      ]
    },
    {
      "month": 9, //october
      "no_of_weeks": 5,
      "ranges": [
        "29/09/2041 - 05/10/2041",
        "06/10/2041 - 12/10/2041",
        "13/10/2041 - 19/10/2041",
        "20/10/2041 - 26/10/2041",
        "27/10/2041 - 02/11/2041",
      ]
    },
    {
      "month": 10, //november
      "no_of_weeks": 5,
      "ranges": [
        "27/10/2041 - 02/11/2041",
        "03/11/2041 - 09/11/2041",
        "10/11/2041 - 16/11/2041",
        "17/11/2041 - 23/11/2041",
        "24/11/2041 - 30/11/2041",
      ]
    },
    {
      "month": 11, //december
      "no_of_weeks": 5,
      "ranges": [
        "01/12/2041 - 07/12/2041",
        "08/12/2041 - 14/12/2041",
        "15/12/2041 - 21/12/2041",
        "22/12/2041 - 28/12/2041",
        "29/12/2041 - 04/01/2042",
      ]
    },
  ],
};

const year_2042 = {
  "months": [
    {
      "month": 0, // january
      "no_of_weeks": 5,
      "ranges": [
        "29/12/2041 - 04/01/2042",
        "05/01/2042 - 11/01/2042",
        "12/01/2042 - 18/01/2042",
        "19/01/2042 - 25/01/2042",
        "26/01/2042 - 01/02/2042",
      ]
    },
    {
      "month": 1, //february
      "no_of_weeks": 5,
      "ranges": [
        "26/01/2042 - 01/02/2042",
        "02/02/2042 - 08/02/2042",
        "09/02/2042 - 15/02/2042",
        "16/02/2042 - 22/02/2042",
        "23/02/2042 - 01/03/2042"
      ]
    },
    {
      "month": 2, //march
      "no_of_weeks": 6,
      "ranges": [
        "23/02/2042 - 01/03/2042",
        "02/03/2042 - 08/03/2042",
        "09/03/2042 - 15/03/2042",
        "16/03/2042 - 22/03/2042",
        "23/03/2042 - 29/03/2042",
        "30/03/2042 - 05/04/2042"
      ]
    },
    {
      "month": 3, //april
      "no_of_weeks": 5,
      "ranges": [
        "30/03/2042 - 05/04/2042",
        "06/04/2042 - 12/04/2042",
        "13/04/2042 - 19/04/2042",
        "20/04/2042 - 26/04/2042",
        "27/04/2042 - 03/05/2042",
      ]
    },
    {
      "month": 4, //may
      "no_of_weeks": 5,
      "ranges": [
        "27/04/2042 - 03/05/2042",
        "04/05/2042 - 10/05/2042",
        "11/05/2042 - 17/05/2042",
        "18/05/2042 - 24/05/2042",
        "25/05/2042 - 31/05/2042",
      ]
    },
    {
      "month": 5, //june
      "no_of_weeks": 5,
      "ranges": [
        "01/06/2042 - 07/06/2042",
        "08/06/2042 - 14/06/2042",
        "15/06/2042 - 21/06/2042",
        "22/06/2042 - 28/06/2042",
        "29/06/2042 - 05/07/2042",
      ]
    },
    {
      "month": 6, //july
      "no_of_weeks": 5,
      "ranges": [
        "29/06/2042 - 05/07/2042",
        "06/07/2042 - 12/07/2042",
        "13/07/2042 - 19/07/2042",
        "20/07/2042 - 26/07/2042",
        "27/07/2042 - 02/08/2042"
      ]
    },
    {
      "month": 7, //august
      "no_of_weeks": 6,
      "ranges": [
        "27/07/2042 - 02/08/2042",
        "03/08/2042 - 09/08/2042",
        "10/08/2042 - 16/08/2042",
        "17/08/2042 - 23/08/2042",
        "24/08/2042 - 30/08/2042",
        "31/08/2042 - 06/09/2042",
      ]
    },
    {
      "month": 8, //september
      "no_of_weeks": 5,
      "ranges": [
        "31/08/2042 - 06/09/2042",
        "07/09/2042 - 13/09/2042",
        "14/09/2042 - 20/09/2042",
        "21/09/2042 - 27/09/2042",
        "28/09/2042 - 04/10/2042",
      ]
    },
    {
      "month": 9, //october
      "no_of_weeks": 5,
      "ranges": [
        "28/09/2042 - 04/10/2042",
        "05/10/2042 - 11/10/2042",
        "12/10/2042 - 18/10/2042",
        "19/10/2042 - 25/10/2042",
        "26/10/2042 - 01/11/2042",
      ]
    },
    {
      "month": 10, //november
      "no_of_weeks": 6,
      "ranges": [
        "26/10/2042 - 01/11/2042",
        "02/11/2042 - 08/11/2042",
        "09/11/2042 - 15/11/2042",
        "16/11/2042 - 22/11/2042",
        "23/11/2042 - 29/11/2042",
        "30/11/2042 - 06/12/2042",
      ]
    },
    {
      "month": 11, //december
      "no_of_weeks": 5,
      "ranges": [
        "30/11/2042 - 06/12/2042",
        "07/12/2042 - 13/12/2042",
        "14/12/2042 - 20/12/2042",
        "21/12/2042 - 27/12/2042",
        "28/12/2042 - 03/01/2043",
      ]
    },
  ],
};
  

export default weekRangeGetter;