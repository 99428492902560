import React, {useContext, useEffect, useState} from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import '../css/sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  MdFeedback, MdOutlineNoteAlt } from 'react-icons/md';
import {  GoSettings } from 'react-icons/go';
import { BiUser } from 'react-icons/bi';
import { VscFeedback } from 'react-icons/vsc';
import {FaRegEnvelope, FaAnchor} from 'react-icons/fa';
import { faTruck,  faUserCog, faCreditCard, faPersonBiking, faSignOut,  faSackDollar, } from '@fortawesome/free-solid-svg-icons';
import { TokenContext  } from '../../component/token_context';
// import audioFile from "../../audio/new_noti.wav";
import red from '../../component/images/red.png';
// import {
//   collection,
//   query,
//   where,
//   onSnapshot,
//   getDoc,
//   doc,
//   getDocs,
//   QuerySnapshot,
//   setDoc,
// } from "firebase/firestore";
// import { db, storage } from "../../utils/firebase";


export default function Sidebar() {
  const values = useContext(TokenContext);
  const admin_role = JSON.parse(values.admin_role);
  //console.log(admin_role);
  const { new_msg, setNewMsg, new_reg_app, setNewRegApp, new_dev_req, SetNewDevReq, new_rep, setNewRep, listen, setListen} = values;
  //console.log(new_msg);
  return (
    <nav>
      <div className='sidebar'>
        <input type="checkbox" name="" id="check" />
        <div className='hamburger-menu-container'>
          <div className='hamburger-menu'>
            <div></div>
          </div>
        </div>
        <div className='sidebarWrapper'>
            <div className='sidebarMenu'> 
              <ul className='sidebarList'>
                <NavLink to='/App/Dashboard' className='sidebarListItem1' style={({ isActive}) => {
                  return {
                    "background-color": isActive? 'rgba(26, 168, 3, 0.15)' : "",
                  }
                }}>
                <img className={new_dev_req ? 'new-dev-req': ''} src={red} alt='red' style={{height: '10px', width: '10px', display: 'none'}} />
                <FaAnchor className='icons-space' />
                  Dashboard
                </NavLink>               
              </ul>
            </div>
            <div className='sidebarMenu'>
              <h3 className='sidebarTitle'>                
                  MEMBERS
              </h3>
              <ul className='sidebarList'>
              <NavLink to='/App/Users' className='sidebarListItem' style={({ isActive}) => {
                  return {
                    "background-color": isActive? 'rgba(26, 168, 3, 0.15)' : "",
                  }
                }}>
              <BiUser className='icons-space2'/>
                  Users
                </NavLink>
                <NavLink to='/App/Delivery_Agents' className='sidebarListItem' style={({ isActive}) => {
                  return {
                    "background-color": isActive? 'rgba(26, 168, 3, 0.15)' : "",
                  }
                }}>
                <FontAwesomeIcon icon={faPersonBiking} className='icons-space'></FontAwesomeIcon> 
                  Delivery Agents
                </NavLink>
                <NavLink to='/App/Fleet_Managers' className='sidebarListItem' style={({ isActive}) => {
                  return {
                    "background-color": isActive? 'rgba(26, 168, 3, 0.15)' : "",
                  }
                }}>
                <FontAwesomeIcon icon={faTruck} className='icons-space'></FontAwesomeIcon>
                  Fleet Managers
                </NavLink>
                <NavLink to='/App/Administrator' className='sidebarListItem' style={({ isActive}) => {
                  return {
                    "background-color": isActive? 'rgba(26, 168, 3, 0.15)' : "",
                  }
                }}>
                <FontAwesomeIcon icon={faUserCog} className='icons-space'></FontAwesomeIcon>
                  Administrators
                </NavLink>
              </ul>
            </div>
            <div className='sidebarMenu'>
              <h3 className='sidebarTitle'>                
                  FINANCIALS
              </h3>
              <ul className='sidebarList'>
              <NavLink to='/App/Payment_record' className='sidebarListItem' style={({ isActive}) => {
                  return {
                    "background-color": isActive? 'rgba(26, 168, 3, 0.15)' : "",
                  }
                }}>
              <FontAwesomeIcon icon={faCreditCard} className='icons-space'></FontAwesomeIcon>
                  Payment History
                </NavLink>
                <NavLink to='/App/Revenue' className='sidebarListItem' style={({ isActive}) => {
                  return {
                    "background-color": isActive? 'rgba(26, 168, 3, 0.15)' : "",
                  }
                }}>
                <FontAwesomeIcon icon={faSackDollar} className='icons-space'></FontAwesomeIcon>
                  Revenue
                </NavLink>
              </ul>
            </div>
            <div className='sidebarMenu'>
              <h3 className='sidebarTitle'>
                  ACCOUNT
              </h3>
              <ul className='sidebarList'>
              <NavLink to='/App/Agent_Applications' className='sidebarListItem' style={({ isActive}) => {
                  return {
                    "background-color": isActive? 'rgba(26, 168, 3, 0.15)' : "",
                  }
                }}>
                <img className={new_reg_app ? 'new-reg-app' : ''} src={red} alt='red' style={{height: '10px', width: '10px', display: 'none'}} />
              <MdOutlineNoteAlt className='icons-space3'/>
                    Agent Applications
                </NavLink>
                <NavLink to='/App/Reports' className='sidebarListItem' style={({ isActive}) => {
                  return {
                    "background-color": isActive? 'rgba(26, 168, 3, 0.15)' : "",
                  }
                }}>
                <img className={new_rep ? 'new-rep' : ''} src={red} alt='red' style={{height: '10px', width: '10px', display: 'none'}} />
                <MdFeedback className='icons-space3'/>
                  Reports
                </NavLink>
                <NavLink to='/App/System_Message' className='sidebarListItem' style={({ isActive}) => {
                  return {
                    "background-color": isActive? 'rgba(26, 168, 3, 0.15)' : "",
                  }
                }}>
                <FaRegEnvelope className='icons-space'/>
                  System message
                </NavLink>
              </ul>
            </div>
            <div className='sidebarMenu'>
              <h3 className='sidebarTitle'>
                  HELP
              </h3>
              <ul className='sidebarList'>             
                <NavLink to='/App/Support' className='sidebarListItem' style={({ isActive}) => {
                  return {
                    "background-color": isActive? 'rgba(26, 168, 3, 0.15)' : "",
                  }
                }}>
                <img className={new_msg ? 'new-msg': ''} src={red} alt='red' style={{height: '10px', width: '10px', display: 'none'}} />
                <VscFeedback className='icons-space3'/>
                  Help & Feedback
                </NavLink>          
                <NavLink to='/App/Settings' className='sidebarListItem' style={({ isActive}) => {
                  return {
                    "background-color": isActive? 'rgba(26, 168, 3, 0.15)' : "",
                  }
                }}>
                <GoSettings className='icons-space3'/>
                  Settings
                </NavLink>
                <NavLink to='/App/Logout' className='sidebarListItem' style={({ isActive}) => {
                  return {
                    "background-color": isActive? 'rgba(26, 168, 3, 0.15)' : "",
                  }
                }}>
                <FontAwesomeIcon icon={faSignOut} className='icons-space'></FontAwesomeIcon>
                  Log Out
                </NavLink>
              </ul>
            </div>
        </div>
      </div>
    </nav>
  );
} 