import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../../component/css/individual_fleet_payment.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";
import PageCounter from "../../component/page_counter";
import aang from "../../component/images/aang.jpg";
import emptybox from "../../component/images/emptybox.png";
import No_Payment from "../../component/notfound/No_Payment";

export const DataChecker = (data) => {
  //console.log(data)
  if (data === "nil") {
    return "No Data Yet";
  } else if (data) {
    return data;
  } else {
    return "No Data Yet";
  }
};

export const ZeroChecker = (cash) => {
  if (cash === 0) {
    return "₦0.00";
  } else {
    // const cash1 = 345234
    return "₦" + cash.toLocaleString();
  }
};

export default function Individual_Fleet_Payment() {
  const [data, setData] = useState([]);
  const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const token = JSON.parse(sessionStorage.getItem("rubbish"));
  const [result, setResult] = useState();
  const [bike, setBike] = useState("bikes");
  const [car, setCar] = useState("cars");
  const [van, setVan] = useState("cars");
  const [truck, setTruck] = useState("cars");
  const [type, setType] = useState("bike");
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  //console.log(location);
  const week = location.state.week;
  const month = location.state.month;
  const year = location.state.year;
  const fleet_manager_id = location.state.id;
  const status = location.state.status;
  const [resultPerPage, setResultPerPage] = useState(20);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [isLoaded === false]);

  let DATE = {};

  const TimeConverter = (props) => {
    //console.log(props)
    const date = new Date(props.value);
    DATE = {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString(),
      combined: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`,
    };
    return DATE.date;
  };

  const DayConverter = (day) => {
    const date = new Date(day).getDay();
    let actual_day = "";
    //console.log(date);
    switch (date) {
      case 0:
        actual_day = "Sunday";
        break;
      case 1:
        actual_day = "Monday";
        break;
      case 2:
        actual_day = "Tuesday";
        break;
      case 3:
        actual_day = "Wednesday";
        break;
      case 4:
        actual_day = "Thursday";
        break;
      case 5:
        actual_day = "Friday";
        break;
      case 6:
        actual_day = "Saturday";
        break;
    }
    return actual_day;
  };

  const handleCName1 = () => {
    setBike("bikes");
    setCar("cars");
    setVan("cars");
    setTruck("cars");
  };
  const handleCName2 = () => {
    setBike("cars");
    setCar("bikes");
    setVan("cars");
    setTruck("cars");
  };
  const handleCName3 = () => {
    setBike("cars");
    setCar("cars");
    setVan("bikes");
    setTruck("cars");
  };
  const handleCName4 = () => {
    setBike("cars");
    setCar("cars");
    setVan("cars");
    setTruck("bikes");
  };

  const StatusDeterminer = () => {
    if (status === "paid") return "paid-logo";
    return "not-paid-logo";
  };

  const TextDeterminer = () => {
    if (status === "paid") return "PAID";
    return "NOT-PAID";
  };

  const minusPagec = () => {
    if (pageCount <= 1) {
      return;
    } else {
      setDisable(true);
      const pageCountM = pageCount - 1;
      setPageCount((prev) => prev - 1);
      fetchData(pageCountM);
    }
  };
  const addPagec = () => {
    if (data?.trx.length <= resultPerPage - 1) return data;
    setDisable1(true);
    const pageCountM = pageCount + 1;
    setPageCount((prev) => prev + 1);
    fetchData(pageCountM);
  };

  const fetchData = async () => {
    //console.log(fleet_manager_id);
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_payment_record/fleet_manager",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            fleet_manager_id: fleet_manager_id,
            week: week,
            month: month,
            year: year,
            vehicle_type: type,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // setData(await response.json());
      const resultM = await response.json();
      setResult(resultM);
      setIsLoaded(true);
      //console.log(isLoaded);
      //console.log(resultM);
      setData(resultM);

      //console.log(data);
      if (
        resultM.msg ===
        "delivery agents weekly transactions gotten successfully"
      ) {
        setDisable(false);
        setDisable1(false);
        //console.log("data gotten succesfully");
      } else {
        setData("");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  if (!isLoaded) {
    return (
      <h1 className="loading-pages">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (
    result.msg === "delivery agents weekly transactions gotten successfully" &&
    data?.trx.length > 0
  ) {
    return (
      <div className="individual-fleet-payment">
        <div className="individual-fleet-details">
          <div className="back1">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              onClick={() => navigate(-1)}
              className="back"
            ></FontAwesomeIcon>
          </div>
          <div className="week-no">
            Week {week}
            <div className={StatusDeterminer()}>{TextDeterminer()}</div>
          </div>
          <div className="fleet-details">
            <div className="fleet-profile">
              <img
                src={
                  data?.fleet_manager?.img_url
                    ? data?.fleet_manager?.img_url
                    : aang
                }
                alt="user"
                className="fleet-image"
              />
            </div>
            <div className="fleet-about">
              <span>{data?.fleet_manager?.fleet_name}</span>
              <br></br>
              <br></br>
              <span>Fleet ID:</span> {data?.fleet_manager?.fleet_manager_code}
              <br></br>
              <span>Phone number:</span> {data?.fleet_manager?.phone_no}
              <br></br>
              <span>Fleet total earnings:</span>{" "}
              {ZeroChecker(data?.total_weeekly_earnings)}
              <br></br>
            </div>
            <div className="fleet-payment">
              <span>ACCOUNT DETAILS</span>
              <br></br>
              <br></br>
              <span>Account name:</span>{" "}
              {data?.fleet_manager?.bank_details?.account_name}
              <br></br>
              <span>Account number:</span>{" "}
              {data?.fleet_manager?.bank_details?.account_no}
              <br></br>
              <span>Account type :</span>{" "}
              {data?.fleet_manager?.bank_details?.account_type}
              <br></br>
              <span>Bank name:</span>{" "}
              {data?.fleet_manager?.bank_details?.bank_name}
              <br></br>
              <span>BVN:</span> {data?.fleet_manager?.bank_details?.bvn}
              <br></br>
            </div>
          </div>
          <div className="fleet-vehicle-payment">
            <div
              className={bike}
              onClick={() => {
                setIsLoaded(false);
                handleCName1();
                setType("bike");
                fetchData();
              }}
            >
              BIKES
            </div>
            <div
              className={car}
              onClick={() => {
                setIsLoaded(false);
                handleCName2();
                setType("car");
                fetchData();
              }}
            >
              CARS
            </div>
            <div
              className={van}
              onClick={() => {
                setIsLoaded(false);
                handleCName3();
                setType("van");
                fetchData();
              }}
            >
              VANS
            </div>
            <div
              className={truck}
              onClick={() => {
                setIsLoaded(false);
                handleCName4();
                setType("truck");
                fetchData();
              }}
            >
              TRUCKS
            </div>
          </div>
          <div className="fleet-table">
            <table>
              <thead>
                <th>Date</th>
                <th>Weekday</th>
                <th>Daily earnings</th>
              </thead>
              <tbody>
                {data?.trx?.map((item, i) => (
                  <tr key={i} className="payment-data">
                    <td>{<TimeConverter value={item.timestamp} />}</td>
                    <td>{DayConverter(item.timestamp)}</td>
                    <td>{ZeroChecker(item?.amt_for_delivery_agent)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <div style={{height:"20px", display:'flex', alignContent: "center", justifyContent: "flex-end", paddingRight: "20px", marginTop: "10px"}}>
            <div>
              {                
                disable ? <ClipLoader color={"black"} loading={disable}  size={15} /> : <FontAwesomeIcon icon={faAngleLeft} className={pageCount <= 1 ? "icon-space-less" : "icon-space"} onClick={minusPagec} />
              }
            </div>
            <h6>{PageCounter(result.count, pageCount, resultPerPage)}</h6>
            <div>
              {
                disable1 ? <ClipLoader color={"black"} loading={disable1}  size={15} /> : <FontAwesomeIcon icon={faAngleRight} className={data?.trx.length <= (resultPerPage -1) ? "icon-space-less" : "icon-space"} onClick={addPagec} /> 
              }
            </div>
          </div> */}
        </div>
      </div>
    );
  } else if (
    result.msg === "delivery agents weekly transactions gotten successfully" &&
    data?.trx.length === 0
  ) {
    //console.log(isLoaded);
    //console.log("data gotten successfully");
    return (
      <div className="individual-fleet-payment">
        <div className="individual-fleet-details">
          <div className="back1">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              onClick={() => navigate(-1)}
              className="back"
            ></FontAwesomeIcon>
          </div>
          <div className="week-no">
            Week {week}
            <div className="payment-logo">NOT-PAID</div>
          </div>
          <div className="fleet-details">
            <div className="fleet-profile">
              <img
                src={
                  data?.fleet_manager?.img_url
                    ? data?.fleet_manager?.img_url
                    : aang
                }
                alt="user"
                className="fleet-image"
              />
            </div>
            <div className="fleet-about">
              <span>{data?.fleet_manager?.fleet_name}</span>
              <br></br>
              <br></br>
              <span>Fleet ID:</span> {data?.fleet_manager?.fleet_manager_code}
              <br></br>
              <span>Phone Number:</span> {data?.fleet_manager?.phone_no}
              <br></br>
              <span>Fleet Total Earning:</span>{" "}
              {ZeroChecker(data?.total_weeekly_earnings)}
              <br></br>
            </div>
            <div className="fleet-payment">
              <span>Account Details</span>
              <br></br>
              <br></br>
              <span>Account Name:</span>{" "}
              {data?.fleet_manager?.bank_details?.account_name}
              <br></br>
              <span>Account Number:</span>{" "}
              {data?.fleet_manager?.bank_details?.account_no}
              <br></br>
              <span>Account type :</span>{" "}
              {data?.fleet_manager?.bank_details?.account_type}
              <br></br>
              <span>Bank name:</span>{" "}
              {data?.fleet_manager?.bank_details?.bank_name}
              <br></br>
              <span>BVN:</span> {data?.fleet_manager?.bank_details?.bvn}
              <br></br>
            </div>
          </div>
          <div className="fleet-vehicle-payment">
            <div
              className={bike}
              onClick={() => {
                setIsLoaded(false);
                handleCName1();
                setType("bike");
                fetchData();
              }}
            >
              BIKES
            </div>
            <div
              className={car}
              onClick={() => {
                setIsLoaded(false);
                handleCName2();
                setType("car");
                fetchData();
              }}
            >
              CARS
            </div>
            <div
              className={van}
              onClick={() => {
                setIsLoaded(false);
                handleCName3();
                setType("van");
                fetchData();
              }}
            >
              VANS
            </div>
            <div
              className={truck}
              onClick={() => {
                setIsLoaded(false);
                handleCName4();
                setType("truck");
                fetchData();
              }}
            >
              TRUCKS
            </div>
          </div>
          <div className="found-not">
            <img src={emptybox} alt="not found" />
            <p>No Transaction for This Week</p>
          </div>
        </div>
      </div>
    );
  }
}
