import React, { useState, useEffect } from "react";
import "../../component/css/agent_form.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import vehicleimgavatar from "../../component/images/vehicleimgavatar.png";
import aang from "../../component/images/aang.jpg";
import licence from "../../component/images/licence.jpg";
import { ClipLoader } from "react-spinners";

export default function Agent_Form() {
  const [data, getData] = useState([]);
  const location = useLocation();
  const delivery_agent_id = location.state.id;
  const type = location.state.type;
  const navigate = useNavigate();
  //console.log(delivery_agent_id);
  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState();
  const [gender, setGender] = useState("male");
  const token = JSON.parse(sessionStorage.getItem("rubbish"));

  const handleGender = (event) => {
    setGender(event.target.value);
  };

  let DATE = {};
  const TimeConverter = (props) => {
    //console.log(props)
    const date = new Date(props.value);
    DATE = {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString(),
      combined: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`,
    };
    return DATE.time;
  };
  const DateConverter = (props) => {
    const date = new Date(props.value);
    DATE = {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString(),
    };
    return DATE.date;
  };

  useEffect(() => {
    fetchData();
  }, [isLoaded === false]);

  const fetchData = async () => {
    try {
      //console.log('I am here')
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_reg_request/reg_request",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            delivery_agent_id: delivery_agent_id,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const resultM = await response.json();
      //console.log(resultM.delivery_agent);
      getData(resultM.delivery_agent);
      setResult(resultM);
      setIsLoaded(true);

      //console.log(`-----------> ${result}`);

      if (response.status === 200) {
        //console.log("data gotten succesfully");
      } else {
        //console.log("somen error occurred");
      }
    } catch (error) {
      //console.log(error);
    }
  };
  if (!isLoaded) {
    //console.log("got here");
    return (
      <h1 className="loading-pages">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (result.msg === "Success") {
    //console.log("data gotten succesfully");
    return (
      <div className="agent-form">
        <Link to="/App/Agent_Applications" className="back3">
          <FontAwesomeIcon
            icon={faArrowLeftLong}
            className="back"
          ></FontAwesomeIcon>
        </Link>
        <div className="agent-form-props">
            {/* <div className="form-information">{JSON.stringify(data)}</div> */}
          <div className="agent-form-title">Personal Information</div>
          <div className="form-details">
            <label className="agent-form-label">Full Name</label>
            <div className="form-information">{data?.fullname}</div>

            <label className="agent-form-label">Email </label>
            <div className="form-information">{data?.email}</div>

            <label className="agent-form-label">Phone number </label>
            <div className="form-information">{data?.phone_no}</div>

            <label className="agent-form-label">Address </label>
            <div className="form-information1">{data?.address}</div>

            <label className="agent-form-label">NIN </label>
            <div className="form-information1">{data?.nin}</div>

            <div className="state-gender">
              <div className="state-row">
                <label className="agent-form-label">State </label>
                <div className="state-information">{data?.state}</div>
              </div>
              <div className="state-row">
                <label className="agent-form-label">City </label>
                <div className="state-information">{data?.city}</div>
              </div>
            </div>

            <div className="state-row">
              <label className="agent-form-label">Gender </label>
              <select
                className="gender-information"
                value={gender}
                onChange={handleGender}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>

            {/* <label className="agent-form-label">Passport/Selfie photo </label>
            <div className="passport-information">
              {" "}
              <img
                src={data?.img_url ? data?.img_url : aang}
                alt=""
                className="passport"
              />{" "}
            </div> */}

            {type == "delivery agent" ? (
              <>
                <hr className="agent-line"></hr>
                <div className="agent-form-title">Vehicle Information</div>
                <label className="agent-form-label">Delivery medium</label>
                <div className="form-information">
                  {data?.vehicle_details?.type}
                </div>
                <label className="agent-form-label">
                  Vehicle manufacurer/type
                </label>
                <div className="form-information">
                  {data?.vehicle_details?.name}
                </div>
                <label className="agent-form-label">Vehicle color </label>
                <div className="form-information">
                  {data?.vehicle_details?.color}
                </div>
                <label className="agent-form-label">Plate number</label>
                <div className="form-information">
                  {data?.vehicle_details?.plate_no}
                </div>
                <label className="agent-form-label">
                  Driver's licence expiry date
                </label>
                <div className="form-information">
                  {data?.vehicle_details?.driver_license_expiry_date}
                </div>
                <label className="agent-form-label">Driver's licence </label>
                <div className="licence-information">
                  <img
                    src={
                      data?.vehicle_details?.img_urls?.[0]
                        ? data?.vehicle_details?.img_urls?.[0]
                        : licence
                    }
                    alt="icon"
                    className="licence-image"
                  />
                  <img
                    src={
                      data?.vehicle_details?.img_urls?.[1]
                        ? data?.vehicle_details?.img_urls?.[1]
                        : licence
                    }
                    alt="icon"
                    className="licence-image"
                  />
                </div>
                <label className="agent-form-label">Vehicle image</label>
                <div className="vehicle-information">
                  <img
                    src={
                      data?.vehicle_details?.img_urls?.[2]
                        ? data?.vehicle_details?.img_urls?.[2]
                        : vehicleimgavatar
                    }
                    alt="icon"
                    className="vehicle-image"
                  />
                  <img
                    src={
                      data?.vehicle_details?.img_urls?.[3]
                        ? data?.vehicle_details?.img_urls?.[3]
                        : vehicleimgavatar
                    }
                    alt="icon"
                    className="vehicle-image"
                  />
                  <img
                    src={
                      data?.vehicle_details?.img_urls?.[4]
                        ? data?.vehicle_details?.img_urls?.[4]
                        : vehicleimgavatar
                    }
                    alt="icon"
                    className="vehicle-image"
                  />
                </div>{" "}
              </>
            ) : null}

            <hr className="agent-line"></hr>
            {data?.bank_details?.bank_name != "" ? (
              <>
                <div className="agent-form-title">Bank Details</div>

                <label className="agent-form-label">
                  Bank Account holder's name{" "}
                </label>
                <div className="form-information">
                  {data?.bank_details?.account_name}
                </div>

                <label className="agent-form-label">Bank name </label>
                <div className="form-information">
                  {data?.bank_details?.bank_name}
                </div>

                <label className="agent-form-label">Bank Account number </label>
                <div className="form-information">
                  {data?.bank_details?.account_no}
                </div>

                <label className="agent-form-label">Account type </label>
                <div className="form-information">
                  {data?.bank_details?.account_type}
                </div>

                <label className="agent-form-label">BVN </label>
                <div className="form-information">
                  {data?.bank_details?.bvn}
                </div>
              </>
            ) : (
              <div className="agent-form-title">
                Registered under fleet manager. No bank details
              </div>
            )}
            {type != "delivery agent" ? (
              <>
                <hr className="agent-line"></hr>

                <label className="agent-form-label">Company name </label>
                <div className="form-information">{data?.company_name}</div>

                <label className="agent-form-label">
                  CAC Registration Number
                </label>
                <div className="form-information">{data?.cac_reg_no}</div>
              </>
            ) : null}

            <hr className="agent-line"></hr>

            <label className="agent-form-label">Date Registered</label>
            <div className="form-information">
              {<DateConverter value={data?.registration_time} />}
            </div>

            <label className="agent-form-label">Time Registered</label>
            <div className="form-information">
              {<TimeConverter value={data?.registration_time} />}
            </div>

            <div className="accept-form">
              <button
                className="decline"
                onClick={() =>
                  navigate("/App/Decline_Agent_Application", {
                    state: { id: delivery_agent_id, type: type },
                  })
                }
              >
                Decline
              </button>
              <button
                className="accept"
                onClick={() =>
                  navigate("/App/Accept_Agent_Application", {
                    state: { id: delivery_agent_id, gender: gender },
                  })
                }
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
