import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";
import "../component/css/popup.css";

const Confirm_Pin2 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  //console.log(location);
  const [disable2, setDisable2] = useState(false);
  const [pin, setPin] = useState("");
  const {
    func,
    week,
    month,
    year,
    transaction_type,
    vehicle_type,
    not_paids,
    noti_data,
    paids,
  } = location.state;
  //console.log(paids);
  const token = JSON.parse(sessionStorage.getItem("rubbish"));
  let fire = "";

  const CheckPin = async () => {
    try {
      setDisable2(true);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_payment_record/verify_activity_pin",
        {
          method: "POST",

          body: JSON.stringify({
            token,
            activity_pin: pin,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );

      const result = await response.json();

      if (result.msg === "activity pin correct") {
        //console.log("data gotten succesfully");
        fire();
      } else if (result.msg === "activity pin incorrect") {
        window.alert("Activity pin incorrect");
        setDisable2(false);
      } else {
        //console.log("some error occurred");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const savePaymentCheck = async () => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_payment_record/save_not_paids",
        {
          method: "POST",

          body: JSON.stringify({
            token,
            week,
            month,
            year,
            transaction_type,
            vehicle_type,
            not_paids,
            noti_data,
            paids,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );

      const result = await response.json();

      if (result.msg === "Save Success") {
        //console.log("data gotten succesfully");
        setDisable2(false);
        window.alert("Payment successful");
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      } else {
        //console.log("some error occurred");
        window.alert("Payment unsuccessful");
        setDisable2(false);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const updatePaymentCheck = async () => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_payment_record/update_not_paids",
        {
          method: "POST",

          body: JSON.stringify({
            token,
            week,
            month,
            year,
            transaction_type,
            vehicle_type,
            not_paids,
            noti_data,
            paids,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );

      const result = await response.json();

      if (result.msg === "Update Success") {
        window.alert("Payment successful");
        setTimeout(() => {
          navigate(-1);
        }, 3000);
        setDisable2(false);
      } else {
        window.alert("Payment unsuccessful");
        setDisable2(false);
        //console.log("some error occurred");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  if (func === "update") {
    fire = updatePaymentCheck;
  } else {
    fire = savePaymentCheck;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    CheckPin();
  };

  const handleChange = (e) => {
    e.preventDefault();
    setPin(e.target.value);
  };

  return (
    <div className="chizzys-parent-container">
      <FontAwesomeIcon
        icon={faArrowLeftLong}
        className="chizzys-arrow"
        onClick={() => navigate(-1)}
      ></FontAwesomeIcon>
      <div className="logout-container">
        <div className="logout-background">
          <div className="logout-confirmation1">
            <div className="chizzy-confirmation-info">
              <br></br>
              <h4>Confirm activity pin</h4>
              <br></br>
              <br></br>
              {/* <img src={amico} width='110px' height='110px' alt='icon' /><br></br> */}
              <div className="logout-rematched">
                <p>Please put in your activity pin to cotinue this operation</p>
              </div>
            </div>
            <div className="logout-dialog-row">
              <form className="chizzys-form" onSubmit={handleSubmit}>
                <input
                  type="password"
                  value={pin}
                  onChange={handleChange}
                  placeholder="Activity pin"
                />
                <button type="submit">
                  {disable2 ? (
                    <ClipLoader color={"black"} loading={disable2} size={15} />
                  ) : (
                    "Pay"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirm_Pin2;
