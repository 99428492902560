import React, { useState, useEffect } from "react";
import "../../component/css/video_settings.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  //   faAngleLeft,
  //   faAngleRight,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
// import { toast } from "react-hot-toast";

export default function VideoSettings() {
  //   const showCustomToast = (
  //     type,
  //     message,
  //     autoClose = 3000,
  //     toast_position = "top-center"
  //   ) => {
  //     // toast.remove();
  //     toast.dismiss();
  //     if (type == 0) {
  //       toast.error(message, {
  //         icon: "❗️",
  //         position: toast_position,
  //         autoClose: autoClose,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: false,
  //         progress: undefined,
  //         theme: "light",
  //         // transition: Flip,
  //       });
  //     } else if (type == 1) {
  //       toast.success(message, {
  //         position: toast_position,
  //       });
  //     } else if (type == 2) {
  //       toast(message, { autoClose: autoClose, position: toast_position });
  //     }
  //   };

  const navigate = useNavigate();

  const token = JSON.parse(sessionStorage.getItem("rubbish"));
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [videos, setVideos] = useState(null);

  const [result, setResult] = useState("");

  const [disableGenUrl, setDisableGenUrl] = useState(false);
  const [disableUserButton, setDisableUserButton] = useState(false);
  const [disableAgentButton, setDisableAgentButton] = useState(false);

  //   ----------------------------------main promo----------------------------------
  const [gottenMainPromoData, setGottenMainPromoData] = useState({});
  const [main_promo_url, set_main_promo_url] = useState("");
  const [is_main_promo_thumbnail_changed, set_is_main_promo_thumbnail_changed] =
    useState(false);
  const [local_main_promo_thumbnail_file, set_local_main_promo_thumbnail_file] =
    useState(null);
  const [local_main_promo_thumbnail_src, set_local_main_promo_thumbnail_scr] =
    useState("");
  // _________________________________________________________________________________
  //   ----------------------------------users promo----------------------------------
  // State variables for the first set
  const [disableUserButton1, setDisableUserButton1] = useState(false);
  const [gottenUserPromoData1, setGottenUserPromoData1] = useState(null);
  const [user_promo_url1, set_user_promo_url1] = useState("");
  const [
    is_user_promo_thumbnail_changed1,
    set_is_user_promo_thumbnail_changed1,
  ] = useState(false);
  const [
    local_user_promo_thumbnail_file1,
    set_local_user_promo_thumbnail_file1,
  ] = useState(null);
  const [local_user_promo_thumbnail_src1, set_local_user_promo_thumbnail_scr1] =
    useState("");

  // State variables for the second set
  const [disableUserButton2, setDisableUserButton2] = useState(false);
  const [gottenUserPromoData2, setGottenUserPromoData2] = useState(null);
  const [user_promo_url2, set_user_promo_url2] = useState("");
  const [
    is_user_promo_thumbnail_changed2,
    set_is_user_promo_thumbnail_changed2,
  ] = useState(false);
  const [
    local_user_promo_thumbnail_file2,
    set_local_user_promo_thumbnail_file2,
  ] = useState(null);
  const [local_user_promo_thumbnail_src2, set_local_user_promo_thumbnail_scr2] =
    useState("");

  // State variables for the third set
  const [disableUserButton3, setDisableUserButton3] = useState(false);
  const [gottenUserPromoData3, setGottenUserPromoData3] = useState(null);
  const [user_promo_url3, set_user_promo_url3] = useState("");
  const [
    is_user_promo_thumbnail_changed3,
    set_is_user_promo_thumbnail_changed3,
  ] = useState(false);
  const [
    local_user_promo_thumbnail_file3,
    set_local_user_promo_thumbnail_file3,
  ] = useState(null);
  const [local_user_promo_thumbnail_src3, set_local_user_promo_thumbnail_scr3] =
    useState("");

  // State variables for the fourth set
  const [disableUserButton4, setDisableUserButton4] = useState(false);
  const [gottenUserPromoData4, setGottenUserPromoData4] = useState(null);
  const [user_promo_url4, set_user_promo_url4] = useState("");
  const [
    is_user_promo_thumbnail_changed4,
    set_is_user_promo_thumbnail_changed4,
  ] = useState(false);
  const [
    local_user_promo_thumbnail_file4,
    set_local_user_promo_thumbnail_file4,
  ] = useState(null);
  const [local_user_promo_thumbnail_src4, set_local_user_promo_thumbnail_scr4] =
    useState("");

  // State variables for the fifth set
  const [disableUserButton5, setDisableUserButton5] = useState(false);
  const [gottenUserPromoData5, setGottenUserPromoData5] = useState(null);
  const [user_promo_url5, set_user_promo_url5] = useState("");
  const [
    is_user_promo_thumbnail_changed5,
    set_is_user_promo_thumbnail_changed5,
  ] = useState(false);
  const [
    local_user_promo_thumbnail_file5,
    set_local_user_promo_thumbnail_file5,
  ] = useState(null);
  const [local_user_promo_thumbnail_src5, set_local_user_promo_thumbnail_scr5] =
    useState("");

  //   ----------------------------------delivery agents promo----------------------------------
  // State variables for the first set
  const [disableDeliveryAgentButton1, setDisableDeliveryAgentButton1] =
    useState(false);
  const [gottenDeliveryAgentPromoData1, setGottenDeliveryAgentPromoData1] =
    useState(null);
  const [delivery_agent_promo_url1, set_delivery_agent_promo_url1] =
    useState("");
  const [
    is_delivery_agent_promo_thumbnail_changed1,
    set_is_delivery_agent_promo_thumbnail_changed1,
  ] = useState(false);
  const [
    local_delivery_agent_promo_thumbnail_file1,
    set_local_delivery_agent_promo_thumbnail_file1,
  ] = useState(null);
  const [
    local_delivery_agent_promo_thumbnail_src1,
    set_local_delivery_agent_promo_thumbnail_scr1,
  ] = useState("");

  // State variables for the second set
  const [disableDeliveryAgentButton2, setDisableDeliveryAgentButton2] =
    useState(false);
  const [gottenDeliveryAgentPromoData2, setGottenDeliveryAgentPromoData2] =
    useState(null);
  const [delivery_agent_promo_url2, set_delivery_agent_promo_url2] =
    useState("");
  const [
    is_delivery_agent_promo_thumbnail_changed2,
    set_is_delivery_agent_promo_thumbnail_changed2,
  ] = useState(false);
  const [
    local_delivery_agent_promo_thumbnail_file2,
    set_local_delivery_agent_promo_thumbnail_file2,
  ] = useState(null);
  const [
    local_delivery_agent_promo_thumbnail_src2,
    set_local_delivery_agent_promo_thumbnail_scr2,
  ] = useState("");

  // State variables for the third set
  const [disableDeliveryAgentButton3, setDisableDeliveryAgentButton3] =
    useState(false);
  const [gottenDeliveryAgentPromoData3, setGottenDeliveryAgentPromoData3] =
    useState(null);
  const [delivery_agent_promo_url3, set_delivery_agent_promo_url3] =
    useState("");
  const [
    is_delivery_agent_promo_thumbnail_changed3,
    set_is_delivery_agent_promo_thumbnail_changed3,
  ] = useState(false);
  const [
    local_delivery_agent_promo_thumbnail_file3,
    set_local_delivery_agent_promo_thumbnail_file3,
  ] = useState(null);
  const [
    local_delivery_agent_promo_thumbnail_src3,
    set_local_delivery_agent_promo_thumbnail_scr3,
  ] = useState("");

  // State variables for the fourth set
  const [disableDeliveryAgentButton4, setDisableDeliveryAgentButton4] =
    useState(false);
  const [gottenDeliveryAgentPromoData4, setGottenDeliveryAgentPromoData4] =
    useState(null);
  const [delivery_agent_promo_url4, set_delivery_agent_promo_url4] =
    useState("");
  const [
    is_delivery_agent_promo_thumbnail_changed4,
    set_is_delivery_agent_promo_thumbnail_changed4,
  ] = useState(false);
  const [
    local_delivery_agent_promo_thumbnail_file4,
    set_local_delivery_agent_promo_thumbnail_file4,
  ] = useState(null);
  const [
    local_delivery_agent_promo_thumbnail_src4,
    set_local_delivery_agent_promo_thumbnail_scr4,
  ] = useState("");

  // State variables for the fifth set
  const [disableDeliveryAgentButton5, setDisableDeliveryAgentButton5] =
    useState(false);
  const [gottenDeliveryAgentPromoData5, setGottenDeliveryAgentPromoData5] =
    useState(null);
  const [delivery_agent_promo_url5, set_delivery_agent_promo_url5] =
    useState("");
  const [
    is_delivery_agent_promo_thumbnail_changed5,
    set_is_delivery_agent_promo_thumbnail_changed5,
  ] = useState(false);
  const [
    local_delivery_agent_promo_thumbnail_file5,
    set_local_delivery_agent_promo_thumbnail_file5,
  ] = useState(null);
  const [
    local_delivery_agent_promo_thumbnail_src5,
    set_local_delivery_agent_promo_thumbnail_scr5,
  ] = useState("");

  // _________________________________________________________________________________

  const change_video_thumbnail = (e, type, place) => {
    const reader = new FileReader();

    if (type == "main_promo") {
      reader.onload = () => {
        if (reader.readyState == 2) {
          set_local_main_promo_thumbnail_scr(reader.result);
        }
      };

      reader.readAsDataURL(e.target.files[0]);
      set_local_main_promo_thumbnail_file(e.target.files[0]);
      set_is_main_promo_thumbnail_changed(true);
    } else if (type == "user_promo") {
      if (place === 1) {
        reader.onload = () => {
          if (reader.readyState == 2) {
            set_local_user_promo_thumbnail_scr1(reader.result);
          }
        };

        reader.readAsDataURL(e.target.files[0]);
        set_local_user_promo_thumbnail_file1(e.target.files[0]);
        set_is_user_promo_thumbnail_changed1(true);
      } else if (place === 2) {
        reader.onload = () => {
          if (reader.readyState == 2) {
            set_local_user_promo_thumbnail_scr2(reader.result);
          }
        };

        reader.readAsDataURL(e.target.files[0]);
        set_local_user_promo_thumbnail_file2(e.target.files[0]);
        set_is_user_promo_thumbnail_changed2(true);
      } else if (place === 3) {
        reader.onload = () => {
          if (reader.readyState == 2) {
            set_local_user_promo_thumbnail_scr3(reader.result);
          }
        };

        reader.readAsDataURL(e.target.files[0]);
        set_local_user_promo_thumbnail_file3(e.target.files[0]);
        set_is_user_promo_thumbnail_changed3(true);
      } else if (place === 4) {
        reader.onload = () => {
          if (reader.readyState == 2) {
            set_local_user_promo_thumbnail_scr4(reader.result);
          }
        };

        reader.readAsDataURL(e.target.files[0]);
        set_local_user_promo_thumbnail_file4(e.target.files[0]);
        set_is_user_promo_thumbnail_changed4(true);
      } else if (place === 5) {
        reader.onload = () => {
          if (reader.readyState == 2) {
            set_local_user_promo_thumbnail_scr5(reader.result);
          }
        };

        reader.readAsDataURL(e.target.files[0]);
        set_local_user_promo_thumbnail_file5(e.target.files[0]);
        set_is_user_promo_thumbnail_changed5(true);
      }
    } else if (type == "agent_promo") {
      if (place === 1) {
        reader.onload = () => {
          if (reader.readyState == 2) {
            set_local_delivery_agent_promo_thumbnail_scr1(reader.result);
          }
        };

        reader.readAsDataURL(e.target.files[0]);
        set_local_delivery_agent_promo_thumbnail_file1(e.target.files[0]);
        set_is_delivery_agent_promo_thumbnail_changed1(true);
      }

      if (place === 2) {
        reader.onload = () => {
          if (reader.readyState == 2) {
            set_local_delivery_agent_promo_thumbnail_scr2(reader.result);
          }
        };

        reader.readAsDataURL(e.target.files[0]);
        set_local_delivery_agent_promo_thumbnail_file2(e.target.files[0]);
        set_is_delivery_agent_promo_thumbnail_changed2(true);
      }

      if (place === 3) {
        reader.onload = () => {
          if (reader.readyState == 2) {
            set_local_delivery_agent_promo_thumbnail_scr3(reader.result);
          }
        };

        reader.readAsDataURL(e.target.files[0]);
        set_local_delivery_agent_promo_thumbnail_file3(e.target.files[0]);
        set_is_delivery_agent_promo_thumbnail_changed3(true);
      }

      if (place === 4) {
        reader.onload = () => {
          if (reader.readyState == 2) {
            set_local_delivery_agent_promo_thumbnail_scr4(reader.result);
          }
        };

        reader.readAsDataURL(e.target.files[0]);
        set_local_delivery_agent_promo_thumbnail_file4(e.target.files[0]);
        set_is_delivery_agent_promo_thumbnail_changed4(true);
      }

      if (place === 5) {
        reader.onload = () => {
          if (reader.readyState == 2) {
            set_local_delivery_agent_promo_thumbnail_scr5(reader.result);
          }
        };

        reader.readAsDataURL(e.target.files[0]);
        set_local_delivery_agent_promo_thumbnail_file5(e.target.files[0]);
        set_is_delivery_agent_promo_thumbnail_changed5(true);
      }
    }
  };

  const handleUpload = async (place, video_url, type) => {
    type == "main_promo"
      ? setDisableGenUrl(true)
      : type == "user_promo"
      ? setDisableUserButton(true)
      : setDisableAgentButton(true);
    // e.preventDefault();

    const bodyFormData = new FormData();

    bodyFormData.append("place", place);
    bodyFormData.append("video_url", video_url);
    bodyFormData.append("doc_type", type);

    if (type === "main_promo") {
      if (is_main_promo_thumbnail_changed == true) {
        bodyFormData.append("image", local_main_promo_thumbnail_file);
      }
    } else if (type === "user_promo") {
      if (place === "1") {
        if (is_user_promo_thumbnail_changed1) {
          bodyFormData.append("image", local_user_promo_thumbnail_file1);
        }
      } else if (place === 2) {
        if (is_user_promo_thumbnail_changed2) {
          bodyFormData.append("image", local_user_promo_thumbnail_file2);
        }
      } else if (place === 3) {
        if (is_user_promo_thumbnail_changed3) {
          bodyFormData.append("image", local_user_promo_thumbnail_file3);
        }
      } else if (place === 4) {
        if (is_user_promo_thumbnail_changed4) {
          bodyFormData.append("image", local_user_promo_thumbnail_file4);
        }
      } else if (place === 5) {
        if (is_user_promo_thumbnail_changed5) {
          bodyFormData.append("image", local_user_promo_thumbnail_file5);
        }
      }
    } else if (type === "agent_promo") {
      if (place === 1) {
        if (is_delivery_agent_promo_thumbnail_changed1) {
          bodyFormData.append(
            "image",
            local_delivery_agent_promo_thumbnail_file1
          );
        }
      } else if (place === 2) {
        if (is_delivery_agent_promo_thumbnail_changed2) {
          bodyFormData.append(
            "image",
            local_delivery_agent_promo_thumbnail_file2
          );
        }
      } else if (place === 3) {
        if (is_delivery_agent_promo_thumbnail_changed3) {
          bodyFormData.append(
            "image",
            local_delivery_agent_promo_thumbnail_file3
          );
        }
      } else if (place === 4) {
        if (is_delivery_agent_promo_thumbnail_changed4) {
          bodyFormData.append(
            "image",
            local_delivery_agent_promo_thumbnail_file4
          );
        }
      } else if (place === 5) {
        if (is_delivery_agent_promo_thumbnail_changed5) {
          bodyFormData.append(
            "image",
            local_delivery_agent_promo_thumbnail_file5
          );
        }
      }
    }

    // console.log(is_main_promo_thumbnail_changed);
    for (const pair of bodyFormData.entries()) {
      //   console.log(pair[0] + ">> " + pair[1]);
      console.log(`${pair[0]} >> ${JSON.stringify(pair[1])}`);
    }

    axios
      .post(
        "https://serverpickload.wl.r.appspot.com/admin_upload_promo/upload_youtube_video",
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )

      .then((response) => {
        console.log(`----------> ${response}`);
        if (response.data["msg"] == "Success") {
          //   showCustomToast(1, "Successful");
        //   toast.success("Sucess!");
        } else {
          //   showErrorToast(
          //     "oOps! Looks like something went wrong. Please try again"
          //   );
          alert(response.data["msg"]);
        }
        type == "main_promo"
          ? setDisableGenUrl(false)
          : type == "user_promo"
          ? setDisableUserButton(false)
          : setDisableAgentButton(false);
      })
      .catch((error) => {
        // showErrorToast(
        //   "The server encountered an error. Please try again later"
        // );
        type == "main_promo"
          ? setDisableGenUrl(false)
          : type == "user_promo"
          ? setDisableUserButton(false)
          : setDisableAgentButton(false);
        alert(error);
      });
  };

  useEffect(() => {
    viewUrl();
  }, []);
  const viewUrl = async () => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_upload_promo/view_youtube_videos",
        {
          method: "POST",

          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const resultM = await response.json();
      setResult(resultM);
      console.log(`----------> ${JSON.stringify(resultM)}`);
      //   let mainPromoObj;
      //   let userPromoData;
      //   let deliveryAgentsPromoData;

      if (resultM.status === "ok") {
        resultM.doc.forEach((item) => {
          if (item.doc_type === "main_promo") {
            setGottenMainPromoData(item);
            set_main_promo_url(item.video_url);
          }
          if (item.doc_type === "user_promo") {
            if (item.place === "1") {
              setGottenUserPromoData1(item);
              set_user_promo_url1(item.video_url);
            } else if (item.place === "2") {
              setGottenUserPromoData2(item);
              set_user_promo_url2(item.video_url);
            } else if (item.place === "3") {
              setGottenUserPromoData3(item);
              set_user_promo_url3(item.video_url);
            } else if (item.place === "4") {
              setGottenUserPromoData4(item);
              set_user_promo_url4(item.video_url);
            } else if (item.place === "5") {
              setGottenUserPromoData5(item);
              set_user_promo_url5(item.video_url);
            }
          }

          if (item.doc_type === "agent_promo") {
            if (item.place === "1") {
              setGottenDeliveryAgentPromoData1(item);
              set_delivery_agent_promo_url1(item.video_url);
            } else if (item.place === "2") {
              setGottenDeliveryAgentPromoData2(item);
              set_delivery_agent_promo_url2(item.video_url);
            } else if (item.place === "3") {
              setGottenDeliveryAgentPromoData3(item);
              set_delivery_agent_promo_url3(item.video_url);
            } else if (item.place === "4") {
              setGottenDeliveryAgentPromoData4(item);
              set_delivery_agent_promo_url4(item.video_url);
            } else if (item.place === "5") {
              setGottenDeliveryAgentPromoData5(item);
              set_delivery_agent_promo_url5(item.video_url);
            }
          }
        });

        setIsLoading(false);
      }

      // setSelectedSrc(data?.promo.thumbnail_url);
      //   setLinkU(data?.promo.clip_url);
      //console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  if (isLoading) {
    return (
      <h1 className="loading-pages">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (result.msg === "Success") {
    return (
      <div className="settings">
        <div className="settings-props">
          <div className="back2">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              onClick={() => navigate(-1)}
              className="back"
            ></FontAwesomeIcon>
          </div>
          <div className="settings-title">Video Settings</div>
          <h3 className="video-settings-data-title">Main promotional video</h3>
          <div className="settings-grid-container">
            <div className="distance">
              <label className="settings-detail-title">Video url</label>
              <form>
                <input
                  className="settings-link-input"
                  type="text"
                  value={main_promo_url}
                  // onChange={handleMainPromo}

                  onChange={(e) => set_main_promo_url(e.target.value)}
                />
              </form>
            </div>

            <div className="settings-center-column">
              <>
                {}

                {local_main_promo_thumbnail_src === "" ? (
                  gottenMainPromoData?.thumbnail_url ? (
                    <img
                      className="thumbnail-image"
                      src={gottenMainPromoData?.thumbnail_url}
                      alt=""
                      style={{ objectFit: "cover" }}
                    />
                  ) : null
                ) : (
                  <img
                    src={local_main_promo_thumbnail_src}
                    alt=""
                    className="thumbnail-image"
                  />
                )}

                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  //   onChange={change_video_thumbnail}
                  onChange={(e) => change_video_thumbnail(e, "main_promo", 1)}

                  className="hide-no-file-chosen"
                />
              </>
            </div>

            <div>
              <button
                className="settings-activate1"
                disabled={disableGenUrl}
                onClick={() => handleUpload(1, main_promo_url, "main_promo")}
              >
                {disableGenUrl ? (
                  <ClipLoader
                    color={"black"}
                    loading={disableGenUrl}
                    size={15}
                  />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div>
          <hr />
          {/* __________________ */}
          <h3 className="video-settings-data-title">Testimonial videos from users</h3>
          <div className="settings-grid-container">
            <div className="distance">
              <label className="settings-detail-title">Video url 1</label>
              <form>
                <input
                  className="settings-link-input"
                  type="text"
                  value={user_promo_url1}
                  
                  // onChange={handleMainPromo}

                  onChange={(e) => set_user_promo_url1(e.target.value)}
                />
              </form>
            </div>

            <div className="settings-center-column">
              <>
                {}

                {local_user_promo_thumbnail_src1 === "" ? (
                  gottenUserPromoData1?.thumbnail_url ? (
                    <img
                      className="thumbnail-image"
                      src={gottenUserPromoData1?.thumbnail_url}
                      alt=""
                      style={{ objectFit: "cover" }}
                    />
                  ) : null
                ) : (
                  <img
                    src={local_user_promo_thumbnail_src1}
                    alt=""
                    className="thumbnail-image"
                  />
                )}

                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  onChange={(e) => change_video_thumbnail(e, "user_promo", 1)}
                  className="hide-no-file-chosen"
                />
              </>
            </div>

            <div>
              <button
                className="settings-activate1"
                disabled={disableUserButton}
                onClick={() => handleUpload(1, user_promo_url1, "user_promo")}
              >
                {disableGenUrl ? (
                  <ClipLoader
                    color={"black"}
                    loading={disableUserButton}
                    size={15}
                  />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div>
          {/* ____________ */}
          <div className="settings-grid-container">
            <div className="distance">
              <label className="settings-detail-title">Video url 2</label>
              <form>
                <input
                  className="settings-link-input"
                  type="text"
                  value={user_promo_url2}
                  onChange={(e) => set_user_promo_url2(e.target.value)}
                />
              </form>
            </div>

            <div className="settings-center-column">
              <>
                {local_user_promo_thumbnail_src2 === "" ? (
                  gottenUserPromoData2?.thumbnail_url ? (
                    <img
                      className="thumbnail-image"
                      src={gottenUserPromoData2?.thumbnail_url}
                      alt=""
                      style={{ objectFit: "cover" }}
                    />
                  ) : null
                ) : (
                  <img
                    src={local_user_promo_thumbnail_src2}
                    alt=""
                    className="thumbnail-image"
                  />
                )}

                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  onChange={(e) => change_video_thumbnail(e, "user_promo", 2)}
                  className="hide-no-file-chosen"
                />
              </>
            </div>

            <div>
              <button
                className="settings-activate1"
                disabled={disableUserButton}
                onClick={() => handleUpload(2, user_promo_url2, "user_promo")}
              >
                {disableGenUrl ? (
                  <ClipLoader
                    color={"black"}
                    loading={disableUserButton}
                    size={15}
                  />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div>
          {/* _____________ */}
          <div className="settings-grid-container">
            <div className="distance">
              <label className="settings-detail-title">Video url 3</label>
              <form>
                <input
                  className="settings-link-input"
                  type="text"
                  value={user_promo_url3}
                  onChange={(e) => set_user_promo_url3(e.target.value)}
                />
              </form>
            </div>

            <div className="settings-center-column">
              <>
                {local_user_promo_thumbnail_src3 === "" ? (
                  gottenUserPromoData3?.thumbnail_url ? (
                    <img
                      className="thumbnail-image"
                      src={gottenUserPromoData3?.thumbnail_url}
                      alt=""
                      style={{ objectFit: "cover" }}
                    />
                  ) : null
                ) : (
                  <img
                    src={local_user_promo_thumbnail_src3}
                    alt=""
                    className="thumbnail-image"
                  />
                )}

                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  onChange={(e) => change_video_thumbnail(e, "user_promo", 3)}

                  className="hide-no-file-chosen"
                />
              </>
            </div>

            <div>
              <button
                className="settings-activate1"
                disabled={disableUserButton}
                onClick={() => handleUpload(3, user_promo_url3, "user_promo")}
              >
                {disableGenUrl ? (
                  <ClipLoader
                    color={"black"}
                    loading={disableUserButton}
                    size={15}
                  />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div>
          {/* _____________ */}
          <div className="settings-grid-container">
            <div className="distance">
              <label className="settings-detail-title">Video url 4</label>
              <form>
                <input
                  className="settings-link-input"
                  type="text"
                  value={user_promo_url4}
                  onChange={(e) => set_user_promo_url4(e.target.value)}
                />
              </form>
            </div>

            <div className="settings-center-column">
              <>
                {local_user_promo_thumbnail_src4 === "" ? (
                  gottenUserPromoData4?.thumbnail_url ? (
                    <img
                      className="thumbnail-image"
                      src={gottenUserPromoData4?.thumbnail_url}
                      alt=""
                      style={{ objectFit: "cover" }}
                    />
                  ) : null
                ) : (
                  <img
                    src={local_user_promo_thumbnail_src4}
                    alt=""
                    className="thumbnail-image"
                  />
                )}

                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  onChange={(e) => change_video_thumbnail(e, "user_promo", 4)}

                  className="hide-no-file-chosen"
                />
              </>
            </div>

            <div>
              <button
                className="settings-activate1"
                disabled={disableUserButton}
                onClick={() => handleUpload(4, user_promo_url4, "user_promo")}
              >
                {disableGenUrl ? (
                  <ClipLoader
                    color={"black"}
                    loading={disableUserButton}
                    size={15}
                  />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div>
          {/* _____________ */}
          <div className="settings-grid-container">
            <div className="distance">
              <label className="settings-detail-title">Video url 5</label>
              <form>
                <input
                  className="settings-link-input"
                  type="text"
                  value={user_promo_url5}
                  onChange={(e) => set_user_promo_url5(e.target.value)}
                />
              </form>
            </div>

            <div className="settings-center-column">
              <>
                {local_user_promo_thumbnail_src5 === "" ? (
                  gottenUserPromoData5?.thumbnail_url ? (
                    <img
                      className="thumbnail-image"
                      src={gottenUserPromoData5?.thumbnail_url}
                      alt=""
                      style={{ objectFit: "cover" }}
                    />
                  ) : null
                ) : (
                  <img
                    src={local_user_promo_thumbnail_src5}
                    alt=""
                    className="thumbnail-image"
                  />
                )}

                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  onChange={(e) => change_video_thumbnail(e, "user_promo", 5)}

                  className="hide-no-file-chosen"
                />
              </>
            </div>

            <div>
              <button
                className="settings-activate1"
                disabled={disableUserButton}
                onClick={() => handleUpload(5, user_promo_url5, "user_promo")}
              >
                {disableGenUrl ? (
                  <ClipLoader
                    color={"black"}
                    loading={disableUserButton}
                    size={15}
                  />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div>
          {/* _____________ */}
          <hr />
          <h3 className="video-settings-data-title">
            Testimonial videos from delivery agents
          </h3>
          <div className="settings-grid-container">
            <div className="distance">
              <label className="settings-detail-title">Video url 1</label>
              <form>
                <input
                  className="settings-link-input"
                  type="text"
                  value={delivery_agent_promo_url1}
                  onChange={(e) =>
                    set_delivery_agent_promo_url1(e.target.value)
                  }
                />
              </form>
            </div>

            <div className="settings-center-column">
              <>
                {local_delivery_agent_promo_thumbnail_src1 === "" ? (
                  gottenDeliveryAgentPromoData1?.thumbnail_url ? (
                    <img
                      className="thumbnail-image"
                      src={gottenDeliveryAgentPromoData1?.thumbnail_url}
                      alt=""
                      style={{ objectFit: "cover" }}
                    />
                  ) : null
                ) : (
                  <img
                    src={local_delivery_agent_promo_thumbnail_src1}
                    alt=""
                    className="thumbnail-image"
                  />
                )}

                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  onChange={(e) => change_video_thumbnail(e, "agent_promo", 1)}

                  className="hide-no-file-chosen"
                />
              </>
            </div>

            <div>
              <button
                className="settings-activate1"
                disabled={disableAgentButton}
                onClick={() =>
                  handleUpload(1, delivery_agent_promo_url1, "agent_promo")
                }
              >
                {disableGenUrl ? (
                  <ClipLoader
                    color={"black"}
                    loading={disableAgentButton}
                    size={15}
                  />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div>
          {/* ___________ */}
          <div className="settings-grid-container">
            <div className="distance">
              <label className="settings-detail-title">Video url 2</label>
              <form>
                <input
                  className="settings-link-input"
                  type="text"
                  value={delivery_agent_promo_url2}
                  onChange={(e) =>
                    set_delivery_agent_promo_url2(e.target.value)
                  }
                />
              </form>
            </div>

            <div className="settings-center-column">
              <>
                {local_delivery_agent_promo_thumbnail_src2 === "" ? (
                  gottenDeliveryAgentPromoData2?.thumbnail_url ? (
                    <img
                      className="thumbnail-image"
                      src={gottenDeliveryAgentPromoData2?.thumbnail_url}
                      alt=""
                      style={{ objectFit: "cover" }}
                    />
                  ) : null
                ) : (
                  <img
                    src={local_delivery_agent_promo_thumbnail_src2}
                    alt=""
                    className="thumbnail-image"
                  />
                )}

                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  onChange={(e) => change_video_thumbnail(e, "agent_promo", 2)}

                  className="hide-no-file-chosen"
                />
              </>
            </div>

            <div>
              <button
                className="settings-activate1"
                disabled={disableAgentButton}
                onClick={() =>
                  handleUpload(2, delivery_agent_promo_url2, "agent_promo")
                }
              >
                {disableGenUrl ? (
                  <ClipLoader
                    color={"black"}
                    loading={disableAgentButton}
                    size={15}
                  />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div>
          {/* ___________ */}
          <div className="settings-grid-container">
            <div className="distance">
              <label className="settings-detail-title">Video url 3</label>
              <form>
                <input
                  className="settings-link-input"
                  type="text"
                  value={delivery_agent_promo_url3}
                  onChange={(e) =>
                    set_delivery_agent_promo_url3(e.target.value)
                  }
                />
              </form>
            </div>

            <div className="settings-center-column">
              <>
                {local_delivery_agent_promo_thumbnail_src3 === "" ? (
                  gottenDeliveryAgentPromoData3?.thumbnail_url ? (
                    <img
                      className="thumbnail-image"
                      src={gottenDeliveryAgentPromoData3?.thumbnail_url}
                      alt=""
                      style={{ objectFit: "cover" }}
                    />
                  ) : null
                ) : (
                  <img
                    src={local_delivery_agent_promo_thumbnail_src3}
                    alt=""
                    className="thumbnail-image"
                  />
                )}

                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  onChange={(e) => change_video_thumbnail(e, "agent_promo", 3)}
                  className="hide-no-file-chosen"
                />
              </>
            </div>

            <div>
              <button
                className="settings-activate1"
                disabled={disableAgentButton}
                onClick={() =>
                  handleUpload(3, delivery_agent_promo_url3, "agent_promo")
                }
              >
                {disableGenUrl ? (
                  <ClipLoader
                    color={"black"}
                    loading={disableAgentButton}
                    size={15}
                  />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div>
          {/* _________ */}
          <div className="settings-grid-container">
            <div className="distance">
              <label className="settings-detail-title">Video url 4</label>
              <form>
                <input
                  className="settings-link-input"
                  type="text"
                  value={delivery_agent_promo_url4}
                  onChange={(e) =>
                    set_delivery_agent_promo_url4(e.target.value)
                  }
                />
              </form>
            </div>

            <div className="settings-center-column">
              <>
                {local_delivery_agent_promo_thumbnail_src4 === "" ? (
                  gottenDeliveryAgentPromoData4?.thumbnail_url ? (
                    <img
                      className="thumbnail-image"
                      src={gottenDeliveryAgentPromoData4?.thumbnail_url}
                      alt=""
                      style={{ objectFit: "cover" }}
                    />
                  ) : null
                ) : (
                  <img
                    src={local_delivery_agent_promo_thumbnail_src4}
                    alt=""
                    className="thumbnail-image"
                  />
                )}

                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  onChange={(e) => change_video_thumbnail(e, "agent_promo", 4)}

                  className="hide-no-file-chosen"
                />
              </>
            </div>

            <div>
              <button
                className="settings-activate1"
                disabled={disableAgentButton}
                onClick={() =>
                  handleUpload(4, delivery_agent_promo_url4, "agent_promo")
                }
              >
                {disableGenUrl ? (
                  <ClipLoader
                    color={"black"}
                    loading={disableAgentButton}
                    size={15}
                  />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div>
          {/* ________ */}
          <div className="settings-grid-container">
            <div className="distance">
              <label className="settings-detail-title">Video url 5</label>
              <form>
                <input
                  className="settings-link-input"
                  type="text"
                  value={delivery_agent_promo_url5}
                  onChange={(e) =>
                    set_delivery_agent_promo_url5(e.target.value)
                  }
                />
              </form>
            </div>

            <div className="settings-center-column">
              <>
                {local_delivery_agent_promo_thumbnail_src5 === "" ? (
                  gottenDeliveryAgentPromoData5?.thumbnail_url ? (
                    <img
                      className="thumbnail-image"
                      src={gottenDeliveryAgentPromoData5?.thumbnail_url}
                      alt=""
                      style={{ objectFit: "cover" }}
                    />
                  ) : null
                ) : (
                  <img
                    src={local_delivery_agent_promo_thumbnail_src5}
                    alt=""
                    className="thumbnail-image"
                  />
                )}

                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  onChange={(e) => change_video_thumbnail(e, "agent_promo", 5)}

                  className="hide-no-file-chosen"
                />
              </>
            </div>

            <div>
              <button
                className="settings-activate1"
                disabled={disableAgentButton}
                onClick={() =>
                  handleUpload(5, delivery_agent_promo_url5, "agent_promo")
                }
              >
                {disableGenUrl ? (
                  <ClipLoader
                    color={"black"}
                    loading={disableAgentButton}
                    size={15}
                  />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (result.msg === "no video exists") {
  }
}
