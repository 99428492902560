import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import { FaGreaterThan } from "react-icons/fa";
import "../../component/css/payment_record.css";
import dayjs from "dayjs";
import { ClipLoader } from "react-spinners";
import PageCounter from "../../component/page_counter";
import emptybox from "../../component/images/emptybox.png";
import { IoMdMore } from "react-icons/io";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import ClickAwayListener from "react-click-away-listener";
import {
  ClassNameDeterminer,
  NameTrimer,
} from "../../component/content_trimer";
import weekRangeGetter, { getWeekNumber } from "../../component/weekRanger";

export const ZeroChecker = (cash) => {
  if (cash === 0) {
    return "₦0.00";
  } else {
    return "₦" + cash.toLocaleString();
  }
};

export default function Fleet_Manager_Payment() {
  // const org_year = Number.parseInt(dayjs().year());
  // const org_month = Number.parseInt(dayjs().month());
  //console.log(org_month, org_year);
  const [data, setData] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [cname1, setCname1] = useState("week1");
  const [cname2, setCname2] = useState("week2");
  const [cname3, setCname3] = useState("week2");
  const [cname4, setCname4] = useState("week2");
  const [cname5, setCname5] = useState("week2");
  const [cname6, setCname6] = useState("week2");
  const [isLoaded, setIsLoaded] = useState(false);
  const token = JSON.parse(sessionStorage.getItem("rubbish"));
  const [result, setResult] = useState("");
  const [Dweek, setDWeek] = useState(1);
  const [month, setMonth] = useState(Number.parseInt(dayjs().month()));
  const [year, setYear] = useState(Number.parseInt(dayjs().year()));
  const [date, setDate] = useState(dayjs(Date.now()).format("YYYY-MM"));
  const [Cweek, setCweek] = useState(1);
  const [fire, setFire] = useState(true);
  const [pageCount, setPageCount] = React.useState(1);
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [agent_ids, setAgentIds] = useState([]);
  const [not_paids, setNotPaids] = useState([]);
  const [all_checker, setAllChecker] = useState([]); // used to determine if the select all checkbox will show
  const [result2, setResult2] = useState("");
  const [global_ids, setGlobalIds] = useState([]);
  const [select_all, setSelectAll] = useState(false);
  const [checked_state, setCheckedState] = useState([
    new Array(not_paids.length).fill(false),
  ]);
  const [paids, setPaids] = useState([]);
  const [disable2, setDisable2] = useState(false);
  const [paymentCheck, setPaymentCheck] = useState("");
  const [noti_data, setNotiData] = useState("");
  const [grey_out, setGreyOut] = useState(false);
  const [length, setLength] = useState(0);
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const d = new Date();
  const day = weekday[d.getDay()];

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [fire === true]);

  useEffect(() => {
    const weekNo = getWeekNumber(year, month, new Date().getDate());
    switch (weekNo) {
      case 1:
        handleClick("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[0]
        );
        break;
      case 2:
        handleClick1("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[1]
        );
        break;
      case 3:
        handleClick2("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[2]
        );
        break;
      case 4:
        handleClick3("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[3]
        );
        break;
      case 5:
        handleClick4("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[4]
        );
        break;
      case 6:
        handleClick6("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[5]
        );
        break;
    }
  }, []);

  const handleDate = (e) => {
    const newDate = dayjs(e.target.value).format("YYYY-MM");
    const yearM = Number.parseInt(newDate.slice(0, 4));
    const monthM = Number.parseInt(newDate.slice(5, 7)) - 1;
    setDate(newDate);
    setYear(Number.parseInt(newDate.slice(0, 4)));
    setMonth(Number.parseInt(newDate.slice(5, 7)) - 1);
    //console.log(newDate, yearM, monthM);
    fetchData(Cweek, monthM, yearM);
    weekRangeGetter(monthM, yearM);
  };

  // const handleWeekRanger = (yearM, monthM, index) => {
  //   const i = index - 1;
  //   let range = getWeeksInMonth(yearM, monthM)[i];
  //   if(range?.start === range?.end) {
  //     return `${range?.start}/${monthM}/${yearM}`;
  //   } else if(range?.dates.length === 2) {
  //     return  `${range?.start}/${monthM}/${yearM}`;
  //   }else if(range?.end === 1) {
  //     return `${range?.start}/${monthM}/${yearM} - ${range?.dates[range?.dates.length - 2]}/${monthM}/${yearM}`;
  //   } else {
  //     return `${range?.start}/${monthM}/${yearM} - ${range?.end}/${monthM}/${yearM}`;
  //   }
  // }
  const minusPagec = () => {
    if (pageCount <= 1) {
      return;
    } else {
      setDisable(true);
      const pageCountM = pageCount - 1;
      setPageCount((prev) => prev - 1);
      fetchData(Cweek, month, year, pageCountM);
    }
  };
  const addPagec = () => {
    if (data?.length <= resultPerPage - 1) return data;
    setDisable1(true);
    const pageCountM = pageCount + 1;
    setPageCount((prev) => prev + 1);
    fetchData(Cweek, month, year, pageCountM);
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    setFire(true);
    setPageCount(1);
  };

  const DataChecker = (data) => {
    if (data) {
      return data;
    } else {
      return "No Data Yet";
    }
  };

  const firstClick = () => {
    setToggle(true);

    // navigate("/Pending-del");
  };

  const secondClick = () => {
    setToggle(false);
    // navigate("/Pending-del");
  };

  const handleClassName1 = () => {
    setCname1("week1");
    setCname2("week2");
    setCname3("week2");
    setCname4("week2");
    setCname5("week2");
    setCname6("week2");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[0]
    );
  };
  const handleClassName2 = () => {
    setCname1("week2");
    setCname2("week1");
    setCname3("week2");
    setCname4("week2");
    setCname5("week2");
    setCname6("week2");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[1]
    );
  };
  const handleClassName3 = () => {
    setCname1("week2");
    setCname2("week2");
    setCname3("week1");
    setCname4("week2");
    setCname5("week2");
    setCname6("week2");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[2]
    );
  };
  const handleClassName4 = () => {
    setCname1("week2");
    setCname2("week2");
    setCname3("week2");
    setCname4("week1");
    setCname5("week2");
    setCname6("week2");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[3]
    );
  };
  const handleClassName5 = () => {
    setCname1("week2");
    setCname2("week2");
    setCname3("week2");
    setCname4("week2");
    setCname5("week1");
    setCname6("week2");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[4]
    );
  };
  const handleClassName6 = () => {
    setCname1("week2");
    setCname2("week2");
    setCname3("week2");
    setCname4("week2");
    setCname5("week2");
    setCname6("week1");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[5]
    );
  };

  const handleClick = (e) => {
    //console.log(e);
    if (e === "auto") {
      setIsLoaded(false);
      // setWeek(1);
      handleClassName1();
      setDWeek("");
      fetchData(1);
      setCweek(1);
    } else {
      e.preventDefault();
      setIsLoaded(false);
      // setWeek(1);
      handleClassName1();
      setDWeek("");
      fetchData(1);
      setCweek(1);
    }
  };

  const handleClick1 = (e) => {
    //console.log(e);
    if (e === "auto") {
      setIsLoaded(false);
      // setWeek(2);
      setDWeek("");
      handleClassName2();
      fetchData(2);
      setCweek(2);
    } else {
      e.preventDefault();
      setIsLoaded(false);
      // setWeek(2);
      setDWeek("");
      handleClassName2();
      fetchData(2);
      setCweek(2);
    }
  };

  const handleClick2 = (e) => {
    //console.log(e);
    if (e === "auto") {
      //console.log("baddest");
      setIsLoaded(false);
      setDWeek("");
      // setWeek(3);
      handleClassName3();
      fetchData(3);
      setCweek(3);
    } else {
      e.preventDefault();
      //console.log("baddest");
      setIsLoaded(false);
      setDWeek("");
      // setWeek(3);
      handleClassName3();
      fetchData(3);
      setCweek(3);
    }
  };

  const handleClick3 = (e) => {
    //console.log(e);
    if (e === "auto") {
      setIsLoaded(false);
      // setWeek(4);
      setDWeek("");
      handleClassName4();
      fetchData(4);
      setCweek(4);
    } else {
      e.preventDefault();
      setIsLoaded(false);
      // setWeek(4);
      setDWeek("");
      handleClassName4();
      fetchData(4);
      setCweek(4);
    }
  };

  const handleClick4 = (e) => {
    //console.log(e);
    if (e === "auto") {
      setIsLoaded(false);
      // setWeek(5);
      setDWeek("");
      handleClassName5();
      fetchData(5);
      setCweek(5);
    } else {
      e.preventDefault();
      setIsLoaded(false);
      // setWeek(5);
      setDWeek("");
      handleClassName5();
      fetchData(5);
      setCweek(5);
    }
  };
  const handleClick6 = (e) => {
    if (e === "auto") {
      setIsLoaded(false);
      // setWeek(6);
      setDWeek("");
      handleClassName6();
      fetchData(6);
      setCweek(6);
    } else {
      e.preventDefault();
      setIsLoaded(false);
      // setWeek(6);
      setDWeek("");
      handleClassName6();
      fetchData(6);
      setCweek(6);
    }
  };

  // will only fire when a checkbox has been checked so create a functionality for that
  const handleClick5 = (e) => {
    e.preventDefault();
    //console.log("hello");
    const active = checked_state.some((element) => element === true);
    if (select_all === false && active === false) {
      window.alert("Please select transactions to pay");
    } else if (paymentCheck === null) {
      navigate("/App/Confirm_Pin2", {
        state: {
          func: "save",
          week: Cweek,
          month: month,
          year: year,
          transaction_type: "fleet",
          not_paids,
          noti_data,
          paids,
        },
      });
    } else {
      navigate("/App/Confirm_Pin2", {
        state: {
          func: "update",
          week: Cweek,
          month: month,
          year: year,
          transaction_type: "fleet",
          not_paids,
          noti_data,
          paids,
        },
      });
    }
  };

  // const StatusRenderer = (item) => {

  // }

  const Menu = (item) => (
    <div className="admin-App">
      <Popup
        trigger={
          <button className="more-details">
            {" "}
            <IoMdMore />{" "}
          </button>
        }
        position="right top"
        on="click"
        closeOnDocumentClick
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        // contentStyle={{ padding: '0px', border: 'none' }}
        // arrow={false}
      >
        <ul className="drop-down">
          <li
            onClick={() =>
              navigate("/App/Individual_Fleet_Payment", {
                state: {
                  id: item?.fleet_manager_details?._id,
                  week: Cweek,
                  month: month,
                  year: year,
                  status: PaymentStatusDeterminer(
                    item?.fleet_manager_details?._id
                  ),
                },
              })
            }
          >
            View more
          </li>
          {Checker(item?.fleet_manager_details?._id) ? null : (
            <li onClick={() => reversePaymentNavigator(item)}>
              Reverse payment
            </li>
          )}
        </ul>
      </Popup>
    </div>
  );

  const reversePaymentNavigator = (item) => {
    not_paids.push({
      agent_id: item?.fleet_manager_details?._id,
      status: "not-paid",
    });
    navigate("/App/Reverse_Payment", {
      state: {
        id: item?.fleet_manager_details?._id,
        week: Cweek,
        month: month,
        year: year,
        transaction_type: "fleet",
        not_paids: not_paids,
        name: item?.fleet_manager_details?.fleet_name,
        code: item?.fleet_manager_details?.fleet_manager_code,
        steps: -2,
        noti_data,
      },
    });
  };

  // funtion to determine the paid status
  const PaymentStatusDeterminer = (fleet_id) => {
    if (paymentCheck === null) return "not-paid";
    if (!agent_ids) return "paid";
    const status = agent_ids.some((agent) => {
      return fleet_id === agent.agent_id;
    });
    //console.log(status);
    if (status) return "not-paid";
    return "paid";
  };

  const Checker = (fleet_id) => {
    if (paymentCheck === null) {
      return true;
    }
    if (!agent_ids) return false;

    const status = agent_ids.some((agent) => {
      return fleet_id === agent.agent_id;
    });

    if (status === true) {
      return true;
    }

    if (status === false) {
      return false;
    }
  };

  let all = "";
  const handleSelectAll = (checker) => {
    //console.log(select_all);
    setSelectAll(checker);
    if (checker === false) {
      //console.log('false');
      all = checked_state.map((item) => (item = false));
      if (result2.paymentCheck === null) {
        const ids = [];
        result.fleet_managers_earnings.forEach((fleet_manager) => {
          const id = fleet_manager.fleet_manager_details._id;
          ids.push({ agent_id: id, status: "not_paid" });
        });
        setNotPaids(ids);
        setPaids([]);
      } else {
        setNotPaids(result2.paymentCheck.not_paids);
        setPaids([]);
      }
    } else {
      //console.log('true');
      if (result2.paymentCheck === null) {
        //console.log('sing a little louder')
        const ids = [];
        result.fleet_managers_earnings.forEach((fleet_manager) => {
          const id = fleet_manager.fleet_manager_details._id;
          ids.push(id);
        });
        setPaids(ids);
        setNotPaids([]);
      } else {
        setNotPaids([]);
        let paidsm = [];
        result2.paymentCheck.not_paids.forEach((element) =>
          paidsm.push(element.agent_id)
        );
        setPaids(paidsm);
      }
      all = checked_state.map((item) => (item = true));
    }
    setCheckedState(all);
  };

  const handleChange = (position, id) => {
    setSelectAll(false);
    //console.log(paids, not_paids);
    //console.log(checked_state);
    const updatedCheckedState = checked_state.map((item, i) => {
      return i === position ? !item : item;
    });
    setCheckedState(updatedCheckedState);
    //console.log(updatedCheckedState);
    // handleCheckbox(id);
    const pre = not_paids.some((element) => element.agent_id === id);
    const present = not_paids.filter((element) => {
      return element.agent_id !== id;
    });
    if (pre) {
      //console.log('first');
      setNotPaids(present);
    } else {
      //console.log('second');
      not_paids.push({ agent_id: id, status: "not_paid" });
    }
    //console.log(not_paids);
    if (paids.length === 0) {
      paids.push(id);
    } else {
      const pre1 = paids.some((element) => element === id);
      const present1 = paids.filter((element) => {
        return element !== id;
      });
      if (pre1) {
        //console.log('first paid');
        setPaids(present1);
      } else {
        //console.log('second paid');
        paids.push(id);
      }
    }
    //console.log(paids);
  };

  const fetchData = async (week, monthM, yearM, pageCountM) => {
    try {
      //console.log(week, Dweek);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_payment_record/fleet_managers",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: pageCountM || pageCount,
            month: monthM || month,
            year: yearM || year,
            week: week || Cweek,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const resultM = await response.json();
      setResult(resultM);
      //console.log(resultM);

      if (
        resultM.msg === "fleet managers weekly transactions gotten successfully"
      ) {
        setData(resultM.fleet_managers_earnings);
        setDisable(false);
        setDisable1(false);
        setIsLoaded(true);
        //console.log("got here");
        //console.log(month, year, date, Dweek);
        setFire(false);
        fetchPaymentStatus(week, monthM, yearM, resultM);
      } else if (
        resultM.msg === "No fleet manager made any earnings this week"
      ) {
        //console.log("and here");
        //console.log(month, year, date, Dweek);
        setFire(false);
        setIsLoaded(true);
      }
    } catch (error) {
      //console.log(data, result);
      //console.log(error);
    }
  };

  const fetchPaymentStatus = async (week, monthM, yearM, resultM) => {
    //console.log(week, monthM, yearM, Dweek, month, year);
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_payment_record/get_not_paids",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            week: week || Cweek,
            month: monthM || month,
            year: yearM || year,
            transaction_type: "fleet",
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );

      const result = await response.json();
      //console.log(result);
      setResult2(result);

      if (result.msg === "Success") {
        setPaymentCheck(result.paymentCheck);
        const ids = [];
        if (result.paymentCheck === null) {
          // setAgentIds(result.paymentCheck.not_paids);
          resultM.fleet_managers_earnings.forEach((fleet_manager) => {
            //console.log(fleet_manager);
            const id = fleet_manager.fleet_manager_details._id;
            //console.log(id);
            ids.push({ agent_id: id, status: "not_paid" });
          });
          setCheckedState(new Array(ids.length).fill(false));
          setNotPaids(ids);
          setAllChecker(ids);
          setLength(ids.length);
        } else {
          setLength(result.paymentCheck.not_paids.length);
          setCheckedState(
            new Array(result.paymentCheck.not_paids.length).fill(false)
          );
          setAgentIds(result.paymentCheck.not_paids);
          setNotPaids(result.paymentCheck.not_paids);
          setAllChecker(result.paymentCheck.not_paids);
          let ids = [];
          let j = -1;
          resultM.fleet_managers_earnings.forEach((fleet_manager) => {
            j++;
            ids.push(fleet_manager.fleet_manager_details._id);
            for (let i = 0; i < result.paymentCheck.not_paids.length; i++) {
              if (
                fleet_manager.fleet_manager_details._id ===
                result.paymentCheck.not_paids[i].agent_id
              ) {
                ids.splice(j, 1);
              }
            }
          });
          //console.log(ids);
          // setPaids(ids);
          setGlobalIds(ids);
        }
        //console.log("data gotten succesfully");
      } else {
        //console.log("somen error occurred");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  if (!isLoaded) {
    //console.log('got here')
    //console.log("got here");
    return (
      <h1 className="loading-pages1">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (year > 2042 || year < 2022) {
    //console.log("and here");
    // return (<No_Payment />)
    return (
      <div className="payment-history">
        <div className="payment-calender-container">
          <input
            type="month"
            name="schedule"
            min="2022-01-01"
            max="2025-12-31"
            className="payment-calender"
            value={date}
            onChange={handleDate}
          />
        </div>
        <div className="found-not">
          <img src={emptybox} alt="not found" />
          <p>No records for this date and time</p>
        </div>
      </div>
    );
  } else if (
    result.msg === "fleet managers weekly transactions gotten successfully"
  ) {
    return (
      <div className="payment-history">
        {"Sunday" === "Sunday" ? (
          <div className="shadow-payment-record">
            <button
              className="shadow-settings-activate5"
              // disabled={grey_out}
              onClick={handleClick5}
            >
              {disable2 ? (
                <ClipLoader color={"black"} loading={disable2} size={15} />
              ) : (
                "Pay"
              )}
            </button>
          </div>
        ) : null}
        <div className="payment-calender-container">
          <input
            type="month"
            name="schedule"
            min="2021-12-31"
            max="2043-01-01"
            className="payment-calender"
            value={date}
            onChange={handleDate}
          />
        </div>
        <div className="payment-week">
          <div className="week1date">
            <div className={cname1} onClick={handleClick}>
              WEEK 1
            </div>
            <p className="week-duration">{weekRangeGetter(month, year)[0]}</p>
          </div>

          <div className="week1date">
            <div className={cname2} onClick={handleClick1}>
              WEEK 2
            </div>
            <p className="week-duration">{weekRangeGetter(month, year)[1]}</p>
          </div>

          <div className="week1date">
            <div className={cname3} onClick={handleClick2}>
              WEEK 3
            </div>
            <p className="week-duration">{weekRangeGetter(month, year)[2]}</p>
          </div>

          <div className="week1date">
            <div className={cname4} onClick={handleClick3}>
              WEEK 4
            </div>
            <p className="week-duration">{weekRangeGetter(month, year)[3]}</p>
          </div>

          <div className="week1date">
            <div className={cname5} onClick={handleClick4}>
              WEEK 5
            </div>
            <p className="week-duration">{weekRangeGetter(month, year)[4]}</p>
          </div>
          {weekRangeGetter(month, year)[5] ? (
            <div className="week1date">
              <div className={cname6} onClick={handleClick6}>
                WEEK 6
              </div>
              <p className="week-duration">{weekRangeGetter(month, year)[5]}</p>
            </div>
          ) : null}
        </div>
        <div className="result-per-page1">
          {all_checker.length > 0 ? (
            <div>
              Select all
              <input
                type="checkbox"
                checked={select_all}
                onChange={() => handleSelectAll(!select_all)}
              />
            </div>
          ) : null}
          <form className="payment-form">
            Results per page
            <input
              className="chizys-input"
              type="number"
              value={resultPerPage}
              onChange={(e) => setResultPerPage(e.target.value)}
            />
            <button className="chizys-button" onClick={handleButtonClick}>
              Done
            </button>
          </form>
        </div>
        <table>
          <th> </th>
          <th>
            <div className="agent-table-left">Agent ID</div>
          </th>
          <th>Fleet name</th>
          <th>Phone number</th>
          <th>Account number / Type</th>
          <th>Bank name</th>
          <th>Earnings</th>
          <th>Status</th>
          <tbody>
            {data?.map((item, i) => (
              <tr key={i} className="payment-data">
                {Checker(item?.fleet_manager_details?._id) ? (
                  <td>
                    <div>
                      <input
                        type="checkbox"
                        onChange={() =>
                          handleChange(i, item?.fleet_manager_details?._id)
                        }
                        checked={checked_state[i]}
                      />
                    </div>
                  </td>
                ) : (
                  <p style={{ visibility: "hidden" }}>e</p>
                )}
                <td>
                  <div className="agent-table-left">
                    {item?.fleet_manager_details?._id}
                  </div>
                </td>
                <td
                  className={ClassNameDeterminer(
                    item?.fleet_manager_details?.fleet_name
                  )}
                >
                  {NameTrimer(
                    DataChecker(item?.fleet_manager_details?.fleet_name)
                  )}
                  <p style={{ display: "none" }}>
                    {DataChecker(item?.fleet_manager_details?.fleet_name)}
                  </p>
                </td>
                <td>{item?.fleet_manager_details?.phone_no}</td>
                <td>
                  {item?.fleet_manager_details?.bank_details?.account_no} /{" "}
                  {item?.fleet_manager_details?.bank_details?.account_type}
                </td>
                <td>{item?.fleet_manager_details?.bank_details?.bank_name}</td>
                <td>{ZeroChecker(item?.total_weekly_earnings)}</td>
                <td>
                  <div
                    className={PaymentStatusDeterminer(
                      item?.fleet_manager_details?._id
                    )}
                  >
                    {PaymentStatusDeterminer(
                      item?.fleet_manager_details?._id
                    ).toLocaleUpperCase()}
                  </div>
                </td>
                <td>
                  <div className="popup-click">{Menu(item)}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          style={{
            height: "20px",
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
            paddingRight: "20px",
            marginTop: "10px",
          }}
        >
          <div>
            {disable ? (
              <ClipLoader color={"black"} loading={disable} size={15} />
            ) : (
              <FontAwesomeIcon
                icon={faAngleLeft}
                className={pageCount <= 1 ? "icon-space-less" : "icon-space"}
                onClick={minusPagec}
              />
            )}
          </div>
          <h6>{PageCounter(result?.count, pageCount, resultPerPage)}</h6>
          <div>
            {disable1 ? (
              <ClipLoader color={"black"} loading={disable1} size={15} />
            ) : (
              <FontAwesomeIcon
                icon={faAngleRight}
                className={
                  data?.length <= resultPerPage - 1
                    ? "icon-space-less"
                    : "icon-space"
                }
                onClick={addPagec}
              />
            )}
          </div>
        </div>
      </div>
    );
  } else if (result.msg === "No fleet manager made any earnings this week") {
    //console.log("I'm here")
    return (
      <div className="payment-history">
        <div className="payment-calender-container">
          <input
            type="month"
            name="schedule"
            min="2022-01-01"
            max="2025-12-31"
            className="payment-calender"
            value={date}
            onChange={handleDate}
          />
        </div>
        <div className="payment-week">
          <div className="week1date">
            <div className={cname1} onClick={handleClick}>
              WEEK 1
            </div>
            <p className="week-duration">{weekRangeGetter(month, year)[0]}</p>
          </div>

          <div className="week1date">
            <div className={cname2} onClick={handleClick1}>
              WEEK 2
            </div>
            <p className="week-duration">{weekRangeGetter(month, year)[1]}</p>
          </div>

          <div className="week1date">
            <div className={cname3} onClick={handleClick2}>
              WEEK 3
            </div>
            <p className="week-duration">{weekRangeGetter(month, year)[2]}</p>
          </div>

          <div className="week1date">
            <div className={cname4} onClick={handleClick3}>
              WEEK 4
            </div>
            <p className="week-duration">{weekRangeGetter(month, year)[3]}</p>
          </div>

          <div className="week1date">
            <div className={cname5} onClick={handleClick4}>
              WEEK 5
            </div>
            <p className="week-duration">{weekRangeGetter(month, year)[4]}</p>
          </div>
          {weekRangeGetter(month, year)[5] ? (
            <div className="week1date">
              <div className={cname6} onClick={handleClick6}>
                WEEK 6
              </div>
              <p className="week-duration">{weekRangeGetter(month, year)[5]}</p>
            </div>
          ) : null}
        </div>
        <div className="found-not">
          <img src={emptybox} alt="not found" />
          <p>No Transaction for This Week</p>
        </div>
      </div>
    );
  }
}
