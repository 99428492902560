import React, { useState, useEffect } from 'react';
import '../../component/css/reports.css';
import profilepic2 from '../../component/images/profilepic2.jpg';
import { useNavigate } from 'react-router-dom';
import No_Report from '../../component/notfound/No_Report';
import User_Reporttoggle from './User_Reporttoggle';
import Agent_Reporttoggle from './Agent_Reporttoggle';
import { ClipLoader } from 'react-spinners';

export default function Reports() {
  const [toggle, setToggle] = useState(true);
  const [data, getData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const token = JSON.parse(sessionStorage.getItem('rubbish'));
  const [result, setResult] = useState();
  const navigate = useNavigate();
  const [resultPerPage, setResultPerPage] = useState(20);

  const firstClick = () => {
    setToggle(true);

    // navigate("/Pending-del"); 
  };
 
  const secondClick = () => {
    setToggle(false);
    // navigate("/Pending-del");
  };
  let DATE = {}

  const TimeConverter = (props) => {
    //console.log(props)
    const date = new Date(props.value);
    DATE =  {
        date: date.toLocaleDateString(),
        time: date.toLocaleTimeString(),
        combined: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
    }
    return DATE.time;
}

   //console.log('data gotten succesfully');

    return ( 
      <div className='reports'>
        <div className='reports-properties'>
          <div className='reports-switch'>
            <div className='report-toggle'
                onClick={firstClick} 
                id={toggle ? "active" : "inactive2"}
          >  Users Reports</div>
          <div  
            className='report2-toggle' 
            onClick={secondClick}
            id={toggle ? "inactive" : "active2"}
          > Delivery Agents Reports</div>                
          </div>          

          {
            toggle === true  ? (
              <User_Reporttoggle
                click={() => {
                  navigate("/App/User_Reporttoggle");
                }}
              />

            ) : toggle === false  ? (
              <Agent_Reporttoggle
                click2={() => {
                  navigate("/App/Agent_Reporttoggle"); 
                }}
              />
            ) : null
          }
          
        </div>
      </div>
    )
}    
