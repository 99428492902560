import React,  {useContext, createContext, useState} from "react";
import DeliveryImage from '../../component/images/DeliveryImage.png';
import "../../component/css/WelcomeUser.css";
import { Link, useNavigate, Outlet } from "react-router-dom";
import { TokenContext  } from '../../component/token_context';
import Login_Header from "./Login_Header";
import ClipLoader from "react-spinners/ClipLoader";

export default function Login(props) {
  const navigate = useNavigate()
  const value = useContext(TokenContext);
  const {handleformsubmit, message, setMessage, loginData, setLoginData, token, disable, setDisable } = value
  //console.log( "this is the details from",message, loginData, token);
  //console.log(value);
  
  if (message === 'Logged In successfully') {
    navigate("/App/Dashboard")
    setTimeout(() => {
      setLoginData({username: loginData.username, password: ''});
    }, 3000);
  } else if(message === 'All fields must be filled') {
    setMessage(<p style={{color: 'red'}}>All fields must be filled</p>)
  } else if(message === 'some error occured'){
    setMessage(<p style={{color: 'red'}}>Incorrect username or password</p>);
  }


  const handleChange = (e) => {
    const target = e.target;
    const { name, value } = target;
    setLoginData({ ...loginData, [name]: value });
  };

  
  return (
    <>
      <div className="login-page">
        <Login_Header />
      
        <div className="mainBox" id="welcome-main">
          <div id="DeliveryImage">
            <p>
              Door to Door <span id="yellow">delivery</span>
              <br /> services for individuals
              <br /> and businesses.
            </p>
            <br />
            <br />
            <img src={DeliveryImage} alt="Deliver" />
          </div>

          <div id="Form-flex-ULogin">
            <h2 id="welcome">Welcome Back Admin</h2>
            <br />

            <form className='login-form' onSubmit={handleformsubmit} >
              <div className='agent-login-input'>
                <label className='login-label'>Username</label><br></br>
                <input 
                  type='text' 
                  value={loginData.username}
                  onChange={handleChange}
                  name='username' 
                  placeholder='Enter you Username' 
                  className='login-input'                 
                />
              </div>
              <div className='agent-password'>
                <label className='login-label'>Password</label><br></br>
                <input 
                  type='password' 
                  name='password' 
                  value={loginData.password}
                  onChange={handleChange}
                  className='login-input' 
                  placeholder='Enter your password'                
                />
              </div>
              <div className='login-submit'>
                <button className='login-btn' type='submit' disabled={disable}>
                 {disable ? <ClipLoader color={"black"} loading={disable}  size={15} /> : "login" } 
                </button>
                <div className='message'>{message ? <p> {message}</p> :null} </div>
              </div>
            </form>
          </div>
          
        </div>        
      </div>
      <Outlet />
    </>
  )
}








































































// import React, {useContext, createContext, useState} from 'react';
// import '../../component/css/login.css';
// import App from '../../App';
// import Dashboard from '../dashboard/Dashboard';
// import { useNavigate, Outlet } from 'react-router-dom';
// import { TokenContext  } from '../../component/token_context';


// export default function Login(props) {
//   const navigate = useNavigate()
//   const value = useContext(TokenContext);
//   const {handleformsubmit, message, loginData, setLoginData, token } = value
//   //console.log( "this is the details from",message, loginData, token);
  
//   if (token) {
//     navigate("/App/Dashboard")
//   }

//   const handleChange = (e) => {
//     const target = e.target;
//     const { name, value } = target;
//     setLoginData({ ...loginData, [name]: value });
//   };
  

//   return (
//     // <TokenContext.Provider value={token}>
//       <div className='login'>
//       <div className='login-props'>
//         <div className='login-style'>
//           <h2 className='login-title'>WELCOME BACK</h2>
//           <form className='login-form' onSubmit={handleformsubmit} >
//             <div className='agent-login-input'>
//               <label className='login-label'>Username</label><br></br>
//               <input 
//                 type='text' 
//                 value={loginData.username}
//                 onChange={handleChange}
//                 name='username' 
//                 placeholder='Username' 
//                 className='login-input'                 
//               />
//             </div>
//             <div className='agent-password'>
//               <label className='login-label'>Password</label><br></br>
//               <input 
//                 type='password' 
//                 name='password' 
//                 value={loginData.password}
//                 onChange={handleChange}
//                 className='login-input' 
//                 placeholder='password'                
//               />
//             </div>
//             <div className='login-submit'>
//               <button className='login-btn' type='submit'>login</button>
//               <div className='message'>{message ? <p> {message}</p> :null} </div>
//             </div>
//           </form>
//         </div>
//       </div>
//       <Outlet />
//       </div>
//       // {props.children}
//     // </TokenContext.Provider>
//   )
// }
