import React from 'react';
import '../../component/css/successfully_cancelled.css';
import animal from '../../component/images/animal.png';

export default function Successfully_Cancelled() {
  return (
    <div className='successfully-cancelled'>
      <div className='successfully-cancelled-container'>
        <div className='cancelled-background'>
          <div className='cancelled-confirmation'>
            <div className='cancelled-confirmation-info'><br></br>
              Delivery Agent Rematching Successful<br></br><br></br>
              <img src={animal} width='100px' height='100px' alt='icon' /><br></br>
              <div className='rematched'>
                You have successfully rematched the schedule delivery 'ID78809' to another available Delivery Agent
              </div>
            </div>
            <div className='view-details'>
              <button className='details'>view details</button>
            </div> 
          </div>
        </div>
      </div>
    </div>
  )
}
